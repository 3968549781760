.primary-table-ui {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfe2e6;

  .pagination {
    padding: 0 30px;
    border-top: 1px solid #e9edf2;
    height: 70px;
  }

  .table-responsive-md {
    table {
      tbody {
        tr {
          td {
            height: 80px;
            padding: 15px 30px;
            // Action Button
            .btn-danger {
              width: 90px;
              height: 40px;
            }
            .primary-table-info {
              font-size: 13px;
              color: #239f87;
              margin: 0;
              line-height: 1.69;
            }
            .action-button {
              padding: 0;
            }
            .subject {
              font-weight: normal;
              font-size: 13px;
              line-height: 15px;
              text-align: left;
              color: #434544;
            }
            .status {
              font-family: "Source Sans Pro";
              font-weight: 600;
              font-size: 13px;
              line-height: 13px;
              text-align: left;
              color: #239f87;
            }
          }
        }
      }
      thead {
        tr {
          th {
            background: #eaeaea;
            height: 50px;

            font-weight: normal;
            padding: 0 30px;
            font-size: 13px;
            line-height: 15px;
            text-align: left;
            color: #303956;
            letter-spacing: normal;
            &:first-child {
              border-top-left-radius: 8px;
            }
            &:last-child {
              border-top-right-radius: 8px;
            }
          }
        }
      }
    }
  }
}

.primary-paginated-table {
  &--head {
    margin-bottom: 14px;
    // position: sticky;
    // top: 0;
    // background: #fff;
    font-size: 12px;
    line-height: 17px;
    color: #35363b;
    font-weight: 600;
    .primary-paginated-table--col {
      .Checkbox {
        position: static;
      }
      label {
        margin: 0;
      }
    }
  }
  &--body {
    .btn-danger {
      width: 90px;
      height: 40px;
    }
    .primary-table-info {
      font-size: 13px;
      color: #239f87;
      margin: 0;
      line-height: 1.69;
    }
    .action-button {
      padding: 0;
    }
    .primary-text {
      font-size: 12px;
      line-height: 17px;
      word-wrap: break-word;
      color: #35363b;
      p {
        font-size: 12px;
        line-height: 17px;
        word-wrap: break-word;
        color: #35363b;
        margin: 0;
      }
      img {
        max-width: 100%;
        display: block;
      }
      a {
        text-decoration: underline;
      }
    }
    .subject {
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      text-align: left;
      color: #434544;
      &.ellipses {
        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 0;
        }
      }
    }
    .primary-paginated-table--row {
      & + .primary-paginated-table--row {
        margin-top: 5px;
      }
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 4px;
      background: #ffffff;
      min-height: 60px;
      padding: 10px;
    }
  }
  &--row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 10px;
  }
  &--col {
    padding: 0 20px;
    .btn-danger {
      background: #e60f0f;
      border-radius: 4px;
      min-width: 1px;
      border: none;
      width: 65px;
      height: 34px;
    }
    .action-button {
      padding: 0;
      float: none;
    }
    .status {
      font-family: "Source Sans Pro";
      font-weight: 600;
      font-size: 13px;
      line-height: 13px;
      text-align: left;
      &.approved {
        color: #239f87;
      }
      &.open {
        color: #f20808;
      }
      &.pending {
        color: #d7c622;
      }
      &.unapproved {
        color: #666666;
      }
    }
  }
}

.primary-pagination {
  display: flex;
  align-items: center;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  li {
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
    &.active {
      a {
        border-color: #1ea087;
        pointer-events: none;
      }
    }
    & + li {
      margin-left: 11px;
      position: relative;

      // &::before {
      //   content: '';
      //   height: 8px;
      //   width: 1px;
      //   position: absolute;
      //   left: -6px;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   background-color: #c4c4c4;
      // }
    }
    &:nth-child(2) {
      margin-left: 0;
    }
    &:first-child {
      margin-right: 20px;
      a {
        width: auto;
        height: auto;
      }
    }
    &:last-child {
      margin-left: 20px;
      a {
        width: auto;
        height: auto;
      }
    }
    a {
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #35363b;
      border: 1px solid transparent;
      border-radius: 50%;
      width: 20px;
      height: 20px;

      svg {
        font-size: 16px;
        color: #aaaaaa;
      }
    }
  }
}

.single-col-table {
  &.with-action {
    .primary-paginated-table {
      &--col {
        width: calc(100% - 30px);
        &:last-child {
          padding: 0;
          width: 30px;
        }
      }
    }
  }
  .primary-paginated-table {
    &--col {
      width: 100%;
    }
  }
}

.two-col-table {
  &.with-action {
    .primary-paginated-table {
      &--col {
        width: calc((100% - 30px) / 2);
        &:last-child {
          padding: 0;
          width: 30px;
        }
      }
    }
  }
  .primary-paginated-table {
    &--col {
      width: calc(100% / 3);
    }
  }
}

.three-col-table {
  &.with-action {
    .primary-paginated-table {
      &--col {
        width: calc((100% - 30px) / 3);
        &:last-child {
          padding: 0;
          width: 30px;
        }
      }
    }
  }
  .primary-paginated-table {
    &--col {
      width: calc(100% / 3);
    }
  }
}

.four-col-table {
  &.with-action {
    .primary-paginated-table {
      &--col {
        width: calc((100% - 30px) / 3);
        &:last-child {
          padding: 0;
          width: 30px;
        }
      }
    }
  }
  .primary-paginated-table {
    &--col {
      width: calc(100% / 4);
    }
  }
}

.five-col-table {
  &.with-action {
    .primary-paginated-table {
      &--col {
        width: calc((100% - 30px) / 4);
        &:last-child {
          padding: 0;
          width: 30px;
        }
      }
    }
  }
  .primary-paginated-table {
    &--col {
      width: calc(100% / 5);
    }
  }
}

.six-col-table {
  &.with-action {
    .primary-paginated-table {
      &--col {
        width: calc((100% - 30px) / 5);
        &:last-child {
          padding: 0;
          width: 30px;
        }
      }
    }
  }
  .primary-paginated-table {
    &--col {
      width: calc(100% / 6);
    }
  }
}

.seven-col-table {
  &.with-action {
    .primary-paginated-table {
      &--col {
        width: calc((100% - 30px) / 6);
        &:last-child {
          padding: 0;
          width: 30px;
        }
      }
    }
  }

  &.with-button {
    .primary-paginated-table {
      &--col {
        width: calc((100% - 75px) / 6);
        &:last-child {
          padding: 0 5px;
          width: 75px;
        }
      }
    }
  }
  .primary-paginated-table {
    &--col {
      width: calc(100% / 7);
    }
  }
}


@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .support-table {
    .primary-paginated-table--col {
      &:first-child {
        width: calc(100% - (250px + 170px + 110px + 110px + 60px)) !important;
      }
      &:nth-child(3) {
        width: 170px !important;
      }
      &:nth-child(4) {
        width: 110px !important;
      }
      &:nth-child(5) {
        width: 110px !important;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .support-table {
    .primary-paginated-table--row {
      padding: 0;
      & + .primary-paginated-table--row {
        margin-top: 15px;
      }
    }
    .primary-paginated-table--col {
      display: flex;
      align-items: center;
      width: 100% !important;
      justify-content: space-between;
      padding: 10px;
      &::before {
        font-size: 13px;
        font-weight: 600;
      }
      &:first-child::before {
        content: 'Ticket Id';
      }
      &:nth-child(2)::before {
        content: 'Description';
      }
      &:nth-child(3)::before {
        content: 'Type';
      }
      &:nth-child(4)::before {
        content: 'Reply';
      }
      &:nth-child(5)::before {
        content: 'Duration';
      }
      &:last-child::before {
        content: 'Action';
      }
      & + .primary-paginated-table--col {
        border-top: 1px solid #eee;
      }
    }
  }
}