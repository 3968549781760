.selectable-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  &.three-col {
    .selectable-list--wrap {
      width: calc((100% - 60px) / 3);
    }
  }
  &--wrap {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 9px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    p {
      font-family: $secondaryFont;
      font-weight: $semiBold;
      margin: 0;
      font-size: 16px;
      line-height: 26px;
      color: #2a2a2a;
    }
    span {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      border-radius: 50%;
      border: 1px solid #e6e6e6;
      img {
        opacity: 0;
      }
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      cursor: pointer;
      opacity: 0;
      height: 100%;
      &:checked ~ span {
        background-color: $primaryColor;
        border-color: $primaryColor;
        img {
          opacity: 1;
        }
      }
    }
  }
}
