.measure-paragraph {
  max-width: 456px;
}
.measure-paragraph-max {
  max-width: 536px;
}
.measure-lead {
  max-width: 536px;
}
.measure-lead-max {
  max-width: 616px;
}
.measure-hero-lead {
 max-width: 696px;
}