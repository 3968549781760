// doubt-box
.doubt-box {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
}
  
// doubt-box-head
.doubt-box-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .left {
    display: flex;
    align-items: center;
    .image-box {
      height: 52px;
      width: 52px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .doubt-box-head-body {
      margin-left: 12px;
      strong {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: black;
        margin-bottom: 3px;
        line-height: 1;
      }
      span {
        display: block;
        font-size: 14px;
        color: #6F6F6F;
      }
    }
  }
}

// doubt-feed-image
.doubt-feed-image {
  width: 100%;
  height: 220px;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

// doubt-feed-desc
.doubt-feed-desc {
  margin-bottom: 16px;
  p {
    font-size: 14px;
    color: #6F6F6F;
    line-height: 22px;
  }
}

// doubt-feed-info
.doubt-feed-info {
  .doubt-feed-info-top {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      color: #6F6F6F;
    }
  }
  .doubt-feed-action-list {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    margin-top: 5px;
    padding: 8px 0 4px 0;
    span {
      cursor: pointer;
    }
  }
  .doubt-feed-action-list {
    span {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: #6F6F6F;
      font-weight: bold;
      img {
        margin-right: 5px;
      }
    }
  }
}

// doubt-write-comment
.doubt-write-comment {
  position: relative;
  .form-control {
    font-size: 14px;
    color: black;
    border: none;
    box-shadow: none;
    height: 70px;
    padding-left: 60px;
  }
  .image-box {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

// doubt-comment-wrapper
.doubt-comment-wrapper {
  border-bottom: 1px solid #F0F0F0;
  padding: 15px 0;
  .doubt-comment-box {
    display: flex;
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-child {
      border: none;
      padding: 0;
      margin: 0;
    }
    .image-box {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .doubt-comment-box-body {
      margin-left: 12px;
      .user-time {
        margin-top: 13px;
        strong {
          font-size: 14px;
          color: #2A2A2A;
          font-weight: bold;
        }
        span { 
          font-size: 12px;
          color: #6F6F6F;
          margin-left: 25px;
        }
      }
      .doubt-comment-text {
        font-size: 14px;
        color: #6F6F6F;
        line-height: 28px;
        margin-bottom: 0;
      }
      .reply-btn {
        font-size: 14px;
        cursor: pointer;
        color: #239F87;
      }
    }
  }
}

//doubt-dropdown
.doubt-dropdown {
  position: relative;
  .toggle {
    cursor: pointer;
  }
  .menu {
    position: absolute;
    left: -85px;
    top: -8px;
    border-radius: 8px;
    box-shadow: 4px 6px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    width: 85px;
    display: none;
    .item { 
      padding: 10px;
      font-size: 14px;
      color: #212121;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        color: #a7a7a7;
        transition: 0.5s;
      }
    }
    &.active {
      display: block;
    }
  }
}

// doubt-post-box
.doubt-post-box {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px 20px 15px 20px;
  .doubt-post-box-top {
    display: flex;
    margin-bottom: 15px;
    .doubt-post-write {
      width: 60%;
      margin-right: 20px;
      position: relative;
      .form-control {
        border: none;
        border-bottom: 1px solid #F0F0F0;
        box-shadow: none;
        padding: 3px 0 0 35px;
        height: 50px;
        border-radius: 0;
        font-size: 14px;
        resize: none;
        font-weight: bold;
      }
      .ask-icon {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .right {
      display: flex;
    }
  }
  .doubt-post-box-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edu-btn {
      min-width: 140px;
    }
  }
}

// doubt-file-upload
.doubt-file-upload {
  input {
    display: none;
  }
  label {
    cursor: pointer;
    font-size: 14px;
    color: #6F6F6F;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
}
