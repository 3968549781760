.primary-tabs {
  ul.tabs-list {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      cursor: pointer;
      font-weight: 500;
      border-bottom: 3px solid transparent;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 14px;
      color: #262424;
      & + li {
        margin-left: 30px;
      }
      &.active {
        font-weight: 700;
        border-color: #239f87;
      }
    }
  }
}
.primary-tab-content {
  padding-top: 20px;
  padding-bottom: 20px;
}
