@font-face {
  font-family: "icomoon";
  src: url("./../fonts/icons/icomoon.eot?1qvg8k");
  src: url("./../fonts/icons/icomoon.eot?1qvg8k#iefix") format("embedded-opentype"),
    url("./../fonts/icons/icomoon.ttf?1qvg8k") format("truetype"),
    url("./../fonts/icons/icomoon.woff?1qvg8k") format("woff"),
    url("./../fonts/icons/icomoon.svg?1qvg8k#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-down-arrow:before {
  content: "\e900";
}
.icon-goal:before {
  content: "\e901";
}
