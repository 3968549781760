@import './variables';
@import './mixins';

.btn {
  font-family: $secondaryFont;
  font-weight: $bold;
  text-align: center;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  transition: all ease-in-out 0.3s;

  &.edu-btn {
    background-color: #239f87;
    border-radius: 4px;
    border-color: #239f87;
    height: 38px;
    font-size: 15px !important;
    &:focus {
      box-shadow: none;
    }
    &:active {
      box-shadow: none !important;
      background-color: #239f87 !important;
      border-color: #239f87 !important;
    }
  }

  @include desktop {
    min-width: 180px;
  }
  &:hover {
    transition: all ease-in-out 0.3s;
  }
  &.btn-link {
    padding: 0;
    min-width: 0;
    font-family: $secondaryFont;
    font-weight: $semiBold;
    font-size: 16px;
    height: auto;
    line-height: normal;
    color: #6f6f6f;
    &.primary {
      color: $primaryColor;
    }
    &:hover {
      text-decoration: none;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  &.btn-primary {
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
    color: $white !important;
    &:hover {
      color: $white !important;
    }
    &:hover {
      color: white;
    }
    &:disabled {
      background-color: #9f9f9f;
      border-color: #9f9f9f;
      color: #dddddd;
      opacity: 1;
    }
    &:not(:disabled):not(.disabled):hover {
      background-color: darken($color: $primaryColor, $amount: 5%);
    }
    &:not(:disabled):not(.disabled):active {
      background-color: $primaryColor;
      border-color: $primaryColor;
    }
  }
  &.btn-default {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #6f6f6f;
    &:not(:disabled):not(.disabled):hover {
      background-color: darken($color: #e6e6e6, $amount: 5%);
    }
    &:not(:disabled):not(.disabled):active {
      background-color: #e6e6e6;
      border-color: #e6e6e6;
    }
  }
}

.button-group {
  display: flex;
  align-items: center;
  button {
    & + button {
      margin-left: 15px;
      @media screen and (max-width: 767px) {
        margin-left: 5px;
      }
    }
  }
}

//round-btn
.round-btn {
  min-width: 128px;
  height: 44px;
  background-color: #239F87;
  border-color: #239F87;
  border-radius: 25px;
  font-size: 15px !important;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.7px;
  padding: 0 10px;
  &:hover, &:focus, &:active {
    background-color: #239F87 !important;
    border-color: #239F87 !important;
  }
}
