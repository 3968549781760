/* left-panel */
.left-panel {
	position: fixed;
	top: 70px;
	left: 0;
	bottom: 0;
	width: 220px;
	padding: 22px 12px 22px;
	display: flex;
	flex-direction: column;
	// justify-content: space-between;
	overflow-y: auto;
	ul {
		list-style-type: none;
		margin-left: -30px;
		li {
			margin-bottom: 22px;
			a {
				display: flex;
				align-items: center;
				&:hover {
					.left-nav-link {
						color: #239f87;
					}
				}
			}
			.left-nav-link {
				font-size: 14px;
				color: #6f6f6f;
				margin-left: 14px;
				line-height: 1.3;
			}
			.left-nav-icon {
				height: 20px;
				width: 20px;
				background-repeat: no-repeat;
				display: block;
				.my-resume {
					margin-bottom: 0;
				}
			}

			.my-profile {
				background-image: url(../../dist/images/educator/profile.svg) !important;
			}

			.my-cms {
				background-image: url(../../dist/images/educator/cms.svg) !important;
			}

			.my-dashboard {
				background-image: url(../../dist/images/educator/dashboard.svg) !important;
			}
			.my-wall {
				background-image: url(../../dist/images/educator/wall.svg) !important;
			}

			.my-course {
				background-image: url(../../dist/images/educator/course.svg) !important;
			}
			.my-doubt {
				background-image: url(../../dist/images/educator/doubts.svg) !important;
			}
			.my-question-bank {
				background-image: url(../../dist/images/educator/question-bank.svg) !important;
			}
			.my-manange-test {
				background-image: url(../../dist/images/educator/manage-test.svg) !important;
			}

			.my-manange-test-bundle {
				background-image: url(../../dist/images/educator/manage-test-bundle.svg) !important;
			}
			.my-library {
				background-image: url(../../dist/images/educator/library.svg) !important;
			}

			.my-educator {
				background-image: url(../../dist/images/educator/educator.svg) !important;
			}
			.my-assignments {
				background-image: url(../../dist/images/educator/assignment.svg) !important;
			}
			.my-students {
				background-image: url(../../dist/images/educator/my-students.svg) !important;
			}
			.my-manage-batches {
				background-image: url(../../dist/images/educator/manage-batches.svg) !important;
			}
			.my-go-live {
				background-image: url(../../dist/images/educator/live.svg) !important;
			}
			.my-reports {
				background-image: url(../../dist/images/educator/reports.svg) !important;
			}

			.my-role {
				background-image: url(../../dist/images/educator/role.svg) !important;
			}
			.my-interview {
				background-image: url(../../dist/images/educator/interview.svg) !important;
			}
			.my-manage-payments {
				background-image: url(../../dist/images/educator/manage-payment.svg) !important;
			}
			.my-rewards {
				background-image: url(../../dist/images/educator/reward.svg) !important;
			}
			.my-support {
				background-image: url(../../dist/images/educator/info.svg) !important;
			}
			.my-logout {
				background-image: url(../../dist/images/educator/logout.svg) !important;
			}

			.my-resume {
				background-image: url(../../dist/images/educator/resume.svg) !important;
				margin-bottom: 0 !important;
			}

			.my-mock-interviews {
				background-image: url(../../dist/images/educator/mock.svg) !important;
			}

			.pro-image {
				background-image: url(../../dist/images/side-menu/edu-pro.svg) !important;
			}
			.goal-image {
				background-image: url(../../dist/images/side-menu/goals.svg) !important;
			}
			.live-image {
				background-image: url(../../dist/images/side-menu/live.svg) !important;
			}
			.assignment-image {
				background-image: url(../../dist/images/side-menu/assignment.svg) !important;
			}
			.progress-image {
				background-image: url(../../dist/images/side-menu/progress.svg) !important;
			}
			.reward-image {
				background-image: url(../../dist/images/side-menu/reward.svg) !important;
			}
			.settings {
				background-image: url(../../dist/images/side-menu/settings.svg) !important;
			}
			.support-image {
				background-image: url(../../dist/images/side-menu/support.svg) !important;
			}
			.ad-dashboard-image {
				background-image: url("../images/ad-dashboard-icon.png");
			}
			.ad-wall-image {
				background-image: url("../images/ad-wall-icon.png");
			}
			.ad-doubt-image {
				background-image: url("../images/ad-doubt-icon.png");
			}
			.ad-batch-image {
				background-image: url("../images/ad-batch-icon.png");
			}
			.ad-student-image {
				background-image: url("../images/ad-student-icon.png");
			}
			.ad-educator-image {
				background-image: url("../images/ad-educator-icon.png");
			}
			.ad-question-image {
				background-image: url("../images/ad-question-icon.png");
			}
			.ad-test-image {
				background-image: url("../images/ad-test-icon.png");
			}
			.ad-library-image {
				background-image: url("../images/ad-library-icon.png");
			}
			.ad-logout-image {
				background-image: url("../images/ad-logout-icon.png");
			}
		}
	}
	.collapse-menu {
		margin-top: 25px;
		margin-left: -12px;
		display: none;
		.left-nav-icon {
			display: none;
		}
		&.active {
			display: block;
		}
	}
}

.rotate180 {
	transform: rotate(180deg);
}
//Admin-left-panel
.admin-left-panel6 {
	.left-panel {
		width: 256px;
		padding-left: 0;
		padding-right: 0;
		background: linear-gradient(167.93deg, #1ea087 22.39%, #35838e 87.46%);
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
		top: 64px;

		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}

		.collapse-menu {
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 0;
				margin-top: 10px;
				&.active {
					& > a {
						background: #00b8be;
					}
				}
				a {
					padding: 10px 25px 10px 60px;
					color: #fff;
					&:hover {
						.left-nav-link {
							color: #fff;
						}
					}
					.left-nav-link {
						margin: 0;
					}
				}
			}
		}

		.left-nav-link {
			color: white;
		}
		// .left-nav-link {
		//   color: #acb0b5;
		// }
		img {
			display: none;
		}
		& > ul {
			padding: 0 0 22px;
			margin: 0;
			& > li {
				padding: 0;
				margin-bottom: 10px;

				&.active {
					& > a {
						background: #00b8be;
						svg {
							transform: translateY(-50%) rotate(90deg);
							transition: 0.3s ease-in-out all;
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
				& > a {
					position: relative;
					padding: 10px 25px;
					&.in {
						svg {
							transform: translateY(-50%) rotate(90deg);
							transition: 0.3s ease-in-out all;
						}
					}
					&:hover {
						.left-nav-link {
							color: #fff;
						}
					}

					svg {
						position: absolute;
						right: 22px;
						top: 50%;
						display: block;
						color: #fff;
						transition: 0.3s ease-in-out all;
						transform: translateY(-50%) rotate(0deg);
					}
					.nav-arrow-img {
						position: absolute;
						right: 6px;
						top: 50%;
						display: block;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}

body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel {
	width: 250px !important;
	.left-panel {
		width: 250px !important;
		background: #f5f9ff;
		padding: 24px 8px;
		// display: flex;
		// flex-direction: column;
		// min-height: 100vh;
		ul {
			flex-grow: 1;
			list-style: none;
			margin: 0 0 20px;
			// padding: 0 16px;
			padding: 0;

			li {
				margin-bottom: 8px;
				&.active {
					a {
						background: #ffe200;
						box-shadow: 0px 8px 16px rgba(255, 209, 0, 0.3);
					}
				}
				a {
					display: flex;
					padding: 12px 16px;
					border-radius: 50px;
					align-items: center;
					font-size: 16px;
					color: #111111;
					&:hover {
						background: #ffe200;
						box-shadow: 0px 8px 16px rgba(255, 209, 0, 0.3);
					}
					.left-nav-link {
						color: #111111;
					}
					&:hover {
						.left-nav-link {
							color: #111111;
						}
					}
					span {
						line-height: 1;
					}
					img {
						margin-right: 12px;
					}
				}
			}
		}
	}
}

.user-left-panel {
	.left-panel ul li .left-nav-icon {
		width: 16px;
		height: 16px;
	}
}
