//////////////////////////
// ICON HOLDER VARIANTS //
//////////////////////////

@mixin icon-holder-outline-variant($color, $color-hover: #fff) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border: 1px solid $color;

  @include hover {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }
}

@mixin icon-holder-variant($color, $color-hover: #fff) {
  color: #fff;
  background-image: none;
  background-color: $color;
  border: 1px solid $color;

  @include hover {
    @include icon-holder-outline-variant($color, $color-hover);
  }
}

@mixin chip-outline-variant($color, $active-background: $color, $color-hover: color-yiq($active-background), $active-border: $color) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  @include hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;
  }
}