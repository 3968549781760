.primary-box-ui-wrap {
  display: flex;
  flex-wrap: wrap;
  .primary-box-ui {
    margin-right: 20px;
  }
}
.primary-box-ui {
  border-radius: 10px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  .badge {
    border-radius: 2px;
    background-color: #c4ffd6;
    font-size: 10px;
    padding: 2px 8px;
  }
  // display: inline-flex;
  // align-items: flex-end;
  // margin-right: 20px;
  // flex-flow: wrap;
  & > label {
    font-family: "Source Sans Pro";
    font-weight: normal;
    font-size: 14px;
    display: block;
    line-height: 25px;
    text-align: left;
    color: #262424;
  }
  .dropzone-wrap {
    display: flex;
    align-items: center;
    .wrap {
      flex-shrink: 0;
      & + .wrap {
        margin-left: 15px;
      }
      & > label {
        font-family: "Source Sans Pro";
        font-weight: normal;
        font-size: 14px;
        display: block;
        line-height: 25px;
        width: 100%;
        text-align: left;
        color: #262424;
      }
    }
  }
  // & > div {
  // width: 150px;
  // margin-left: 7.5px;
  // margin-right: 7.5px;
  // & + div {
  //   margin-left: 15px;
  // }
  // }
  .show-img {
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    & > img {
      max-width: 100%;
      max-height: 135px;
    }
  }
  h4 {
    font-family: "Source Sans Pro";
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
    color: #000;
  }
  .edit-icon {
    border: solid 1px #b9bcca;
    background-color: #ffffff;
    outline: none;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 19px;
    color: #b9bcca;
    transition: all ease-in-out 0.3s;
    svg {
      transition: all ease-in-out 0.3s;
    }
    &:hover {
      background-color: #b9bcca;
      transition: all ease-in-out 0.3s;
      svg {
        fill: #ffffff;
        transition: all ease-in-out 0.3s;
      }
    }
  }
}

.primary-text-group {
  margin-bottom: 9px;
  p {
    margin: 0 0 4px;
    font-family: "Source Sans Pro";
    font-weight: normal;
    font-size: 13px;
    line-height: normal;
    text-align: left;
    color: #919191;
  }

  span {
    display: block;
    font-family: "Source Sans Pro";
    font-weight: normal;
    word-break: break-word;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    color: #000;
  }
}

.primary-image-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #f4f4f4;
    & + div {
      margin-left: 20px;
    }
    img {
      max-width: 100%;
    }
  }
}
