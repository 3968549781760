 .primary-page-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  button {
    height: 40px;
    border-radius: 4px;
    padding: 0 20px;
    display: block;
    background: #ffe200;
    border: 1px solid #e1e3e7;

    &.primary-outine-button {
      border-color: #0B8F75;
      background-color: transparent;
      font-weight: 600;
font-size: 12px;
height: 30px;
line-height: 15px;
padding: 0 10px !important;
color: #35363B;

svg {
  color: #0B8F75;
  transition: all ease-in-out .3s;
  font-size: 18px;
}
&:hover {
  background-color: #0B8F75;
  color: #fff;
  svg {
    color: #fff;
    transition: all ease-in-out .3s;
  }
}

    }

    &:hover {
      background-color: darken($color: #ffe200, $amount: 3%);
    }
  }

  & + .primary-page-header {
    margin-top: 30px;
  }
  h2 {
    font-family: "Source Sans Pro";
    font-weight: normal;
    font-size: 32px;
    line-height: 31px;
    text-align: left;
    color: #464343;
  }
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    li {
      margin-top: 10px;
      svg {
        fill: #919191;
      }
      a {

        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-align: left;
        text-transform: uppercase;
        color: #919191;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.primary-outine-button {
      border-color: #0B8F75;
      background-color: transparent;
      font-weight: 600;
font-size: 12px;
height: 30px;
line-height: 15px;
padding: 0 10px !important;
color: #35363B;

svg {
  color: #0B8F75;
  transition: all ease-in-out .3s;
  font-size: 18px;
}
&:hover {
  background-color: #0B8F75;
  color: #fff;
  svg {
    color: #fff;
    transition: all ease-in-out .3s;
  }
}

    }