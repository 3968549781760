.datepicker-wrap {
  position: relative;

  .react-daterange-picker {
    display: block;
    width: 100%;
    &__button {
      display: none;
    }
    &__inputGroup {
      flex-grow: 0;
      min-width: 1px;
    }
    &__wrapper {
      height: 36px;
      border-radius: 4px;
      background: #fff;
      width: 100%;
      font-family: "Source Sans Pro";
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      padding: 4px 15px;
      text-align: left;
      color: #000;
      border: 1px solid #e6e6e6;
    }
  }

  .react-datepicker-wrapper {
		width: 100%;
		display: block;
    .primary-form-control {
      padding-right: 40px;
    }
  }
  svg {
    position: absolute;
    right: 10px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    color: #239f87;
  }
}
