.page-section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  
  @include media-breakpoint-up(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.layout-default .page-section {
  @include media-breakpoint-up(lg) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}