.menu-modal {
  .modal-content {
    position: relative;
    overflow: hidden;
    @include mdc-elevation(10, $shadow-baseline-color, $shadow-opacity-boost);
  }
  .modal-body {
    padding: 0;
    position: relative;
    overflow: hidden;
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .tab-content {
    .nav-link {
      color: rgba($black, .7);
      @include hover {
        color: $black;
      }
    }
  }
  .nav-link.active {
    color: $black;
  }
  .menu {
    .nav-link {
      font-weight: 500;
      padding: .5rem 1.25rem;
      color: $courses-modal-nav-link;
    }
    .active {
      color: $courses-modal-active-nav-link;
      background-color: $modal-content-bg;
      position: relative;
      &::after {
        content: ' ';
        width: 4px;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $primary;
        display: block;
        position: absolute;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .modal-dialog {
      max-width: calc(800px - (#{$spacer} * 2));
      margin-top: $navbar-height;
    }
  }
}