// inner-main-heading
.inner-main-heading {
  font-size: 18px;
  color: black;
}

// settings-wrapper
.settings-wrapper{
  max-width: 580px;
}

// settings-box
.settings-box {
  .setting-box-heading {
    font-size: 14px;
    display: block;
    color: #6F6F6F;
    margin-bottom: 8px;
  }
  .settings-box-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6dede;
    padding-bottom: 23px;
    margin-bottom: 23px;
    .settings-inner-heading {
      font-size: 14px;
      color: #2A2A2A;
      font-weight: bold;
      line-height: 1;
    }
    .right {
      line-height: 1;
    }
    .action-btn {
      font-size: 14px;
      cursor: pointer;
      color: #239F87;
      font-weight: 600;
      line-height: 1;
    }
  }
  &.for-language {
    .setting-box-heading {
      margin-bottom: 12px;
    }
    .settings-inner-heading {
      color: #6F6F6F;
      font-weight: 500;
    }
    .lang-heading {
      font-size: 14px;
      color: #2A2A2A;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 10px;
      display: block;
    }
  }
}

// settings-links
.settings-links {
  ul {
    margin-bottom: 0;
    list-style-type: none;
    margin-left: -40px;
  }
  li {
    margin-bottom: 8px;
    a {
      font-size: 14px;
      color: #2A2A2A;
      font-weight: bold;
      line-height: 1;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// toggle-switch
.toggle-switch {
  height: 16px;
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 16px;
    margin-bottom: 0;
    input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  input:checked + .slider {
    background-color: #239F87;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #239F87;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 25px;
  }
}
