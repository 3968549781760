.progress {
  box-shadow: none;
  background: #e5e5e5;
  border-radius: 20px;
  height: 12px;
  &-bar {
    border-radius: 20px;
  }
  .bg-success {
    background-color: $primaryColor !important;
  }
}
