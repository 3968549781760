.success-box {
  text-align: center;
  img {
    display: block;
    margin: 0 auto 60px;
  }
  p {
    margin: 0;
  }
  .btn {
    margin-top: 60px;
  }
}
