.primary-button {
  background: transparent;
  border: 0px;
  padding: 0.5rem 1rem;
  font-size: 13px;
  border-radius: 0.25rem;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  color: #434544;
  transition: all ease-in-out 0.3s;

  &:hover {
    transition: all ease-in-out 0.3s;
  }
  &[shape="popup"] {
    background: #239f87;
    color: white;
    border: 1px solid #e1e3e7;

    &:hover {
      background: darken($color: #239f87, $amount: 3%);
      color: white;
    }
  }

  &[shape="finish"] {
    background: #00b8be;
    color: white;
    border: 1px solid #e1e3e7;

    &:hover {
      background: darken($color: #00b8be, $amount: 3%);
      color: white;
    }
  }

  &[shape="primary"] {
    background: #ffe200;
    color: black;
    border: 1px solid #e1e3e7;

    &:hover {
      background: darken($color: #ffe200, $amount: 3%);
    }
  }

  &[shape="secondary"] {
    background: #f5f7fa;
    color: black;
    border: 1px solid #acb0b5;

    &:hover {
      background: darken($color: #f5f7fa, $amount: 3%);
    }
  }
}

.btn-danger {
  border-radius: 4px;
  border: solid 1px #e1e3e7;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.15;
  color: #ffffff;
  background-color: #e60f0f;
}

.button-with-icon {
  background: #f5f7fa;
  border: 1px solid #acb0b5;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  color: #303956;
  transition: all ease-in-out 0.3s;

  &:hover {
    background: #47b3bb;
    color: #fff;
    transition: all ease-in-out 0.3s;
    // border: none;
    border-color: #47b3bb;
  }
  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}
