@import "../utils/variables";
@import "../utils/mixins";

.user-structure {
	min-height: 100vh;
	background: $white;
	display: flex;
	@media (max-width: 767px) {
		flex-wrap: wrap;
		min-height: 100%;
	}

	// @media (max-width: 1200px) {
	//   width: 50%;
	// }
	@media (max-width: 767px) {
		width: 100%;
	}
	.info {
		&-header {
			padding: 10px 20px;
			img {
				max-width: 100%;
				height: 35px;
				@media (max-width: 767px) {
					height: auto;
				}
			}
			@include tablet {
				padding: 14px 20px 0;
				img {
					height: auto;
				}
			}
		}
		&-body {
			display: none;
			@include tablet {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 0 144px;
			}
			img {
				max-width: 100%;
			}

			@include tablet {
				padding: 0 35px;
				height: calc(100% - (56px + 65px - 15px));
			}
			@include tabletLandscape {
				padding: 0 35px;
				height: calc(100% - (56px + 65px));
			}
			h1 {
				font-weight: $bold;
				font-size: 36px;
				line-height: 36px;
				max-width: 517px;
				font-family: $primaryFont;
				color: #00a689;
				margin-bottom: 14px;
				@media (max-width: 991px) {
					font-size: 26px;
					line-height: normal;
				}
			}
			p {
				font-size: 18px;
				font-family: $secondaryFont;
				font-weight: $regular;
				text-align: center;
				line-height: 32px;
				color: #666666;
				margin-bottom: 58px;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: normal;
				}
				@include tabletLandscape {
					font-size: 20px;
				}
			}
		}
		&-footer {
			display: none;
			@include tablet {
				display: block;
				padding: 0 35px 20px;
			}
			@include tabletLandscape {
				padding: 0 94px 35px;
			}
			ul {
				display: flex;
				margin: 0;
				padding: 0;
				list-style: none;
				align-items: center;
				justify-content: center;
				li {
					& + li {
						margin-left: 50px;
						a {
							position: relative;
							&::before {
								content: "";
								width: 4.24px;
								height: 4.24px;
								left: -27.24px;
								top: 50%;
								position: absolute;
								background: #666666;
								opacity: 0.3;
								transform: rotate(45deg) translateY(-50%);
							}
						}
					}
					a {
						font-weight: 600;
						font-family: $secondaryFont;
						cursor: pointer;
						font-size: 16px;
						line-height: 30px;
						&.fb {
							color: #3b5998;
						}
						&.lk {
							color: #0077b5;
						}
						&.ins {
							color: #1da1f2;
						}
					}
				}
			}
		}
	}
	&--info {
		background-color: #fff;
		width: 50%;
		// @media (max-width: 1200px) {
		//   width: 100%;
		// }

		@media (max-width: 991px) {
			width: calc(100% - 350px);
		}

		@media (max-width: 767px) {
			width: 100%;
			background: #f5f9ff;
		}
	}
	&--form {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f5f9ff;
		width: 50%;
		// .form-control {
		//   color: #596d8c;
		//   &:focus {
		//     color: #596d8c;
		//   }
		// }
		@media (max-width: 1200px) {
			width: 50%;
			padding: 50px 20px;
		}
		@media (max-width: 991px) {
			width: 350px;
		}
		@media (max-width: 767px) {
			width: 100%;
			padding: 30px 20px;
		}
		.wrap {
			// flex-grow: 1;
			width: 432px;
			@media (max-width: 992px) {
				width: 100%;
			}
			@media (max-width: 767px) {
				width: 70%;
			}
			@media (max-width: 575px) {
				width: 100%;
			}
			label {
				& + .form-control {
					border: none;
					padding: 0;
				}
			}
			.button-group {
				margin-top: 35px;
				.btn {
					width: 50%;
					@media screen and (max-width: 480px) {
						width: auto;
						flex-grow: 1;
					}
				}
			}
			.form-control {
				background-color: #f3f3f3;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-family: $secondaryFont;
				color: rgba($color: #6f6f6f, $alpha: 0.85);
				margin: 0;
				@include tablet {
					font-size: 20px;
					line-height: 32px;
				}
				& + p {
					margin-top: 20px;
				}
			}
		}
		.form-link {
			margin-top: 15px;
			a {
				font-family: $secondaryFont;
				font-weight: $semiBold;
				font-size: 16px;
				line-height: 23px;
				color: #6f6f6f;
			}
		}
		.or-seprator {
			position: relative;
			text-align: center;
			margin: 20px 0 14px;
			&::before {
				content: "";
				width: 100%;
				height: 1px;
				background-color: #e0e0e0;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			span {
				font-size: 20px;
				line-height: 1;
				background-color: #f5f9ff;
				padding: 0 10px;
				position: relative;
				color: #173460;
				font-weight: $regular;
				font-family: $secondaryFont;
			}
		}
		h2 {
			font-size: 24px;
			line-height: 34px;
			// text-align: center;
			font-family: $primaryFont;
			font-weight: $bold;
			color: #2a2a2a;
			margin-bottom: 20px;
			@include tablet {
				font-size: 34px;
				line-height: 44px;
			}
		}
		h3 {
			font-weight: $bold;
			font-family: $secondaryFont;
			margin: 0 0 4px;
			font-size: 15px;
			line-height: 33px;
			color: #2a2a2a;
			@include tablet {
				font-size: 18px;
				line-height: 36px;
			}
		}
		.login-options {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			list-style: none;
			margin: 0;
			padding: 0;
			@media (max-width: 575px) {
				display: block;
			}
			li {
				@media (max-width: 992px) {
					width: 100%;
				}
				& + li {
					margin-left: 20px;
					@media (max-width: 575px) {
						margin-left: 0;
						margin-top: 10px;
					}
				}
				a {
					cursor: pointer;
					// background: linear-gradient(270deg, #f4f4f4 0%, #f4f4f4 100%);
					background-color: #fff;
					border: 1px solid #fff;
					border-radius: 4.8px;
					transition: all ease-in-out 0.3s;
					font-size: 0;
					line-height: 20px;
					font-weight: $regular;
					font-family: $secondaryFont;
					color: #6f6f6f;
					width: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 44px;
					@media (max-width: 1280px) {
						font-size: 14px !important;
						padding: 0 10px !important;
					}
					@media (max-width: 992px) {
						width: 100%;
					}
					img {
						@media (max-width: 992px) {
							margin-right: 10px;
						}
					}
					@include tabletLandscape {
						font-size: 14px;
						width: auto;
						padding: 0 20px;
						img {
							margin-right: 10px;
						}
					}
					@include desktop {
						font-size: 16px;

						img {
							margin-right: 20px;
						}
					}
					&:hover {
						background-color: darken($color: #f4f4f4, $amount: 5%);
						transition: all ease-in-out 0.3s;
					}
				}
			}
		}

		.otp-box {
			margin-bottom: 30px;
			display: block;
			ul {
				margin: 2px 0 4px;
				padding: 0;
				display: flex;
				align-items: center;
				list-style: none;
				li {
					width: 50px;
					& + li {
						margin-left: 20px;
					}
					.form-control {
						text-align: center;
						padding: 0;
						background-color: #fff;
						font-weight: $semiBold;
						font-size: 20px;
						color: #6f6f6f;
					}
				}
			}
			p {
				font-weight: $semiBold;
				font-size: 13px;
				line-height: 36px;
				margin: 0;
				color: #6f6f6f;
				font-family: $secondaryFont;
			}
			.btn-link {
				font-size: 13px;
				color: $error;
				margin: 11px 0;
				line-height: 1;
			}
		}
	}

	.onboarding-info {
		margin-top: 30px;
		p {
			font-size: 16px !important;
			color: #000;
			a {
				color: #000;
				text-decoration: underline;
			}
		}
	}
}

.login-tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	// border: 1px solid #eee;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	background-color: #fff;
	list-style: none;
	width: 60%;
	margin: 40px 0;
	border-radius: 50px;
	padding: 0px;
	li {
		width: 50%;
		&:first-child {
			a {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:last-child {
			a {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		a {
			line-height: 1;
			color: #000;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 35px;
			border-radius: 50px;
			font-size: 14px;
			font-weight: 600;
			&.active {
				background-color: #00a689;
				color: #fff;
				pointer-events: none;
			}
		}
	}
}

.password-field {
	position: relative;
	.primary-form-control {
		padding-right: 40px;
	}
	svg {
		position: absolute;
		right: 12px;
		cursor: pointer;
		font-size: 18px;
		top: 31px;
	}
}
