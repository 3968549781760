///////////
// IMAGE //
///////////

.img-fluid {
	width: 100%;
	max-width: none;
}

.img-fluid-max {
  @include img-fluid();
}