.secondary-input {
  position: relative;
  i {
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 32px;
    font-style: normal;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
  }

  .primary-form-control {
    padding-left: 178px;
  }
}
