.page-headline {
  position: relative;
  padding-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-bottom: 3rem;
  }

  :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    position: relative;
    display: inline-flex;
    margin-bottom: calc(#{$headings-margin-bottom} + 12px);

    &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: $primary;
      content: " ";
      bottom: -12px;
    }
  }

  &--title > :first-child {
    margin-bottom: 12px;
  }

  &--white > :first-child::after {
    background-color: $white;
  }
}