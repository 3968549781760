.layout-sticky-subnav {
  .mdk-header-layout {
    overflow: initial;
  }
  .page__subnav {
    position: sticky;
    top: $navbar-height;
    z-index: 2;
  }
  .page__container {
    z-index: 0;
    position: relative;
  }
}

.mdk-header-layout__content {
  z-index: auto !important;
}

@media screen and (max-width: 767px) {
  .mdk-header-layout {
    padding-bottom: 100px;
  }
}

.mdk-header-layout > .mdk-header {
  z-index: 2 !important;
}