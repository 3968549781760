@import "../utils/variables";
.form-group {
  margin-bottom: 30px;
  label {
    display: block;
    font-family: $secondaryFont;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    color: #393e41;
  }
}

.primary-input {
  display: block;

  width: 100%;

  &:focus {
    outline: none;
  }
}

.form-control {
  background: #f3f3f3;
  border-color: #f3f3f3;
  height: 50px;
  font-family: $secondaryFont;
  font-weight: $regular;
  box-shadow: none;
  border: none;
  font-size: 18px;
  resize: none;
  line-height: 23px;
  // color: red;
  color: #596d8c;
  padding: 0 14px;
  // color: rgba(111, 111, 111, 0.85);

  &:focus {
    background: #f3f3f3;
    box-shadow: none;
    text-shadow: none;
    border-color: #f3f3f3;
    // color: green;
    color: #596d8c;
    // color: rgba(111, 111, 111, 0.85);
  }
  &--white {
    background-color: $white;
    color: rgba(111, 111, 111, 0.85);
    border: 1px solid #b7b7b7;
    &:focus {
      color: rgba(111, 111, 111, 0.85);
      background-color: $white;
      border-color: #b7b7b7;
    }
  }
}

textarea {
  &.form-control {
    line-height: 32px;
    padding: 14px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
