.container,
.container-fluid {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@include media-breakpoint-down(sm) {
  .has-drawer-opened .mdk-header-layout__content {
    z-index: 2;
  }
}

.mdk-header-layout {
  overflow: visible; // fix children with position: sticky;
}
.mdk-drawer-layout .container,
.mdk-drawer-layout .container-fluid {
  @include make-container-max-widths($drawer-layout-container-max-widths);
}

.sub-layout {
  .mdk-header-layout__content {
    height: 100%;
  }
  .page-content {
    display: flex;
    flex-direction: column;
  }
}

@include media-breakpoint-up(lg) {
  .sub-layout-drawer .mdk-drawer__content {
    position: absolute;
  }
}

// html,
// body {
//   height: 100%;
//   position: relative;
// }
// .mdk-header-layout,
// .mdk-drawer-layout,
// .mdk-drawer-layout__content {
//   height: initial;
//   min-height: 100%;
// }

