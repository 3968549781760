/////////////
// GENERAL //
/////////////

body {
  .mdk-drawer-layout {
    overflow: visible !important;
    .mdk-drawer[data-persistent] {
      width: 200px !important;
      &.admin-left-panel6 {
        width: 256px !important;
      }
    }
  }
  // .mdk-drawer-layout__content {
  // 	padding-top: 0 !important;
  // }
  .mdk-header {
    position: sticky !important;
    margin-bottom: $spacer;
    height: auto;
    // padding-top: 0 !important;

    .material-icons {
      display: none;
    }

    &--fixed {
      position: static !important;
    }

    &--shadow::after {
      box-shadow: inset 0px 5px 6px -3px rgba($header-shadow-base-color, 0.4);
    }

    &__bg-front {
      background-position: center;
      &::after {
        display: block;
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }
  }

  ////////////////////////////////
  // GRADIENT BACKGROUND HEADER //
  ////////////////////////////////

  .mdk-header--bg-gradient-primary .mdk-header__bg-front::after {
    background-image: $primary-gradient;
    opacity: 0.9;
  }

  .mdk-header--bg-gradient-purple .mdk-header__bg-front::after {
    background-image: linear-gradient(-128deg, #8a4182 0%, #3289dc 100%);
    opacity: 0.9;
  }

  .mdk-header--bg-white .mdk-header__bg-front::after {
    background-color: rgba(white, 0.9);
  }

  @each $color, $value in $theme-colors {
    .mdk-header--bg-#{$color} .mdk-header__bg-front::after {
      background-color: rgba($value, 0.9);
    }
  }
}
