// SIDEBAR BRAND
.sidebar-brand {
	font-weight: $sidebar-brand-weight;
	font-size: $sidebar-brand-size;
	margin-bottom: $sidebar-spacing-y/2;
	display: flex;
	align-items: center;
	&:hover {
		text-decoration: none;
	}
}
.sidebar-brand-icon {
	margin-right: $sidebar-spacing-x/2;
}
.sidebar-brand-header {
	height: $sidebar-brand-height;
	line-height: $sidebar-brand-height;
	padding-left: $sidebar-spacing-x;
	padding-right: $sidebar-spacing-x;
	margin-bottom: $sidebar-spacing-y;
	width: 100%;
}
.sidebar-brand-border {
	border-bottom: 1px solid transparent;
}