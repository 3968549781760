.courses {
  margin: 20px 0 20px;
  @include tablet {
    margin-bottom: 50px;
  }
  p {
    @include tablet {
      margin-bottom: 18px;
    }
  }
  ul {
    list-style: none;
    margin: 0 -10px;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      position: relative;
      background: #f3f3f3;
      border-radius: 4px;
      padding: 0 20px;
      height: 120px;
      width: calc((100% - 40px) / 2);
      font-weight: bold;
      margin: 20px 10px 0;
      font-size: 16px;
      line-height: 18px;
      font-family: $primaryFont;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2a2a2a;
      text-align: center;
      // &:last-child {
      //   width: 100%;
      // }
      @include tabletLandscape {
        width: calc((100% - 60px) / 3);
        font-size: 20px;
        line-height: 20px;
        // &:last-child {
        //   width: calc((100% - 60px) / 3);
        // }
      }
      span {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: $white;
        width: 21px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
          opacity: 0;
        }
      }
      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        cursor: pointer;
        &:checked {
          & + span {
            background-color: $primaryColor;
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.secondary {
    p {
      font-family: $secondaryFont;
      font-weight: $bold;
      font-size: 20px;
      line-height: 36px;
      color: #2a2a2a;
      margin-bottom: 0;
    }
    ul {
      li {
        background-color: $white;
        span {
          background: #f0f0f0;
        }
      }
    }
  }
  &.five-col {
    ul {
      li {
        @include tabletLandscape {
          width: calc((100% - 100px) / 4);
        }
      }
    }
  }
}

.primary-list {
  border: 1px solid #c4c4c4;
  margin-bottom: 5px;
  .wrap {
    align-items: center;
    & + div {
      display: none;
    }
  }
  .icon {
    width: 55px;
    min-width: 1px;
    border: none;
    flex-shrink: 0;
    border: 1px solid #C4C4C4;
    height: 55px;
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }
}
