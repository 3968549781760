/**
* MARGINS AND PADDINGS
*/

$spaceamounts: (
  0,
  3,
  4,
  5,
  8,
  10,
  12,
  14,
  15,
  16,
  20,
  24,
  25,
  27,
  28,
  30,
  32,
  35,
  36,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  100,
  120
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space / 16}rem !important;
    }
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space / 16}rem !important;
    }
  }
}

.width-full {
  width: 100% !important;
}

.aligm-items-start {
  align-items: flex-start !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.lh-1 {
  line-height: 1 !important;
}

button:focus,
a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: none !important;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-tabs-with-btn {
  .wrap {
    p {
      // max-width: 330px;
      font-family: $secondaryFont;
      font-size: 18px;
      line-height: 20px;
      color: $black;
    }
  }
}

.back-button {
  display: flex;
  width: 100%;
  font-family: $secondaryFont;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 14px;
  color: #2a2a2a;
  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    margin-right: 8px;
  }
}

.width-full {
  width: 100%;
}
