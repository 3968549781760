.accordion {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 1.5rem;

  &__item {
    position: relative;
    display: block;

    &:not(:last-child) {
      margin-bottom: -$list-group-border-width;
      border-bottom: $list-group-border-width solid $list-group-border-color;
    }

    &:first-child {
      @include border-top-radius($list-group-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($list-group-border-radius);
    }

    @include hover-focus {
      z-index: 1; // Place hover/active items above their siblings for proper border styling
      text-decoration: none;
    }
  }
  &__toggle {
    padding: $list-group-item-padding-y 0;
    display: flex;
    align-items: center;
    width: 100%;
    color: $body-color;
    font-weight: 500;
  }
  &__toggle-icon {
    transform: rotate(0);
  }
  &__item.open &__toggle-icon {
    transform: rotate(-180deg);
  }

  &__menu-link {
    display: flex;
    align-items: center;
    width: 100%;
    color: rgba($body-color, 0.7);

    &:not(:last-child) {
      margin-bottom: -$list-group-border-width;
      border-bottom: $list-group-border-width solid $list-group-border-color;
    }

    @include hover-focus {
      z-index: 1;
    }

    padding: 0.5rem 0;
    font-size: 13px;
    line-height: 1.5rem;

    &.active {
      color: $body-color;
      font-weight: 500;
    }

    a {
      color: inherit;
    }
  }

  &__submenu {
    padding: 0.5rem 0;

    @include border-bottom-radius(0.25rem);

    a {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 13px;
      padding: 0 1rem;
      line-height: 1.5rem;
      &.active {
        font-weight: 500;
        color: $body-color;
      }
    }
  }

  &--boxed {
    .accordion__toggle {
      padding: $list-group-item-padding-y 1rem $list-group-item-padding-y
        $list-group-item-padding-x;
    }
    .accordion__menu-link {
      padding: 0.5rem 1rem 0.5rem $list-group-item-padding-x;
    }
  }
}

.custom-pills {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
