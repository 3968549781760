// custom-tabs-row
.custom-tabs-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

/* custom tabs */
.custom-tabs {
  border-radius: 4px;
  display: flex;
  span {
    cursor: pointer;
    background-color: #e6e6e6;
    font-size: 15px;
    font-weight: 700;
    color: #6f6f6f;
    letter-spacing: 0.3px;
    height: 38px;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    position: relative;
    &.active {
      background-color: #239f87;
      color: white;
      &:before {
        display: none;
      }
      & + span {
        &:before {
          display: none;
        }
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      width: 1px;
      height: 30px;
      background-color: #bdb9b9;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
      &:before {
        display: none;
      }
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

/* custom-card */
.custom-card {
  background-color: white;
  border-radius: 8px;
  min-height: 190px;
  padding: 20px;
  margin-bottom: 20px;
  .custom-card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .course-name {
      font-size: 16px;
      color: black;
      font-weight: bold;
    }
    .subscribe-text {
      font-size: 14px;
      color: #239f87;
      font-weight: 600;
    }
  }
}

/* course info */
.course-info {
  margin-bottom: 15px;
  ul {
    list-style-type: none;
    margin-left: -40px;
    display: flex;
    margin-bottom: 7px;
    li {
      font-size: 14px;
      color: #6f6f6f;
      position: relative;
      & + li {
        padding-left: 14px;
        margin-left: 10px;
        &:before {
          content: " ";
          background-image: url("https://educrack-dev.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
          width: 4px;
          height: 7px;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }
  }
  p {
    font-size: 14px;
    color: #6f6f6f;
    margin-bottom: 0;
  }
}

/* from-to-wrapper */
.from-to-wrapper {
  display: flex;
  .from-to-box {
    &:first-child {
      margin-right: 75px;
    }
    span {
      display: block;
      font-size: 14px;
      color: #6f6f6f;
      margin-bottom: 5px;
    }
    strong {
      display: block;
      font-size: 14px;
      color: #2a2a2a;
      font-weight: bold;
    }
  }
}

/* course-detail-wrapper */
.overview-detail-wrapper {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 40px;
  .overview-image-box {
    width: 100%;
    height: 247px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }
  }
  .overview-detail-body {
    padding: 20px 25px;
    .overview-heading {
      display: block;
      font-size: 18px;
      color: black;
      margin-bottom: 12px;
      font-weight: bold;
    }
    .text-body {
      margin-bottom: 20px;
      p {
        font-size: 14px;
        color: #6f6f6f;
        margin-bottom: 7px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* inner-heading */
.inner-heading {
  font-size: 16px;
  font-weight: bold;
  color: #2a2a2a;
  margin-bottom: 10px;
  display: block;
}

/* small-custom-card */
.small-custom-card {
  background-color: white;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  strong {
    display: block;
    font-size: 16px;
    color: #2a2a2a;
    margin-bottom: 5px;
    font-weight: bold;
  }
  span {
    display: block;
    font-size: 14px;
    color: #6f6f6f;
  }
}

/* subject-detail-wrapper */
.subject-detail-wrapper {
  margin-bottom: 20px;
}

/* subject-detail-inner */
.subject-detail-inner {
  max-width: 740px;
}

/* teachers-detail-wrapper */
.teachers-detail-wrapper {
  margin-bottom: 20px;
}

/* custom-card-media */
.custom-card-media {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
  .image-box {
    min-width: 60px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .custom-card-media-body {
    margin-left: 20px;
    .heading {
      display: block;
      font-size: 18px;
      color: black;
      margin-bottom: 5px;
    }
    .text {
      display: block;
      font-size: 14px;
      color: #6f6f6f;
      margin-bottom: 12px;
    }
    ul {
      display: flex;
      list-style-type: none;
      margin-left: -42px;
      li {
        margin-right: 50px;
        display: flex;
        align-items: center;
        color: #6f6f6f;
        font-size: 14px;
        img {
          margin-right: 7px;
        }
        span {
          line-height: 1.2;
        }
      }
    }
    p {
      font-size: 14px;
      color: #6f6f6f;
      margin-bottom: 0;
    }
  }
}

// filter-row-wrapper
.filter-row-wrapper {
  max-width: 694px;
  margin-bottom: 25px;
  .filter-row-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: bold;
      color: #2a2a2a;
    }
    .filter-icon {
      cursor: pointer;
    }
  }
  .filter-text {
    font-size: 14px;
    color: #6f6f6f;
    opacity: 0.85;
    margin-top: 12px;
    line-height: 28px;
    margin-bottom: 0;
  }
}

// filter-icon
.filter-icon {
  cursor: pointer;
}

// calendar-wrapper
.calendar-wrapper {
  max-width: 750px;
  margin-bottom: 20px;
}

// lesson-wrapper
.lesson-wrapper {
  max-width: 750px;
}

// small-accordion
.small-accordion {
  margin-bottom: 0;
  .card {
    box-shadow: none;
    border: none;
    margin-bottom: 20px;
    position: relative;
  }
  .card-header {
    height: 60px;
    border-radius: 4px !important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 20px;
    border: none;
    margin-bottom: 0 !important;
    .heading {
      font-size: 16px;
      font-weight: bold;
      color: #2a2a2a;
    }
    .icon-down-arrow {
      font-size: 24px;
      font-weight: bold;
      transform: rotate(270deg);
      margin-right: -7px;
    }
    &.active {
      .icon-down-arrow {
        transform: rotate(0deg);
      }
    }
  }
  .card-body {
    padding: 0 20px 20px 20px;
    .small-acc-box {
      margin-bottom: 20px;
      .course-info {
        margin-bottom: 0;
      }
      strong {
        display: block;
        font-weight: bold;
        font-size: 15px;
        color: #2a2a2a;
        margin-bottom: 5px;
      }
      &:last-child {
        margin-bottom: 0;
        ul {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// test-accordion
.test-accordion {
  .card-header {
    height: 84px;
    .acc-left-side {
      .text {
        display: block;
        font-size: 14px;
        color: #6f6f6f;
        margin-top: 5px;
      }
    }
    .icon-down-arrow {
      font-size: 26px;
    }
  }
}

// test-custom-card
.test-custom-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right {
    display: flex;
    .start-text {
      font-size: 14px;
      color: #239f87;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      &.with-complete {
        text-transform: capitalize;
        cursor: default;
      }
    }
    .retry-text {
      font-size: 14px;
      color: #6f6f6f;
      margin-right: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

// small-media-card
.small-media-card {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  .image-box {
    min-width: 114px;
    width: 114px;
    height: 114px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .small-media-card-body {
    margin-left: 15px;
    strong {
      display: block;
      font-size: 15px;
      color: #2a2a2a;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      color: #6f6f6f;
      margin: 5px 0;
    }
    span {
      font-weight: bold;
      display: block;
      font-size: 14px;
      color: #2a2a2a;
    }
  }
}

// mentor-detail-box
.mentor-detail-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 520px;
  .mentor-detail-box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      display: flex;
      align-items: center;
      .image-box {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .left-body {
        margin-left: 15px;
        strong {
          display: block;
          font-size: 16px;
          font-weight: bold;
          color: #2a2a2a;
        }
        span {
          display: block;
          font-size: 14px;
          color: #6f6f6f;
        }
      }
    }
    .follow-btn {
      min-width: 112px;
      height: 36px;
      padding: 0 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #239f87;
      border: 1px solid #239f87;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        background-color: #239f87;
        color: white;
        transition: 0.5s;
      }
    }
  }
  .text {
    font-size: 14px;
    color: #6f6f6f;
    line-height: 24px;
    margin: 20px 0;
  }
  .spe-text {
    font-size: 14px;
    color: #6f6f6f;
    margin: 0;
    span {
      font-weight: bold;
    }
  }
}

// mentor-info-list
.mentor-info-list {
  display: flex;
  .mentor-info-box {
    width: 33.33%;
    text-align: center;
    strong {
      display: block;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    span {
      width: 118px;
      height: 34px;
      background-color: #e6e6e6;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin: 0 auto;
    }
  }
}

// mentor-detail-box-wrapper
.mentor-detail-box-wrapper {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.assignment-type {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0;
  padding: 15px 0;
  list-style: none;
  li {
    font-weight: 600;
    font-size: 10px;
    line-height: 8px;
    text-align: center;
    text-transform: uppercase;
    color: #35363b;
    display: flex;
    align-items: center;
    & + li {
      margin-left: 15px;
    }
    span {
      width: 11px;
      font-size: 0;
      border-radius: 50%;
      height: 11px;
      margin-right: 5px;
    }
  }
}

.custom-table {
  & + .custom-table {
    margin-top: 30px;
  }
  &.eight-col {
    .custom-table--col {
      width: calc((100% - 105px) / 7);
      &:last-child {
        width: 105px;
      }
    }
  }

  &.two-col {
    .custom-table--col {
      width: calc(100% / 2);
    }
  }

  &.three-col {
    .custom-table--col {
      width: calc(100% / 3);
    }
  }

  &.four-col {
    .custom-table--col {
      width: calc(100% / 4);
    }
  }

  &.five-col {
    .custom-table--col {
      width: calc(100% / 5);
    }

    &.with-action {
      .custom-table--col {
        width: calc((100% - 71px) / 4);
        &:last-child {
          width: 71px;
        }
      }
    }
  }

  &--head {
    .custom-table--row {
      height: auto;
      margin-bottom: 10px;
    }
    .custom-table--col {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #35363b;
    }
  }
  &--body {
    .custom-table--row {
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      min-height: 62px;
      transition: all ease-in-out 0.3s;
      // &:hover {
      //   transition: all ease-in-out 0.3s;
      //   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      //   border-color: transparent;
      // }
      & + .custom-table--row {
        margin-top: 10px;
      }
    }
  }
  &--row {
    display: flex;
    align-items: center;
  }
  &--col {
    padding: 0 20px;
    .table-options {
      position: relative;
      height: 30px;
      // background-color: red;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.3s;
      &:hover {
        .table-options--menu {
          transform: scale(1);
          transition: all ease-in-out 0.3s;
        }
      }

      &--menu {
        position: absolute;
        top: 0;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        margin: 0;
        min-width: 95px;
        padding: 12px 14px;
        list-style: none;
        right: 30px;
        transform: scale(0);

        transform-origin: top right;
        transition: all ease-in-out 0.3s;

        li {
          & + li {
            margin-top: 10px;
          }
          a {
            display: block;
            font-size: 12px;
            line-height: 17px;
            color: #35363b;
            &:hover {
              // text-decoration: underline;
              color: #00a689;
            }
          }
        }
      }

      &:hover {
        // background-color: #00a689;
        transition: all ease 0.3s;
        // color: #fff;
      }
    }
    .btn-danger {
      background: #e60f0f;
      border-radius: 4px;
      min-width: 1px;
      border: none;
      width: 65px;
      height: 34px;
      &:hover {
        background-color: darken($color: #e60f0f, $amount: 5%);
      }
    }

    span {
      font-size: 10px;
      line-height: 14px;
      color: #666666;
      display: block;

      & + span {
        margin-top: 2px;
      }
    }
    p {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      color: #35363b;

      a {
        color: #35363b;
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
      &.expired {
        color: #b6b6b6;
        font-size: 14px;
      }
    }
  }
}

.primary-pagination {
  display: flex;
  align-items: center;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  li {
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
    &.active {
      a {
        border-color: #1ea087;
        pointer-events: none;
      }
    }
    & + li {
      margin-left: 11px;
      position: relative;

      // &::before {
      //   content: '';
      //   height: 8px;
      //   width: 1px;
      //   position: absolute;
      //   left: -6px;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   background-color: #c4c4c4;
      // }
    }
    &:nth-child(2) {
      margin-left: 0;
    }
    &:first-child {
      margin-right: 20px;
      a {
        width: auto;
        height: auto;
      }
    }
    &:last-child {
      margin-left: 20px;
      a {
        width: auto;
        height: auto;
      }
    }
    a {
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #35363b;
      border: 1px solid transparent;
      border-radius: 50%;
      width: 20px;
      height: 20px;

      svg {
        font-size: 16px;
        color: #aaaaaa;
      }
    }
  }
}

.custom-table-info {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #35363b;
  strong {
    font-weight: 800;
  }
}
