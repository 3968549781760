@import "./days-info";
.primary-page-header {
	display: flex;
	align-items: center;
	min-height: 30px;
	margin-bottom: 20px;
	h3 {
		font-weight: 600;
		font-size: 24px;
		line-height: 18px;
		margin: 0;
		color: #111111;
		& + p {
			margin: 8px 0 0;
			@media screen and (max-width: 767px) {
				margin-top: 3px;
			}
		}

		@media screen and (max-width: 767px) {
			font-size: 20px;
			line-height: normal;
		}
	}
	p {
		font-size: 12px;
		line-height: 18px;
		margin: 0 0 0;
		color: #666666;
	}
	a {
		font-weight: 600;
		font-size: 18px;
		line-height: 18px;
		color: #00a689;
	}
}

.custom-thumbnail {
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	cursor: pointer;
	position: relative;
	min-height: 305px;
	padding: 8px 8px 20px;
	h3 {
		font-weight: 600;
		font-size: 16px;
		line-height: 30px;
		margin: 0;
		color: #111111;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 100%;
	}
	p {
		font-size: 12px;
		line-height: 18px;
		margin: 12px 0 0;
		display: flex;
		align-items: center;
		color: #666666;
		svg {
			margin-right: 10px;
			width: 12px;
			stroke: #00a689;
			color: #00a689;
			height: 12px;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		li {
			font-size: 12px;
			line-height: 18px;
			color: #666666;
			& + li {
				margin-left: 20px;
				position: relative;
				&::before {
					content: "";
					background-image: url("https://educrack-prod.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
					width: 4px;
					height: 7px;
					position: absolute;
					left: -10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
	& > span {
		display: flex;
		height: 165px;
		align-items: center;
		justify-content: center;
		background: #f5f7fa;
		border-radius: 16px;
		overflow: hidden;
		&.subscribed {
			&::before {
				content: "";
				// background-image: url(./images/subscribed.svg);
				background-image: url(../../dist/images/batch/subscribed.svg);
				width: 64px;
				height: 64px;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}
}

.primary-breacrumbs {
	display: flex;
	align-items: center;
	// margin: 30px 0;
	list-style: none;
	margin: 0 0 20px;
	padding: 0;
	li {
		&:last-child {
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			color: #00a689;
		}
		& + li {
			&:nth-child(2) {
				margin-left: 10px;
			}
			margin-left: 20px;
			position: relative;
			&:not(:nth-child(2)):before {
				position: absolute;
				left: -14px;
				top: 50%;
				transform: translateY(-50%);
				color: #6c757d;
				height: 7px;
				width: 4px;
				background-image: url("https://educrack-prod.s3.ap-south-1.amazonaws.com/asset-1598354440512.png");
				content: "";
			}
		}
		a {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 18px;
			color: #6f6f6f;
			display: flex;
			align-items: center;
			&.active {
				color: #00a689;
				font-weight: bold;
			}
			.back {
				width: 32px;
				height: 32px;
				background: #ffffff;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
				border-radius: 8px;
				display: flex;
				margin-right: 8px;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.custom-nav-tabs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	p {
		font-weight: bold;
		font-size: 16px;
		line-height: 23px;
		margin: 0;
		color: #00a689;
	}
	button {
		width: 104px;
		height: 40px;
		min-width: 1px;
		border: none;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #00a689;
		box-shadow: 0px 8px 16px rgba(0, 166, 137, 0.32);
		border-radius: 8px;
		font-weight: bold;
		font-size: 12px;
		line-height: 16px;
		color: #ffffff;
	}
	.form-group {
		margin-bottom: 0;
		width: 152px;
	}
	ul {
		// width: 556px;
		display: flex;
		margin: 0;
		padding: 0 16px;
		list-style: none;
		align-items: center;
		background: #ffffff;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: 12px;
		li {
			height: 60px;
			display: flex;
			align-items: center;
			padding: 0 26px;
			font-size: 16px;
			line-height: 23px;
			color: #111111;
			position: relative;
			cursor: pointer;
			&.active {
				font-weight: bold;
				color: #00a689;
				pointer-events: none;

				&::before {
					content: "";
					width: 100%;
					background: #00a689;
					border-radius: 2px 2px 0px 0px;
					height: 2px;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
			&.filter {
				width: 40px;
				height: 40px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				background: linear-gradient(0deg, #00a689, #00a689);
				border-radius: 8px;
				svg {
					width: 24px;
					height: 24px;
					color: #fff;
				}
			}
		}
	}
}

.batch-detail-wrap {
	.wrap-content {
		.subject {
			background: #ffffff;
			margin-top: 20px;
			padding: 20px 28px 24px;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
			border-radius: 12px;
			margin-bottom: 108px;
			.view-all {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 26px;
				a {
					font-weight: 600;
					font-size: 18px;
					line-height: 18px;
					color: #00a689;
				}
			}
			h4 {
				font-weight: 600;
				font-size: 18px;
				line-height: 32px;
				color: #111111;
				margin: 0 0 11px;
			}
			p {
				font-size: 18px;
				line-height: 18px;
				margin-bottom: 0;
				color: #2a2a2a;
				& + p {
					margin-top: 20px;
				}
				span {
					margin-left: 10px;
					font-size: 14px;
					line-height: 14px;
					color: #6f6f6f;
				}
			}
		}
		.overview {
			background: #ffffff;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
			border-radius: 12px;
			.wrap {
				display: flex;
				padding: 16px;
				.img {
					img {
						border-radius: 4px;
					}
				}
				p {
					font-size: 16px;
					line-height: 24px;
					margin-top: 24px;
					color: #666666;
				}
				.course-intro-view {
					display: flex;
					margin-top: 18px;
					p {
						display: flex;
						cursor: pointer;
						font-size: 16px;
						margin: 0;
						line-height: 24px;
						color: #00a689;
						align-items: center;
						img {
							margin-right: 16px;
						}
					}
				}
				.desc {
					padding-left: 32px;
					h3 {
						font-weight: bold;
						font-size: 24px;
						margin: 0;
						line-height: 32px;
						color: #111111;
						& + span {
							font-size: 16px;
							line-height: 24px;
							display: block;
							color: #aaaaaa;
						}
					}
				}
			}
			&--footer {
				border-top: 1px solid #e2e8f0;
				padding: 15px 40px;
				ul {
					list-style: none;
					margin: 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					li {
						font-size: 16px;
						line-height: 24px;
						display: flex;
						align-items: center;
						color: #111111;
						img {
							margin-right: 4px;
						}
					}
				}
			}
		}
	}
}

.our-mentors-ui {
	.sub-heading {
		font-weight: bold;
		font-size: 48px;
		line-height: 48px;
		text-align: center;
		color: #263238;
		margin-bottom: 59px;
	}
	.desc {
		margin: 0;
		padding: 26px 23px 38px 23px;
		font-size: 14px;
		line-height: 20px;
		color: #6f6f6f;
	}
	.bar {
		background: #f2f6f9;
		display: flex;
		.section {
			width: 50%;
			padding: 2px 0 3px 0;
			font-weight: normal;
			font-size: 18px;
			line-height: 28px;
			color: #02c6a2;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				margin-right: 8.5px;
			}
		}
	}
	ul {
		display: flex;
		margin: 0 -15px;
		padding: 0;
		flex-wrap: wrap;
		list-style: none;
		&.three-col {
			li {
				width: calc(100% / 3 - 30px);
			}
		}
		li {
			width: calc(100% / 4 - 30px);
			// margin-right: 30px;
			margin-bottom: 50px;
			margin-left: 15px;
			margin-right: 15px;
			background: #ffffff;
			border: 1px solid #e2e9f0;
			box-sizing: border-box;
			box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
			border-radius: 24px;
			text-align: center;
			.info {
				font-weight: normal;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				color: #000000;
				margin: 0 0 16px;
				.name {
					display: block;
					font-weight: bold;
				}
			}
			.img {
				padding-top: 30px;
				padding-bottom: 11px;
				img {
					background-color: #e8e8e8;
					width: 70px;
					height: 70px;
					border-radius: 50%;
					object-fit: contain;
				}
			}
		}
	}
}

.course-schedule {
	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		list-style: none;
		margin: 0 0 15px;
		padding: 14px 0 0;
		li {
			font-weight: 600;
			font-size: 16px;
			line-height: 23px;
			color: #2a2a2a;
			& + li {
				margin-left: 23px;
			}
		}
	}
	img {
		margin: 0 auto;
		display: block;
	}
}

.batch-accordion {
	& + .batch-accordion {
		margin-top: 10px;
	}
	h3 {
		font-weight: 600;
		font-size: 16px;
		line-height: 32px;
		margin: 0 0 12px;
		color: #2a2a2a;
	}

	.accordion {
		margin-bottom: 0;
		& + .accordion {
			margin-top: 10px;
		}
		.card {
			margin: 0;
			background: #ffffff;
			border-radius: 6px;
			border: 1px solid rgba(216, 216, 216, 0.55);
			box-sizing: border-box;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
			box-shadow: none;
			border: none;

			&-body {
				background-color: #ffffff;
				padding: 0 9px 0;
				.test-wrap {
					background: #f5f9ff;
					border-radius: 12px;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					padding: 20px 16px 0;
					.test-card {
						width: calc((100% - 48px) / 4);
						background: #ffffff;
						margin-bottom: 20px;
						border-radius: 12px;
						text-align: center;
						a {
							background: #a9eadf;
							border-radius: 0px 0px 12px 12px;
							height: 40px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-weight: 600;
							font-size: 14px;
							line-height: 20px;
							color: #097461;
							&.view-report {
								background: linear-gradient(0deg, #c4c4c4, #c4c4c4), #00a689;
								color: #000000;
								text-decoration: underline;
								font-weight: normal;
							}
						}
						.wrap {
							padding: 23px 10px 16px;
							h3 {
								font-weight: 600;
								font-size: 16px;
								line-height: 23px;
								margin: 0 0 50px;
								color: #111111;
							}
							p {
								margin: 0;
								font-size: 14px;
								line-height: 20px;
								color: #666666;
								span {
									color: #00a689;
								}
							}
						}
					}
				}

				.lesson-wrap {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					.wrapper {
						width: calc(50% - 4px);
						margin-bottom: 26px;
						h4 {
							height: 30px;
							padding: 0 20px;
							display: flex;
							align-items: center;
							margin: 0 0 15px;
							background: #e2e9f0;
							border-radius: 6px;
							font-weight: 600;
							font-size: 14px;
							line-height: 1;
							color: #2a2a2a;
						}
						ul {
							margin: 0;
							list-style: none;
							padding: 0 20px;
							li {
								display: flex;
								align-items: center;
								& + li {
									margin-top: 10px;
								}
								.icon {
									display: flex;
									align-items: center;
									justify-content: center;
									background: #f5f9ff;
									width: 53px;
									height: 37px;
								}
								p {
									margin: 0;
									padding-left: 12px;
									width: calc(100% - 53px);
									display: flex;
									align-items: center;
								}
							}
						}
					}
				}
			}
			&-header {
				display: block;
				padding: 17px 17px;
				margin: 0;
				border: none;
				cursor: pointer;
				position: relative;
				&.active {
					p {
						color: #111111;
					}
					&::after {
						transform: translateY(-50%) rotate(90deg);
					}
					ul li {
						font-weight: 600;
						color: #00a689;
					}
					&::before {
						content: "";
						width: 3px;
						height: 20px;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						position: absolute;
						background: #00a689;
						border-radius: 0px 2px 2px 0px;
					}
				}
				p {
					font-size: 16px;
					line-height: 23px;
					margin: 0;
					color: #838383;
					@media screen and (max-width: 767px) {
						font-size: 12px;
						line-height: 1.5;
					}
				}
				& > ul {
					margin: 0;
					list-style: none;
					padding: 0;
					display: flex;
					align-items: center;
					li {
						font-size: 16px;
						line-height: 1;
						color: #707070;
						& + li {
							margin-left: 23px;
							position: relative;
							&:before {
								content: "";
								width: 1px;
								height: 100%;
								background-color: #000;
								position: absolute;
								left: -11px;
							}
						}
					}
				}
				&::after {
					content: "";
					// background-image: url("./images/right.svg");
					background-image: url("../../dist/images/batch/right.svg");
					width: 8px;
					height: 14px;
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.resource {
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 12px;
	padding: 24px;
	&--row {
		display: flex;
		flex-wrap: wrap;
		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #f5f9ff;
			width: 53px;
			height: 37px;
		}

		p {
			margin: 0;
			padding-left: 12px;
			width: calc(100% - 53px);
			display: flex;
			align-items: center;
			a {
				font-size: 14px;
				line-height: 24px;
				color: #666666;
				&:hover {
					color: #00a689;
				}
			}
		}
		& + .resource--row {
			margin-top: 10px;
		}
	}
}

.advertisement-block {
	padding: 25px;
	flex-grow: 0 !important;
	width: 280px;
	display: none;

	.advertisement-banner {
		& + .advertisement-banner {
			margin-top: 16px;
		}
		height: 378px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffff;
		border-radius: 8px;
		p {
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			color: #1458ea;
			margin: 0;
		}
	}
}

.rewards-points {
	height: 200px;
	background-size: cover;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	background-image: linear-gradient(
		137.27deg,
		#1feec6 12.53%,
		#22cfae 28.83%,
		#10bc9b 47.03%,
		#239f87 65.13%,
		#108770 81.44%,
		#076040 100%
	);
	border-radius: 8px;
	.wrap {
		position: relative;
		z-index: 2;
		p {
			font-size: 18px;
			line-height: 18px;
			margin: 0 0 5px;
			color: #ffffff;
		}
		span {
			display: block;
			font-weight: bold;
			font-size: 40px;
			line-height: 36px;
			letter-spacing: 0.16em;
			color: #ffffff;
		}
		button {
			width: 88px;
			height: 32px;
			border: none;
			background: #ffffff;
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			margin-top: 18px;
			text-align: center;
			color: #239f87;
			box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
			border-radius: 8px;
		}
	}
	&::before {
		content: "";
		// background-image: url(./images/rewards-bg.svg);
		background-image: url("../../dist/images/batch/rewards-bg.svg");
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.primary-filter {
	display: flex;
	margin-bottom: 30px;
	align-items: center;
	form {
		display: flex;
		// width: calc(100% - 37px);
		width: 100%;
		align-items: center;
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
		}
	}
	.form-group {
		margin: 0;
		margin-right: 0;
		width: 100%;

		& + .form-group {
			margin-left: 20px;
			@media screen and (max-width: 767px) {
				margin-top: 10px;
				margin-left: 0;
			}
		}
	}
	.filter-icon {
		width: 37px;
		height: 37px;
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		border-radius: 8px;
		svg {
			width: 19px;
			fill: #6f6f6f;
			height: 19px;
		}
	}
}

.mentor-details {
	margin: 25px 0;
	p {
		font-size: 16px;
		line-height: 20px;
		margin: 0;
		color: #6f6f6f;
		& + p {
			margin-top: 1rem;
		}
	}
	&--header {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 35px;
		.mentor {
			&-icon {
				padding: 0 40px;
				img {
					width: 155px;
					object-fit: contain;
					height: 155px;
					border-radius: 50%;
					background-color: #eee;
				}
			}
			&-data {
				h3 {
					font-weight: bold;
					font-size: 20px;
					line-height: 36px;
					margin: 0 0 6px;
					color: #000000;
				}
				ul {
					list-style: none;
					margin: 48px 0 0;
					padding: 0;
					display: flex;
					li {
						text-align: center;
						font-weight: bold;
						font-size: 18px;
						line-height: 32px;
						color: #2a2a2a;
						& + li {
							margin-left: 20px;
						}
						&.video-link {
							font-weight: 600;
							font-size: 14px;
							margin-left: 45px;
							line-height: 20px;
							text-decoration: underline;
							color: #263238;
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 164px;
							background: #e2e9f0;
							border-radius: 8px;
							transition: all ease-in-out 0.3s;
							&:hover {
								transition: all ease-in-out 0.3s;
								background-color: darken($color: #e2e9f0, $amount: 5%);
							}
							img {
								margin-right: 20px;
							}
						}
						strong {
							width: 118px;
							height: 34px;
							display: flex;
							align-items: center;
							justify-content: center;
							background: #e6e6e6;
							border-radius: 8px;
							font-size: 14px;
							line-height: 24px;
							color: #6f6f6f;
						}
					}
				}
				span {
					display: block;
					font-size: 16px;
					line-height: 18px;
					color: #6f6f6f;
					& + span {
						margin-top: 10px;
					}
				}
			}
		}
	}
}

.primary-sidebar {
	width: 248px;
	padding: 24px 8px;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	ul {
		flex-grow: 1;
		list-style: none;
		margin: 0;
		// padding: 0 16px;
		padding: 0;

		li {
			margin-bottom: 8px;
			&.active {
				a {
					background: #ffe200;
					box-shadow: 0px 8px 16px rgba(255, 209, 0, 0.3);
				}
			}
			a {
				display: flex;
				padding: 12px 16px;
				border-radius: 50px;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
		}
	}
}

.transactions {
	margin-bottom: 30px;
	h3 {
		font-size: 20px;
		line-height: 18px;
		margin: 0 0 17px;
		color: #2a2a2a;
	}
	&--row {
		& + .transactions--row {
			margin-top: 10px;
		}
		display: flex;
		padding: 14px 14px;
		align-items: center;
		justify-content: space-between;
		background: #ffffff;
		border: 1px solid rgba(216, 216, 216, 0.55);
		box-sizing: border-box;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: 6px;
		.wrap {
			p {
				font-size: 16px;
				line-height: 16px;
				color: #2a2a2a;
			}

			span {
				display: block;
				font-size: 12px;
				line-height: 16px;
				margin-top: 2px;
				color: #6f6f6f;
			}
		}
		p {
			margin: 0;
			display: flex;
			align-items: center;
		}
		& > p {
			font-size: 16px;
			line-height: 18px;
			color: #2a2a2a;
			&.paytm-price {
				font-size: 20px;
				line-height: 20px;
				letter-spacing: -0.24px;
				color: #2a2a2a;
			}
			&.price {
				font-size: 26px;
				line-height: 20px;
				letter-spacing: -0.24px;
				color: #239f87;
			}
			span {
				margin-left: 2px;
				font-size: 12px;
			}
			img {
				margin-right: 3px;
			}
		}
	}
}

.referral-code {
	background: #ffffff;
	border: 1px solid rgba(216, 216, 216, 0.55);
	padding: 25px 20px 0;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 6px;
	img {
		display: block;
		margin: 0 auto;
	}
}

.reward-banner {
	background: #ffffff;
	margin-bottom: 30px;
	padding-right: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid rgba(216, 216, 216, 0.55);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 6px;
	.wrap {
		button {
			width: 149px;
			height: 35px;
			margin-top: 6px;
			border: none;
			font-size: 16px;
			line-height: 18px;
			color: #ffffff;
			background: #239f87;
			border-radius: 4px;
			transition: all ease-in-out 0.3s;
			&:hover {
				background-color: darken($color: #239f87, $amount: 5%);
				transition: all ease-in-out 0.3s;
			}
		}
		p {
			font-size: 16px;
			line-height: 20px;
			text-align: center;
			letter-spacing: -0.24px;
			color: #2a2a2a;
			margin: 10px 0 0;
		}
		h4 {
			font-size: 50px;
			line-height: 20px;
			letter-spacing: -0.24px;
			color: #2a2a2a;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				margin-left: 2px;
				font-weight: normal;
				font-size: 20px;
			}
		}
	}
	.image {
		width: 280px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 186.67px;
		position: relative;
		img {
			position: relative;
		}
		&::before {
			content: "";
			// background: url(./images/reward-bg.svg);
			background: url("../../dist/images/batch/reward-bg.svg");
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

.link-paytm {
	margin-top: 40px;
	.wrap {
		width: 342px;
		margin: 0 auto;
		p {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 25px;
			text-align: center;
			color: #6f6f6f;
		}
		.link-box {
			width: 342px;
			height: 61px;
			padding: 0 20px;
			background: #ffffff;
			border: 1px solid rgba(216, 216, 216, 0.55);
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
			border-radius: 6px;
			display: flex;
			align-items: center;
			button {
				width: 69px;
				height: 35px;
				border: none;
				background: #239f87;
				border-radius: 4px;
				flex-shrink: 0;
				font-size: 16px;
				line-height: 18px;
				color: #ffffff;
				transition: all ease-in-out 0.3s;
				&:hover {
					background-color: darken($color: #239f87, $amount: 5%);
					transition: all ease-in-out 0.3s;
				}
			}
			span {
				font-size: 16px;
				line-height: 20px;
				color: #6f6f6f;
			}
			input {
				padding: 0 16px;
				width: calc(100% - (69px + 24px));
				border: none;
				font-size: 20px;
				line-height: 20px;
				color: #6f6f6f;
				&::placeholder {
					color: #b6b6b6;
				}
			}
		}
		img {
			display: block;
			margin: 0 auto 13px;
		}
	}
}

.redeem-button {
	display: flex;
	justify-content: flex-end;
	button {
		width: 179px;
		font-size: 16px;
		line-height: 18px;
		color: #ffffff;
		height: 35px;
		background: #239f87;
		border-radius: 4px;
		border: none;
		transition: all ease-in-out 0.3s;
		&:hover {
			background-color: darken($color: #239f87, $amount: 5%);
			transition: all ease-in-out 0.3s;
		}
	}
}

.statistics {
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	padding: 16px 24px 24px;
	h3 {
		font-weight: 600;
		font-size: 20px;
		margin: 0;
		line-height: 36px;
		color: #2a2a2a;
	}
	img {
		display: block;
		margin-top: 10px;
	}
}

.assignment {
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	padding: 16px;
	&--row {
		& + .assignment--row {
			margin-top: 24px;
		}
		& > p {
			font-size: 12px;
			line-height: 32px;
			margin: 0;
			color: #6f6f6f;
			opacity: 0.85;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: space-between;
			li {
				width: calc(50% - 4px);
				background: #f5f9ff;
				border: 0.5px solid #e0e6ee;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				border-radius: 16px;
				padding-left: 9px;
				padding-right: 16px;
				cursor: pointer;
				padding-top: 14px;
				padding-bottom: 14px;
				.wrap {
					flex-grow: 1;
					padding-left: 25px;
					position: relative;
					svg {
						width: 18px;
						height: 18px;
						color: #00a689;
						position: absolute;
						left: 0;
						top: 0;
					}
					h3 {
						margin: 0;
						font-weight: 600;
						font-size: 18px;
						line-height: 20px;
						color: #000000;
					}
					& > span {
						font-weight: 600;
						font-size: 10px;
						line-height: 20px;
						display: block;
						color: #000000;
					}
					p {
						display: flex;
						margin: 0;
						align-items: center;
						span {
							font-size: 12px;
							line-height: 20px;
							color: #666666;
							& + span {
								margin-left: 20px;
								position: relative;
								&::before {
									content: "•";
									font-size: 12px;
									line-height: 20px;
									position: absolute;
									left: -11px;
									color: #666666;
								}
							}
						}
					}
				}
				& > svg {
					flex-shrink: 0;
					width: 24px;
					height: 24px;
					color: #aaaaaa;
				}
			}
		}
	}
}

.assigned-test-box {
	margin-top: 40px;
	h3 {
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;

		color: #00a689;
		margin: 0 0 17px;
	}
}

.primary-batch-card {
	border-radius: 10px;
	background: #fff;
	margin-bottom: 30px;
	transition: all ease-in-out 0.3s;
	border: 1px solid #e5e5e5;
	// overflow: hidden;
	cursor: pointer;
	padding: 8px;

	&:hover {
		box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15);
		transition: all ease-in-out 0.3s;
		border-color: transparent;
	}
	&--header {
		height: 136px;
		position: relative;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 6px;
		}
		p {
			font-size: 9px;
			line-height: 11px;
			text-align: center;
			padding: 4px 5px;
			color: #343434;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			margin: 0;
			background-color: rgba(255, 255, 255, 0.9);
		}
	}
	&--info {
		border-top: 1px solid #e9e9e9;
		margin: 0 -8px;
		padding: 8px 0 0 12px;
		& > ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			line-height: 1;
			min-height: 47px;
			& > li {
				padding: 4px 6px;
				border-radius: 2px;
				font-weight: 600;
				font-size: 10px;
				line-height: 13px;
				text-transform: uppercase;
				color: #000000;

				&.ml-auto {
					& > div {
						padding: 0;
					}
				}

				&.edit-icon {
					margin-left: auto;
					// width: 19px;
					// height: 19px;
					// border-radius: 50%;
					// display: flex;
					// align-items: center;
					// justify-content: center;
					cursor: pointer;
					padding: 0;
					background: #fff;
					color: #239f87;
					font-size: 16px;
					// border: 1px solid #239f87;
				}
				& + li {
					margin-left: 10px;
				}
			}
		}
	}
	&--body {
		padding: 0;
		h3 {
			margin: 0;
			font-weight: 600;
			font-size: 16px;
			line-height: 30px;
			color: #111111;
		}
		ul {
			margin: 0;
			padding: 8px 0 14px;
			list-style: none;
			li {
				font-size: 12px;
				line-height: 18px;
				display: flex;
				align-items: center;
				color: #666666;
				& + li {
					margin-top: 6px;
				}
				svg {
					color: #00a689;
					margin-right: 8px;
				}
			}
		}
		p {
			font-weight: normal;
			margin: 0;
			display: flex;
			align-items: center;
			font-size: 12px;
			line-height: 18px;
			color: #666666;

			span {
				margin: 0 7px;
			}

			& + p {
				margin-top: 2px;
			}
		}
	}
}

.result-container {
	.primary-batch-tabs {
		justify-content: center;
		position: sticky;
		top: 71px;
		z-index: 1;
		background: white;
		padding: 10px 0;
	}
}

.primary-batch-tabs {
	list-style: none;
	margin: 0 0 23px;
	padding: 0;
	display: flex;
	align-items: center;
	&--content {
		.free-resource {
			.primary-button {
				height: 36px;
				min-width: 1px;
				margin-top: 19px;
				padding: 0;
				width: 73px;
			}
		}
		.assign-teacher-subject {
			.wrap {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 20px;
				.date {
					width: 60px;
					display: flex;
					& > p {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;
						span {
							font-size: 14px;
							color: #48506a;
						}
					}
				}
				ul {
					list-style: none;
					margin: 0 -10px;
					width: calc(100% - 50px);
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					li {
						background: #fff;
						width: calc((100% - 40px) / 4);
						margin: 0 5px 10px;
						padding: 10px 20px;
						p {
							margin: 0;
							font-size: 12px;
							color: #000;

							a {
								text-decoration: underline;
								color: #01b96b;
							}

							& + p {
								margin-top: 8px;
							}
						}
					}
				}
			}
		}
		.day-wise-slot {
			margin-top: 25px;

			.wrap {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 20px;
				align-items: center;

				.day {
					width: 120px;
				}
				.custom-control.custom-checkbox label.custom-control-label {
					font-size: 14px;
					text-transform: none;
					color: #000;
				}

				ul {
					list-style: none;
					margin: 0 0 0 30px;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					li {
						display: flex;
						align-items: center;
						padding: 15px 13px;
						border-radius: 4px;
						background: #fff;
						border: 1px solid #e6e6e6;
						& + li {
							margin-left: 10px;
						}
						svg {
							cursor: pointer;
						}

						p {
							margin: 0;
							font-size: 14px;
							color: #000;
						}
						svg {
							margin-left: 20px;
						}
					}
				}

				.add-slot {
					width: 60px;
					cursor: pointer;
					height: 20px;
					border-radius: 10px;
					background: #fff;
					border: 1px solid #239f87;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					color: #000;
					transition: all 0.3s ease-in-out;
					&:hover {
						background-color: #239f87;
						color: #fff;
						transition: all 0.3s ease-in-out;
					}

					&.disbaled {
						opacity: 0.22;
						pointer-events: none;
					}
				}
			}
		}

		.batch-total-info {
			display: flex;
			align-items: center;
			margin: 33px 0 0;
			padding: 0;
			list-style: none;
			li {
				font-size: 13px;
				line-height: 1;
				color: #48506a;
				& + li {
					margin-left: 15px;
				}
				strong {
					font-weight: 600;
					font-size: 16px;
				}
			}
		}
		.topic-box {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			margin: 0 -10px;
			padding: 0;
			li {
				width: calc((100% - 60px) / 3);
				display: flex;
				flex-direction: column;
				min-height: 115px;
				border-radius: 6px;
				background: #fff;
				padding: 10px 20px;
				margin: 0 10px 20px;
				border: 1px solid #e6e6e6;
				svg {
					margin-left: auto;
					display: block;
					cursor: pointer;
					margin-top: 5px;
				}
				h3 {
					margin: 0;
					font-size: 16px;
					line-height: 22px;
					color: #000;
					flex-grow: 1;
				}
				p {
					margin: 0 0 3px;
					font-size: 12px;
					line-height: 22px;
					color: #919191;
				}
			}
		}
	}
	li {
		// & + li {
		//   margin-left: 25px;
		// }
		user-select: none;
		font-weight: 600;
		font-size: 16px;
		line-height: 15px;
		display: block;
		cursor: pointer;
		color: #35363b;
		height: 36px;
		border: 1px solid transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 20px;
		padding: 0 15px;

		// &::before {
		//   content: "";
		//   height: 2px;
		//   background: transparent;
		//   position: absolute;
		//   transform: scale(0);
		//   transition: all 0.3s ease-in-out;
		//   transform-origin: center center;
		//   left: 0;
		//   bottom: -4px;
		//   width: 100%;
		// }
		&.active {
			background: #f5f7fa;
			border: 1px solid #1ea087;
			color: #1ea087;
			pointer-events: none;
			position: relative;
			// &::before {
			//   transition: all 0.3s ease-in-out;
			//   background: #00a689;
			//   transform: scale(1);
			// }
		}
		a {
			user-select: none;
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			display: block;
			cursor: pointer;
			color: #6f6f6f;
			&::before {
				content: "";
				height: 2px;
				background: transparent;
				position: absolute;
				transform: scale(0);
				transition: all 0.3s ease-in-out;
				transform-origin: center center;
				left: 0;
				bottom: -4px;
				width: 100%;
			}
			&.active {
				color: #00a689;
				pointer-events: none;
				position: relative;
				&::before {
					transition: all 0.3s ease-in-out;
					background: #00a689;
					transform: scale(1);
				}
			}
		}
	}
}

.batch-sections {
	margin-bottom: 30px;
	border-top: none !important;
	h3 {
		font-weight: 600;
		font-size: 18px;
		line-height: 23px;
		margin-bottom: 20px;
		color: #35363b;
	}
	&--row {
		display: flex;
		align-items: center;
	}
	&--col {
		display: flex;
		flex-wrap: wrap;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: #35363b;

		p {
			font-size: 12px;
			font-weight: 600;
			display: flex;
			align-items: center;
			line-height: 1;
			svg {
				font-size: 20px;
				margin-left: 5px;
				color: #c4c4c4;
				cursor: pointer;
			}
			a {
				text-decoration: underline;
				font-weight: normal;
				color: #35363b;
			}
		}

		&:first-child {
			width: 300px;
		}
		&:last-child {
			width: calc(100% - 300px);
		}
		// align-items: center;
	}
	&--head {
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: 7px;
		.batch-sections--col {
			font-weight: bold;
			color: #35363b;
			font-size: 12px;
			line-height: 17px;
		}
	}
	&--body {
		.batch-sections--row {
			padding: 10px 0;
			border-bottom: 1px solid #e6e6e6;
			.custom-control.custom-checkbox label.custom-control-label {
				text-transform: capitalize;
				font-weight: 600;
				font-size: 12px;
				// line-height: 15px;
				color: #35363b;

				// &:before {
				//   top: 0;
				// }
			}
			.custom-control {
				margin-right: 20px;
				display: flex;
				align-items: center;

				.custom-control-input {
					display: none;
				}
			}
		}
	}
}

// .batch-upload-box {
//   label {
//     text-transform: capitalize !important;
//   }
// }

.resource-list {
	margin: 40px 0;
	&--head {
		.resource-list--col {
			font-size: 13px;
			color: #48506a;
		}
	}
	&--body {
		padding-top: 14px;
	}
	&--row {
		display: flex;
		align-items: center;
		& + .resource-list--row {
			margin-top: 35px;
		}
	}
	&--col {
		font-size: 14px;
		color: #000;
		flex-grow: 1;
		display: flex;
		align-items: center;
		strong {
			font-weight: 600;
			text-transform: uppercase;
			font-size: 14px;
			color: #000;
		}
		a {
			font-size: 14px;
			color: #239f87;
		}
		img {
			& + svg {
				margin-left: 40px;
			}
		}
		svg {
			& + svg {
				margin-left: 40px;
			}
		}
		&:first-child {
			width: calc(94px + 99px);
			flex-grow: 0;
		}
	}
}

.topic-list-wrap {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 265px;
	overflow: auto;
	li {
		padding: 10px 5px;
		border-bottom: 1px solid #eee;
	}
}

.free-resources {
	& > p {
		font-size: 14px;
		line-height: 20px;
		color: #35363b;
	}
	ul {
		list-style: none;
		margin: 0 0 30px;
		padding: 0;
		li {
			background: #ffffff;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
			border-radius: 12px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 15px 20px;
			& + li {
				margin-top: 10px;
			}
			svg {
				font-size: 16px;
				cursor: pointer;
				color: #c4c4c4;
			}
			p {
				font-size: 16px;
				width: calc(100% - 16px);
				line-height: 23px;
				color: #111111;
				padding-left: 25px;
				padding-right: 15px;
				margin: 0;
				position: relative;
				svg {
					color: #1ea087;
					position: absolute;
					left: 0;
					top: 4px;
				}
			}
		}
	}
}

.primary-batch-tabs {
	&--content {
		.course-box {
			background: #ffffff;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
			border-radius: 12px;
			padding: 25px;
			margin-bottom: 25px;
			display: flex;
			flex-wrap: wrap;
			.course-detail {
				width: calc(100% - 200px);
				padding-left: 25px;
				.details {
					display: flex;
					align-items: center;
					margin-top: 40px;
					// justify-content: space-between;
					p {
						display: flex;
						align-items: center;
						font-size: 16px;
						line-height: 24px;
						margin-bottom: 0;
						color: #c2b5b5;
						margin-right: 20px;
						svg {
							color: #00a689;
							// font-size: 24px;
							margin-right: 8px;
						}
					}
					a {
						display: flex;
						align-items: center;
						font-weight: 600;
						font-size: 16px;
						line-height: 24px;
						color: #00a689;
						.play-icon {
							width: 34px;
							height: 34px;
							margin-right: 20px;
							display: flex;
							align-items: center;
							position: relative;
							border-radius: 50%;
							justify-content: center;
							background: #00a689;
							&::before,
							&::after {
								content: "";
								position: absolute;
							}
							&::before {
								width: 48px;
								height: 48px;
								border-radius: 50%;
								background: #00a689;
								opacity: 0.4;
							}
							&:after {
								border-radius: 50%;
								width: 56px;
								height: 56px;
								background: #00a689;
								opacity: 0.16;
							}
							svg {
								color: #ffd100;
								position: relative;
								// color: #fff;
								// border: 1px solid #fff;
							}
						}
					}
				}
				h3 {
					margin: 0;
					font-weight: bold;
					font-size: 24px;
					line-height: 1;
					color: #111111;
				}
				ul {
					display: flex;
					align-items: center;
					list-style: none;
					margin: 10px 0 0;
					padding: 0;
					li {
						& + li {
							margin-left: 23px;
							position: relative;
							&::before {
								position: absolute;
								left: -14px;
								top: 50%;
								transform: translateY(-50%);
								color: #6c757d;
								height: 7px;
								width: 4px;
								background-image: url(https://educrack-prod.s3.ap-south-1.amazonaws.com/asset-1598354440512.png);
								content: "";
							}
						}
						a {
							font-size: 14px;
							line-height: 18px;
							display: block;
							color: #666666;
						}
					}
				}
			}
			.course-image {
				width: 200px;
				background: #f5f9ff;
				border-radius: 6px;
				height: 136px;
				overflow: hidden;
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
		.subject-topic {
			& > p {
				padding-left: 300px;
				display: flex;
				a {
					font-weight: 600;
					font-size: 12px;
					line-height: 15px;
					text-decoration: underline;
					color: #1ea087;
				}
			}
		}
		.batch-sections {
			&--col {
				ul {
					list-style: none;
					margin: 0 -10px;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					li {
						margin: 0 10px 10px;
						&:not(:last-child) {
							position: relative;
							&::after {
								content: "";
								height: 7px;
								width: 1px;
								background-color: #35363b;
								position: absolute;
								right: -10px;
								top: 50%;
								transform: translateY(-50%);
							}
						}
						p {
							font-weight: normal;
							font-size: 13px;
							line-height: 20px;
							margin: 0;
							display: block;
							color: #35363b;
						}
						a {
							font-weight: 600;
							font-size: 12px;
							line-height: 15px;
							display: block;
							color: #35363b;
						}
					}
				}
			}
		}
		.day-wise-slot {
			margin-bottom: 40px;
			h3 {
				margin: 0;
				font-weight: 600;
				font-size: 16px;
				line-height: 30px;
				color: #111111;
			}
		}
		.description {
			margin-bottom: 40px;
			h3 {
				margin: 0;
				font-weight: 600;
				font-size: 16px;
				line-height: 30px;
				color: #111111;
			}

			table {
				width: 100% !important;
				margin: 20px 0;
				border: #000 1px solid;
				td {
					padding: 10px;
				}
			}

			p {
				font-size: 16px;
				line-height: 24px;
				margin: 0;
				color: #35363b;

				& + p {
					margin-top: 15px;
				}
			}
		}
	}
}

.batch-accordion .accordion .card-header p span {
	font-size: 16px;
	line-height: 23px;
	color: #707070;

	@media screen and (max-width: 767px) {
		font-size: 12px;
		line-height: 1.5;
		white-space: nowrap;
		padding-left: 10px;
		text-align: right;
	}
}

.accordion .card-body {
	.lesson-notes-list {
		margin: 15px 0 0;
		padding: 0;
		list-style: none;
		li {
			display: flex;
			& + li {
				margin-top: 10px;
			}
			a {
				display: flex;
				align-items: center;
				font-size: 14px;
				line-height: 24px;
				color: #00a689;
				div {
					&:not(:first-child):last-child {
						padding-left: 12px;
					}
				}
				&:hover {
					text-decoration: underline;
				}
				span {
					width: 53px;
					height: 37px;
					display: flex;
					flex-shrink: 0;
					margin-right: 12px;
					align-items: center;
					justify-content: center;
					background: rgba(0, 166, 137, 0.1);
					border-radius: 4px;
					svg {
						font-size: 24px;
						color: #454545;
					}
				}
			}
		}
	}
	.add-box {
		display: flex;
		padding: 0 10px;
		align-items: center;
		justify-content: space-between;
		height: 30px;
		background: #f0f0f0;
		border-radius: 8px;

		p {
			font-weight: 600;
			font-size: 14px;
			line-height: 1;
			display: flex;
			align-items: center;
			color: #111111;
			margin: 0;
			svg {
				margin-right: 5px;
				color: #0b8f75;
				font-size: 18px;
			}
		}
	}
}

.primary-timeline {
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	&.transparent {
		background: transparent;
		box-shadow: none;
		border-radius: 0;
		.primary-timeline {
			&--body {
				padding: 0;
			}
		}
	}
	&--header {
		border-bottom: #e2e8f0 1px solid;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.days-dropdown {
			& > a {
				min-width: 144px;
				display: flex;
				align-items: center;
				padding: 0 10px;
				height: 40px;
				justify-content: space-between;
				background: #f0f0f0;
				border-radius: 8px;
				font-weight: bold;
				font-size: 18px;
				line-height: 18px;
				/* identical to box height, or 100% */

				color: #111111;
				svg {
					color: #111111;
					font-size: 24px;
				}
			}
		}
	}

	&--body {
		padding: 15px;
		.timeline-row {
			display: flex;
			align-items: center;
			& + .timeline-row {
				margin-top: 15px;
			}
			.wrap {
				width: calc(100% - 60px);
				display: flex;
				flex-wrap: wrap;
				padding-left: 50px;

				.timeline-card {
					margin-bottom: 15px;
					& + .timeline-card {
						margin-left: 30px;
					}
					background: #f3fcfb;
					border: 1px solid #00a689;
					box-sizing: border-box;
					border-radius: 6px;
					width: calc(50% - 15px);
					padding: 8px 15px;
					p {
						font-size: 14px;
						line-height: 20px;
						margin: 0;
						/* identical to box height, or 143% */

						display: flex;
						align-items: center;

						color: #111111;
						a {
							font-weight: 600;
							font-size: 14px;
							line-height: 20px;
							/* identical to box height, or 143% */

							display: flex;
							align-items: center;

							color: #ff0000;
						}
					}
					strong {
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						/* identical to box height, or 143% */

						display: flex;
						align-items: center;

						color: #111111;
					}
					span {
						font-weight: bold;
						font-size: 12px;
						line-height: 20px;
						display: flex;
						align-items: center;

						color: #239f87;
					}
					i {
						font-size: 12px;
						line-height: 20px;
						/* identical to box height, or 167% */

						display: flex;
						align-items: center;

						color: #666666;
						font-style: normal;
					}
				}
			}
			h3 {
				width: calc(30px + 30px);
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				color: #35363b;
				margin: 0;
				text-align: center;
				span {
					display: block;
				}
			}
		}
	}
}

.session-topic-header {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	justify-content: space-between;
	em {
		font-weight: bold;
		font-size: 18px;
		line-height: 20px;
		/* identical to box height, or 111% */

		display: flex;
		align-items: center;

		color: #239f87;
		font-style: normal;
	}
	p {
		margin: 0;
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		/* identical to box height, or 111% */
		display: flex;
		align-items: center;
		color: #35363b;
		span {
			margin-left: 30px;
		}
	}
}

.add-session-data {
	.add-box {
		& + .add-box {
			margin-top: 50px;
		}
		display: flex;
		padding: 0 10px;
		align-items: center;
		justify-content: space-between;
		height: 36px;
		// background: #f0f0f0;
		border: 1px solid #0b8f75;
		border-radius: 8px;

		span {
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			/* identical to box height */

			text-align: right;

			color: #aaaaaa;
		}

		p {
			font-weight: 600;
			font-size: 12px;
			line-height: 1;
			display: flex;
			align-items: center;
			color: #111111;
			text-transform: uppercase;

			margin: 0;
			svg {
				margin-right: 10px;
				color: #0b8f75;
				font-size: 18px;
			}
		}
	}
}

.custom-table {
	&.eight-col {
		.custom-table--col {
			width: calc((100% - 105px) / 7);
			&:last-child {
				width: 105px;
			}
		}
	}
	&--head {
		.custom-table--row {
			height: auto;
			margin-bottom: 7px;
		}
		.custom-table--col {
			font-size: 14px;
			line-height: 18px;
			color: #6f6f6f;
		}
	}
	&--body {
		.custom-table--row {
			background: #ffffff;
			box-shadow: 0px 2px 5px rgba(148, 148, 148, 0.2);
			border-radius: 8px;
			& + .custom-table--row {
				margin-top: 10px;
			}
		}
	}
	&--row {
		display: flex;
		align-items: center;
		height: 62px;
	}
	&--col {
		padding: 0 20px;
		.btn-danger {
			background: #e60f0f;
			border-radius: 4px;
			min-width: 1px;
			border: none;
			width: 65px;
			height: 34px;
			&:hover {
				background-color: darken($color: #e60f0f, $amount: 5%);
			}
		}
		p {
			margin: 0;
			font-size: 12px;
			line-height: 18px;
			color: #000000;
			&.expired {
				color: #b6b6b6;
				font-size: 14px;
			}
		}
	}
}

.lesson-wrapping {
	& + .lesson-wrapping {
		margin-top: 34px;
	}
	& > p {
		font-weight: 600;
		font-size: 16px;
		line-height: 32px;
		color: #111111;
		margin-bottom: 11px;
	}
}

.student-tabs {
	.primary-tabs {
		.primary-batch-tabs {
			height: 64px;
			background: #ffffff;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
			border-radius: 12px;
			display: inline-flex;
			padding: 0 40px;
			li {
				padding: 0;
				border-radius: none;
				font-size: 16px;
				line-height: 23px;
				color: #6f6f6f;
				position: relative;
				border: none;
				&::before {
					display: none;
				}
				// transition: all ease-in-out .3s;
				&::after {
					content: "";
					width: 100%;
					border-radius: 2px 2px 0px 0px;
					background: #00a689;
					position: absolute;
					bottom: -14px;
					left: 0;
					height: 3px;
					transform: scale(0);
					transform-origin: center center;
					transition: all ease-in-out 0.3s;
				}
				& + li {
					margin-left: 45px;
				}
				&.active {
					font-weight: bold;
					color: #00a689;
					background-color: transparent;
					// transition: all ease-in-out .3s;
					&::after {
						transform: scale(1);
						transition: all ease-in-out 0.3s;
					}
				}
			}
		}
	}
}

.primary-heading {
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	color: #00a689;
}

.resource-detail-box {
	background: #ffffff;
	// min-height: 400px;
	min-height: calc(100vh - (70px + 25px + 32px + 20px + 24px + 16px));
}

.resources-list {
	background: #ffffff;
	border-radius: 12px;
	padding: 24px;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			&:hover {
				cursor: pointer;

				p {
					font-weight: 600;
					color: #00a689;
				}
			}
			& + li {
				margin-top: 12px;
			}
			span {
				width: 53px;
				height: 37px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgba(0, 166, 137, 0.1);
				border-radius: 4px;
			}
			p {
				margin: 0;
				width: calc(100% - 53px);
				padding-left: 12px;
				font-size: 14px;
				line-height: 24px;
				color: #666666;
			}
		}
	}
}

.priamry-test-card .test-card-footer {
	&.report {
		background: linear-gradient(0deg, #c4c4c4, #c4c4c4), #00a689;
		text-decoration: underline;
	}
}

.primary-mentor-card {
	border-radius: 8px;
	overflow: hidden;
	text-align: center;
	cursor: pointer;
	margin-bottom: 24px;
	transition: all ease-in-out 0.3s;
	&:hover {
		box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
		transition: all ease-in-out 0.3s;
		.primary-mentor-card--body {
			border-color: transparent;
			transition: all ease-in-out 0.3s;
		}
	}
	&--body {
		border: 1px solid #e5e5e5;
		background-color: #fff;
		padding: 60px 20px 28px;
		border-top: none;
		transition: all ease-in-out 0.3s;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		h3 {
			font-weight: bold;
			font-size: 18px;
			line-height: 20px;
			color: #111111;
			margin-bottom: 8px;
		}

		span {
			display: block;
			font-size: 12px;
			line-height: 20px;
			color: #111111;
		}

		p {
			font-size: 14px;
			margin: 0;
			line-height: 24px;
			color: #6f6f6f;
		}

		ul {
			display: flex;
			padding: 0;
			align-items: center;
			justify-content: center;
			height: 44px;
			list-style: none;
			margin: 8px 0 17px;
			li {
				& + li {
					margin-left: 35px;
				}
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: 18px;
				line-height: 28px;
				color: #111111;
				svg {
					margin-right: 7px;
				}
			}
		}
	}
	&--header {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: 80px;
		position: relative;
		background-size: cover;
		background-position: center center;
		background: linear-gradient(180deg, #b3d4de 0%, #c4e0e9 100%);
		img {
			width: 90px;
			height: 90px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -45px;
			border-radius: 50%;
			background-color: #f5f7fa;
			border: 5px solid #fff;
			object-fit: cover;
		}
	}
}

.simple-box {
	&--head {
		margin-bottom: 24px;
		h4 {
			font-weight: bold;
			margin: 0;
			font-size: 18px;
			line-height: normal;
			text-align: center;
			color: #111111;
			@media screen and (min-width: 768px) {
				font-size: 34px;
				line-height: 48px;
			}
		}
		h3 {
			font-weight: 600;
			margin: 0;
			font-size: 18px;
			line-height: normal;
			color: #111111;
			@media screen and (min-width: 768px) {
				font-size: 24px;
				line-height: 48px;
			}
			& + p {
				margin: 0;
				font-size: 12px;
				line-height: 18px;
				color: #666666;
			}
		}
	}
}

.mentor-preview-box {
	background: #ffffff;
	padding: 20px;
	ul {
		list-style: none;
		display: flex;
		align-items: flex-end;
		margin: 16px 0 0;
		padding: 0;
		li {
			text-align: center;
			font-weight: bold;
			font-size: 18px;
			line-height: 32px;
			color: #00a689;
			& + li {
				margin-left: 16px;
			}
			span {
				display: flex;
				margin-top: 4px;
				align-items: center;
				justify-content: center;
				font-weight: 600;
				font-size: 14px;
				line-height: 24px;
				background: rgba(0, 166, 137, 0.1);
				border-radius: 8px;
				color: #00a689;
				width: 118px;
				height: 34px;
				svg {
					margin-right: 4px;
					width: 24px;
					height: 24px;
				}
			}
		}
	}
	.video-box {
		width: 250px;
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: radial-gradient(50% 50% at 50% 50%, #6c6c6c 0%, #4e4e4e 100%);
		border-radius: 8px;
		border: 1px solid #eee;
		flex-shrink: 0;
		embed {
			margin: 0 !important;
		}
		svg {
			cursor: pointer;
		}
	}
	.wrap {
		// padding-left: 20px;
		// padding-top: 20px;
		display: flex;
		flex-wrap: wrap;
		img {
			width: 155px;
			height: 155px;
			border: 5px solid #fff;
			border-radius: 50%;
		}

		.content {
			width: calc(100% - 155px);
			padding-left: 12px;

			.user-info {
				margin: 0;
				li {
					font-size: 15px;
					line-height: 21px;
					font-weight: normal;
					width: auto;
					color: #6f6f6f;
					@media screen and (max-width: 600px) {
						font-size: 16px;
					}
					& + li {
						margin-left: 22px;
						position: relative;
						@media screen and (max-width: 600px) {
							margin-left: 0;
							margin-top: 22px;
						}
						&::before {
							content: "";
							position: absolute;
							left: -8px;
							top: 50%;
							transform: translateY(-50%);
							width: 1px;
							height: 10px;
							background-color: #6f6f6f;
							@media screen and (max-width: 600px) {
								height: 1px;
								width: 10px;
								top: -8px;
								transform: translateX(-50%);
								left: 50%;
							}
						}
					}
				}
			}

			h3 {
				font-weight: bold;
				font-size: 20px;
				line-height: 32px;
				margin: 0 0 8px;
				color: #111111;
			}

			strong {
				display: block;
				font-weight: 600;
				font-size: 16px;
				margin-bottom: 8px;
				line-height: 18px;
				color: #6f6f6f;
			}
			p {
				font-size: 16px;
				line-height: 18px;
				color: #6f6f6f;
				margin: 0;
			}
		}
	}
	.info {
		display: flex;
		justify-content: space-between;
		padding: 0;
	}
	.desc {
		padding: 30px 0 0;
		p {
			font-size: 16px;
			line-height: 22px;
			margin: 0;
			color: #6f6f6f;
			& + p {
				margin-top: 20px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.mentor-preview-box {
		ul {
			justify-content: center;
			margin-bottom: 20px;
		}
		.desc {
			padding-top: 20px;
		}
		.info {
			display: block;
			padding: 15px 15px 0;
			.video-box {
				width: 100%;
			}
			.wrap {
				padding: 0;
				display: block;
				.content {
					width: 100%;
					padding-left: 0;
					text-align: center;
					padding-top: 12px;
				}
				img {
					margin: 0 auto;
					width: 120px;
					height: 120px;
					display: block;
				}
			}
		}
	}
	.accordion {
		.card-body {
			.lesson-notes-list {
				margin-bottom: 15px;
			}
		}
	}
	.primary-timeline {
		&--body {
			.timeline-row {
				flex-direction: column;
				align-items: flex-start;
				.wrap {
					width: 100%;
					padding-left: 0;
					padding-top: 15px;
					.timeline-card {
						width: 100%;

						& + .timeline-card {
							margin-left: 0;
							margin-top: 15px;
						}
					}
				}
				h3 {
					width: 100%;
					display: flex;
					align-items: center;
					span {
						& + span {
							margin-left: 5px;
						}
					}
				}
			}
		}
		&--header {
			.days-dropdown {
				& > a {
					min-width: 120px;
					font-size: 16px;
					line-height: 18px;

					svg {
						font-size: 18px;
					}
				}
			}
		}
	}
	.student-tabs {
		.primary-tabs {
			.primary-batch-tabs {
				overflow-x: auto;
				max-width: 100%;
				height: 50px;
				padding: 0 20px;
				overflow-y: hidden;

				li {
					// margin-right: 45px;
					white-space: nowrap;
					padding: 0 20px;
					font-size: 14px;
					&:after {
						width: calc(100% - 40px);
						right: 0;
						margin: 0 auto;
						bottom: -7px;
					}
					& + li {
						margin: 0;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 479px) {
	.primary-heading {
		font-size: 18px;
		line-height: normal;
	}
	.mentor-preview-box {
		ul {
			flex-wrap: wrap;
			justify-content: space-between;
			li {
				width: calc(50% - 5px);
				span {
					width: 100%;
				}
				&.video {
					width: 100%;
					margin-top: 10px;
					span {
						margin-top: 0;
					}
				}
				& + li {
					margin-left: 0;
				}
			}
		}
	}
}

.document-box {
	background: #ffffff;
	border: 1px solid #e9e9e9;
	padding: 20px;
	border-radius: 4px;
	h3 {
		font-weight: 600;
		font-size: 22px;
		line-height: 28px;
		color: #35363b;
	}
	ul {
		margin: 6px 0 0;
		display: flex;
		align-items: center;
		list-style: none;
		padding: 0;
		&.docs {
			margin-top: 16px;
			li {
				width: 101px;
				height: 71px;
			}
		}
		li {
			background: #ffffff;
			border: 1px solid #e6e6e6;
			box-sizing: border-box;
			border-radius: 3px;
			width: 57px;
			height: 57px;
			display: flex;
			align-items: center;
			justify-content: center;
			& + li {
				margin-left: 24px;
			}
			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}
	}
	span {
		font-weight: 600;
		font-size: 15px;
		line-height: 19px;
		display: block;
		color: #a1a1a1;
	}
	strong {
		font-weight: bold;
		font-size: 15px;
		line-height: 21px;
		display: block;
		margin-top: 6px;
		color: #535974;
		i {
			font-style: normal;
			font-weight: normal;
		}
	}
}

.batch-accordion.test-accordian.assignment-accordian {
	.accordion {
		.card-header {
			p {
				div {
					@media screen and (min-width: 768px) {
						width: calc(500px - 17px);
						p {
							width: 50%;
							padding: 0 15px;
						}
					}
				}
			}
		}
	}
}

.timeline-card {
	.action-button {
		margin-left: auto;
	}
	.edit-icon {
		color: #239f87;
		flex-shrink: 0;
		font-size: 16px;
		margin-left: auto;
		cursor: pointer;
		margin-bottom: 5px;
	}
}

.primary-timeline {
	.timeline-tabs {
		width: 176px;
		display: flex;
		list-style: none;
		background: #f0f0f0;
		margin: 0;
		padding: 4px;
		border-radius: 8px;
		li {
			width: 50%;
			position: relative;
			input {
				position: absolute;
				width: 100%;
				height: 100%;
				cursor: pointer;
				opacity: 0;
				&:checked {
					& + span {
						background: #111111;
						color: #ffffff;
					}
				}
			}
			span {
				font-weight: bold;
				font-size: 14px;
				line-height: 20px;
				border-radius: 6px;
				/* identical to box height, or 143% */

				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;

				color: #666666;
			}
		}
	}
}

.primary-timeline {
	&--body {
		.calender-view {
			margin-top: -15px;
			margin-bottom: -15px;
			display: flex;
			.timeline-row {
				.wrap {
					width: 100% !important;
				}
			}
			.timeline-card {
				width: 100% !important;
				& + .timeline-card {
					margin-left: 0 !important;
				}
			}
			.col {
				width: 50%;
				padding: 15px 0;
				.no-date {
					font-weight: 600;
					font-size: 12px;
					line-height: 20px;
					/* identical to box height, or 167% */

					display: flex;
					align-items: center;
					justify-content: center;
					height: 200px;
					color: #111111;
				}
				.col-footer {
					height: 40px;
					padding: 10px 24px;
					display: flex;
					align-items: center;
					background: #f0f0f0;
					border-radius: 6px;
					p {
						font-size: 15px;
						line-height: 20px;
						margin: 0;
						padding-left: 16px;
						position: relative;
						color: #666666;
						&.current {
							&::before {
								background: #30a58f;
							}
						}
						&.class {
							&::before {
								background: #111111;
							}
						}
						&::before {
							content: "";
							width: 8px;
							height: 8px;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							border-radius: 50%;
							position: absolute;
						}
						& + p {
							margin-left: 20px;
						}
					}
				}
				.col-header {
					display: flex;
					padding-bottom: 15px;
					justify-content: space-between;
					align-items: center;
					.primary-outine-button {
						border-color: #0b8f75;
						background-color: transparent;
						font-weight: 600;
						font-size: 12px;
						border-radius: 4px;
						height: 36px;
						line-height: 15px;
						padding: 0 10px !important;
						color: #35363b;

						svg {
							color: #0b8f75;
							transition: all ease-in-out 0.3s;
							font-size: 18px;
						}
						&:hover {
							background-color: #0b8f75;
							color: #fff;
							svg {
								color: #fff;
								transition: all ease-in-out 0.3s;
							}
						}
					}
					h4 {
						font-weight: 600;
						font-size: 18px;
						line-height: 20px;
						margin: 0;
						color: #111111;
					}
				}
				&:first-child {
					padding-right: 24px;
				}
				&:last-child {
					padding-left: 24px;
				}
				& + .col {
					border-left: 1px solid #e2e8f0;
				}
			}
		}
	}
	&--header {
		h3 {
			font-size: 24px;
			line-height: 30px;
			font-weight: 400;
			margin: 0;
			color: #111111;
			strong {
				font-weight: bold;
			}
		}
		.days-dropdown {
			position: relative;
			ul {
				position: absolute;
				margin: 0;
				padding: 5px;
				display: none;
				list-style: none;
				background: #fff;
				width: 100%;
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
				border-radius: 8px;
				li {
					cursor: pointer;
					font-size: 14px;
					padding: 6px 10px;
					& + li {
						border-top: 1px solid #eee;
					}
				}
			}
		}
	}
}

// TODO
.course-overview {
	.course {
		background: #ffffff;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: 12px;
		margin-bottom: 21px;
		&--body {
			display: flex;
			flex-wrap: wrap;
			padding: 15px;
			flex-direction: column;
			@media screen and (min-width: 768px) {
				padding: 30px;
				flex-direction: row;
			}
			.course-card {
				width: 100%;
				border-radius: 12px;
				overflow: hidden;

				@media screen and (min-width: 768px) {
					width: 300px;
				}

				a {
					display: flex;
					align-items: center;
					font-weight: 600;
					font-size: 16px;
					height: 50px;
					justify-content: center;
					border: 1px solid #c4c4c4;
					line-height: 24px;
					border-bottom-left-radius: 12px;
					border-bottom-right-radius: 12px;
					color: #00a689;
					span {
						width: 20px;
						height: 20px;
						margin-right: 14px;
						display: flex;
						align-items: center;
						position: relative;
						border-radius: 50%;
						justify-content: center;
						background: #00a689;
						svg {
							color: #ffd100;
							position: relative;
						}
						&::after,
						&::before {
							content: "";
							position: absolute;
						}
						&::after {
							border-radius: 50%;
							width: 34px;
							height: 34px;
							background: #00a689;
							opacity: 0.16;
						}
						&::before {
							width: 29px;
							height: 29px;
							border-radius: 50%;
							background: #00a689;
							opacity: 0.4;
						}
					}
				}
				img {
					max-width: 100%;
					width: 100%;
				}
			}
			.course-info {
				margin: 0;
				width: 100%;
				padding-top: 15px;
				text-align: center;

				.description {
					ul {
						margin: 0;
						padding: 0;
						display: block;
						li {
							font-family: "Source Sans Pro", sans-serif !important;
							color: #666 !important;
							margin-bottom: 0 !important;
							line-height: 150% !important;
							margin-top: 0 !important;
							margin-right: 0 !important;
							font-size: 14px !important;

							& + li {
								padding-left: 0;
								margin-left: 0;
								margin-top: 10px !important;
							}

							span {
								font-family: "Source Sans Pro", sans-serif !important;
								color: #666 !important;
								margin-bottom: 0 !important;
								line-height: 150% !important;
								margin-top: 0 !important;
								margin-right: 0 !important;
								font-size: 14px !important;
							}
						}
					}
				}

				@media screen and (min-width: 768px) {
					width: calc(100% - 300px);
					padding-top: 0;
					padding-left: 25px;
					text-align: left;
				}
				h3 {
					font-weight: bold;

					line-height: 32px;
					margin: 0 0 4px;
					color: #111111;
					font-size: 20px;

					@media screen and (min-width: 768px) {
						font-size: 24px;
					}
				}
				strong {
					display: block;
					font-weight: 600;
					line-height: 24px;
					color: #666666;
					margin-bottom: 8px;
					font-size: 14px;

					@media screen and (min-width: 768px) {
						font-size: 16px;
					}
				}

				p {
					line-height: 24px;
					color: #666666;

					font-size: 14px;
					margin-bottom: 20px;

					@media screen and (min-width: 768px) {
						font-size: 16px;
						margin: 0 0 10px;
					}
				}
				.wrap {
					display: flex;
					align-items: center;
					justify-content: center;

					@media screen and (min-width: 768px) {
						justify-content: flex-end;
					}

					span {
						font-weight: 600;
						font-size: 24px;
						line-height: 30px;
						/* identical to box height, or 125% */

						margin-right: 20px;
						color: #111111;
					}
				}
			}
		}
		&--footer {
			border-top: 1px solid #e2e8f0;
			ul {
				list-style: none;
				margin: 0;
				padding: 0 30px;
				height: 55px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media screen and (max-width: 479px) {
					padding: 10px 15px 0;
					height: auto;
					flex-wrap: wrap;
					align-items: center;
				}

				li {
					display: flex;
					align-items: center;
					font-size: 16px;
					line-height: 24px;
					color: #111111;

					@media screen and (max-width: 479px) {
						font-size: 14px;
						padding: 0 0 10px;
						&:first-child {
							order: 1;
						}

						&:nth-child(2) {
							order: 2;
							font-weight: 600;
							color: #00a689;
						}
					}

					svg {
						margin-right: 10px;
						color: #1ea087;
					}
				}
			}
		}
	}
	.subjects {
		background: #ffffff;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: 12px;
		overflow: hidden;
		&--body {
			padding: 0 15px;
			@media screen and (min-width: 768px) {
				padding: 30px;
			}
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				li {
					display: block;
					padding: 15px 0;

					@media screen and (min-width: 768px) {
						display: flex;
						align-items: center;
						padding: 0;
					}

					&:hover {
						cursor: pointer;
						p {
							text-decoration: underline;
						}
					}

					small {
						font-size: 14px;
						line-height: 14px;
						color: #666666;
						margin-top: 10px;

						@media screen and (min-width: 768px) {
							margin-left: 10px;
							margin-top: 0;
						}
					}
					p {
						margin: 0;
						font-size: 14px;
						line-height: 18px;
						color: #111111;

						@media screen and (min-width: 768px) {
							font-size: 18px;
						}
					}
					& + li {
						border-top: 1px solid #eee;
						margin: 0;

						@media screen and (min-width: 768px) {
							margin-top: 24px;
							border-top: none;
						}
					}
				}
			}
		}
		&--footer {
			height: 61px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px solid #e0e0e0;
			p {
				font-weight: bold;
				line-height: 18px;
				font-size: 16px;
				cursor: pointer;
				margin: 0;
				color: #00a689;

				@media screen and (min-width: 768px) {
					font-size: 18px;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
		&--header {
			height: 62px;
			display: flex;
			align-items: center;
			background: #f0f0f0;
			padding: 0 15px;

			@media screen and (min-width: 768px) {
				padding: 0 30px;
			}

			h3 {
				font-weight: bold;
				font-size: 18px;
				line-height: 32px;
				color: #111111;
				margin: 0;
			}
		}
	}
}
