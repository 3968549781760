@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.form-image-group {
  position: relative;
  .custom-control {
    margin: 0;
    padding: 0;
  }
  .custom-control-inline .custom-control-input {
    position: absolute;
    z-index: -1; // Put the input behind the label so it doesn't overlay text
    opacity: 0;
  }
  label {
    cursor: pointer;
    margin: 0;
    padding: 4px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    &::before,
    &::after {
      display: none;
    }
  }
  :checked ~ label {
    padding: 2px;
    border: 3px solid $primary;
  }
  .is-valid & {
    :checked ~ label {
      border-color: $success;
    }
  }
  .is-invalid & {
    label {
      border-color: $danger;
    }
  }
}
