.mdk-drawer[data-persistent].layout-mini__drawer,
.layout-mini__drawer .mdk-drawer__content {
  width: $layout-mini-drawer-width;
}

.layout-mini .sidebar-secondary {
  transition: transform 0.2s ease;
  transform: translate3d(-100%, 0, 0);
  box-shadow: none;
}

.layout-mini-secondary .sidebar-secondary,
.layout-mini--open .sidebar-secondary {
  @include mdc-elevation(3, $shadow-baseline-color, $shadow-opacity-boost);
}

.layout-mini__drawer[data-position="right"] .sidebar-secondary {
  transform: translate3d(100%, 0, 0);
}

.layout-mini,
.layout-mini-secondary {
  .page__container {
    @include media-breakpoint-up(xl) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  // secondary drawer visible

  .mdk-drawer[data-persistent].layout-mini-secondary__drawer,
  .layout-mini-secondary__drawer .mdk-drawer__content {
    width: $layout-mini-drawer-width + $mdk-drawer-width;
  }

  .sidebar-mini {
    width: $layout-mini-drawer-width;
    position: relative;
    z-index: 1;

    &.sidebar-light .active.show .sidebar-menu-icon {
      color: $dark;
    }

    &.sidebar-dark .active.show .sidebar-menu-icon {
      color: $sm-dark-active-icon-color;
    }
    
    .sidebar-menu-text,
    .sidebar-badge,
    .sidebar-menu-toggle-icon {
      display: none;
    }

    .sidebar-menu-item {
      margin: 0;
      border-radius: 0;
    }

    .sidebar-menu-button {
      flex-direction: column;
      padding: .5rem 0;
    }

    .sidebar-menu-initials {
      content: attr(data-initials);
      display: block;
      visibility: visible;
    }

    .sidebar-menu-button {
      justify-content: center;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
    .sidebar-menu-icon {
      margin: 0;
    }
  }

  .sidebar-secondary {
    width: $mdk-drawer-width;
    position: absolute;
    top: 0;
    left: $layout-mini-drawer-width;
  }

  &__drawer[data-position="right"] .sidebar-secondary {
    left: auto;
    right: $layout-mini-drawer-width;
  }

  &__drawer .mdk-drawer__content {
    display: flex;
    z-index: 0;
  }

  @include media-breakpoint-up(sm) {
    & &__d-none {
      display: none;
    }
  }

  &--open {
    .sidebar-secondary,
    .layout-mini__drawer[data-position="right"] .sidebar-secondary {
      transform: translate3d(0, 0, 0);
    }
  }
}

@include media-breakpoint-up(xl) {
  .layout-mini-secondary .container {
    max-width: 936px;
  }
}