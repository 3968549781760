.primary-thumbnail {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  &--action {
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 20px;
  }
  &--icon {
    width: 114px;
    height: 114px;
    background-color: #eee;
    border-radius: 8px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
  &--content {
    width: calc(100% - 114px);
    padding-left: 14px;
    padding-right: 40px;
    padding-top: 16px;
    h3 {
      font-family: $secondaryFont;
      font-weight: $bold;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 16px;
      color: #2a2a2a;
    }
    p {
      font-family: $secondaryFont;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
      color: #6f6f6f;
    }
  }
}
