.test-box {
  &--header {
    margin-bottom: 30px;
    h3 {
      font-family: $secondaryFont;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      color: #2a2a2a;
      margin-bottom: 15px;
    }
  }
  &--body {
    background: $white;
    padding: 25px;
    border-radius: 10px;
    .button-group {
      justify-content: flex-end;
      margin-top: 30px;
      .btn {
        min-width: 128px;
        height: 44px;
        font-size: 18px;
        & + .btn {
          margin-left: 25px;
        }
      }
    }
    h4 {
      font-family: $secondaryFont;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 18px;
      color: #6f6f6f;
    }
    p {
      font-family: $secondaryFont;
      font-weight: $semiBold;
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 26px;
      color: #2a2a2a;
    }
  }
}


.my-progress {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  &--name {
    display: flex;
    width: calc(173px + 34px);
    align-items: center;
    span {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #FFFFFF;
      border: 1px solid #F9F9F9;
      box-sizing: border-box;
      box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.1);
      font-size: 20px;
      color: #00A689;
      margin-right: 10px;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
      line-height: 16px;
      color: #2A2A2A;
    }
  }

  &--bar {
    flex-grow: 1;
    padding: 12px 22px 0 0;
    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #111111;

    }
    p {
      font-size: 12px;
      line-height: 18px;
      margin: 1px 0 0;
      color: #666666;
    }
  }

  &--action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 12px;
    width: 100px;
    a {
      font-size: 13px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #666666;
      & + a {
        margin-top: 5px;
      }
    }
  }
}

.primary-progress-bar {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
  .wrap {
    width: 100%;
    height: 8px;
    background: #FFFFFF;
    border: 1px solid #239F87;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    .bar-line {
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 10px;
      background: #00A689;
    }
  }
}