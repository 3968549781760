.page-separator-mask {
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 356px;
  .page-separator-mask__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 84px;
  }
  .page-separator {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin: 0;
  }
  .page-separator-mask__top {
    background-image: linear-gradient(-180deg, rgba($page-separator-mask-color,0.70) 0%, rgba($page-separator-mask-color,0.80) 17%, rgba($page-separator-mask-color,0.90) 32%, $page-separator-mask-color 70%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 64px;
  }
  .page-separator-mask__bottom {
    background: $page-separator-mask-color;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
  }
}