.primary-upload-ui {
  width: 46px;
  height: 38px;
  border-radius: 10px;
  border: solid 1px #efefef;
  margin-bottom: 15px;
  background-color: #ffffff;
  label {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #000;
    }
  }
  input {
    display: none;
  }
}

.transparent-textarea {
  width: 100%;
  height: auto;
  display: block;
  border: none;
  resize: none;
  font-size: 18px;
  color: #434544;
}

.add-option-form {
  display: flex;
  margin: 15px 0;
  align-items: center;
  & > div {
    width: 36px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    height: 36px;
    margin-left: 5px;
    cursor: pointer;
    flex-shrink: 0;
    svg {
      font-size: 20px;
    }
  }
  .clear {
    background-color: #f44d4d;
  }
  .add {
    background-color: #239f87;
  }
}

.modal-image-box {
  position: relative;
}
.modal-attachment {
  position: relative;
  .cross {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 5px 10px;
  }
  img {
    max-width: 100%;
    border-radius: 10px;
  }
}
.option-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 20px;

  .quiz_list {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      color: #262424;
      line-height: 1;
      margin-right: 7px;
    }
    .list-icon {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #1a1a1a;
      height: 24px;
      margin-right: 12px;
      border: solid 1px #767676;
      border-radius: 50%;
      background-color: #ffffff;
      &.selected-icon {
        background-color: #239f87;
        border-color: #239f87;
        color: #ffffff;
      }
    }
  }
}

.add-more-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    color: #1a1a1a;
    svg {
      margin-left: 5px;
    }
  }
}

.quiz-list-options {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  & + .quiz-list-options {
    border-top: solid 1px rgba(112, 112, 112, 0.1);
  }
  li {
    &:first-child {
      width: 24px;
      border-radius: 50%;
      height: 24px;
      border: solid 1px #767676;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      font-size: 12px;
      color: #1a1a1a;
    }
    svg {
      cursor: pointer;
    }
    &:nth-child(2) {
      flex-grow: 1;
      padding: 0 10px;
      font-size: 14px;
      line-height: normal;
      color: #262424;
    }
  }
}

.educrack-wall {
  .create-button {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &--score {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 20px;
    color: #000000;
  }

  &--filter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    label {
      display: none;
    }
    .primary-form-control,
    .form-group {
      width: calc((100% - 30px) / 3);
    }
  }
}

// .post-card {
//   background: #FFFFFF;
//   border: 1px solid #E5E5E5;
//   border-radius: 16px;
//   margin-bottom: 20px;
//   &--header {
//     display: flex;
//     align-items: center;
//     padding: 16px 24px;
//     justify-content: space-between;
//     @media screen and (max-width: 767px) {
//       padding: 15px;
//     }
//     .wall-dropdown {
//       position: relative;
//       user-select: none;
//       svg {
//         font-size: 24px;
//         color: #6f6f6f;
//         cursor: pointer;
//       }
//       .menu {
//         // display: none;
//         position: absolute;
//         border-radius: 10px;
//         border: solid 1px #efefef;
//         background-color: #ffffff;
//         right: 5px;
//         overflow: hidden;
//         top: 30px;
//         &:not(.active) {
//           display: none;
//         }
//         span {
//           font-size: 12px;
//           display: block;
//           white-space: nowrap;
//           padding: 10px 15px;
//           font-weight: normal;
//           line-height: normal;
//           color: #000000;
//           transition: all ease-in-out 0.3s;
//           &:hover {
//             cursor: pointer;
//             background-color: #239f87;
//             transition: all ease-in-out 0.3s;
//             color: #ffffff;
//           }
//           & + span {
//             border-top: solid 1px #efefef;
//           }
//         }
//       }
//     }
//     .wrap {
//       display: flex;
//       align-items: center;
//       img {
//         width: 48px;
//         height: 48px;
//         border-radius: 50%;
//         @media screen and (max-width: 767px) {
//           width: 30px;
//           height: 30px;
//         }
//       }
//       .user-info {
//         margin-left: 12px;
//         @media screen and (max-width: 767px) {
//           margin-left: 10px;
//         }
//         p {
//           margin: 0;
//           font-size: 18px;
//           line-height: 28px;
//           color: #000000;
//           @media screen and (max-width: 767px) {
//             line-height: normal;
//             font-size: 16px;
//           }
//         }
//         span {
//           display: block;
//           font-size: 14px;
//           line-height: 23px;
//           color: #6f6f6f;
//           & + span {
//             margin-top: 5px;
//           }
//         }
//       }
//     }
//   }

//   &--footer {
//     padding: 18px 24px;
//     border-top: solid 1px #f4f4f4;

//     @media screen and (max-width: 767px) {
//       padding: 15px;
//     }

//     .added-comment {
//       position: relative;
//       & + .add-comment {
//         margin-top: 20px;
//       }
//       .wrap {
//         display: flex;
//         align-items: center;
//         padding: 10px 0;
//         &:first-child {
//           padding-top: 0;
//         }
//         &:last-child {
//           padding-bottom: 0;
//         }
//         & + .wrap {
//           border-top: 1px solid #eee;
//         }
//         img,
//         .comment-remove {
//           flex-shrink: 0;
//         }

//         .comment-remove {
//           svg {
//             cursor: pointer;
//           }
//         }

//         .comment {
//           flex-grow: 1;
//           padding: 0 10px;
//           span {
//             display: block;
//             font-size: 14px;
//             line-height: normal;
//             color: #000000;
//           }

//           p {
//             display: block;
//             font-size: 12px;
//             margin: 0;
//             line-height: normal;
//             color: #a7a7a7;
//           }
//         }
//       }
//     }
//     .add-comment {
//       display: flex;
//       align-items: center;
//       position: relative;
//       .comment-action {
//         position: absolute;
//         right: 20px;
//         display: flex;
//         align-items: center;
//         top: 50%;
//         transform: translateY(-50%);
//       }
//       span {
//         & + span {
//           margin-left: 10px;
//           @media screen and (max-width: 767px) {
//             margin-left: 5px;
//           }
//           svg {
//             color: #239f87;
//           }
//         }
//         svg {
//           font-size: 24px;
//           cursor: pointer;
//         }
//       }
//       & > img {
//         width: 48px;
//         height: 48px;
//         margin-right: 12px;
//         border-radius: 50%;
//         @media screen and (max-width: 767px) {
//           width: 35px !important;
//           height: 35px !important;
//           position: absolute;
//           left: 5px;
//         }
//       }
//       input {
//         flex-grow: 1;
//         border: none;
//         font-size: 14px;
//         display: block;
//         line-height: normal;
//         color: #000;
//         background: #f5f9ff;
//         border-radius: 24px;
//         height: 48px;
//         padding: 0 100px 0 16px;
//         @media screen and (max-width: 767px) {
//           max-width: 100% !important;
//           padding: 0 80px 0 45px !important;
//         }
//         &::placeholder {
//           color: #aaaaaa;
//         }
//       }
//     }
//   }

//   &--content {
//     p {
//       font-size: 18px;
//       line-height: 28px;
//       color: #6f6f6f;
//       margin: 0;
//       padding: 0 20px 16px;
//       @media screen and (max-width: 767px) {
//         padding: 15px;
//         font-size: 16px;
//         line-height: normal;
//       }
//     }
//   }

//   &--action {
//     margin-top: 22px;
//     padding: 10px 24px;
//     border-top: 1px solid #f0f0f0;
//     border-bottom: 1px solid #f0f0f0;
//     @media screen and (max-width: 767px) {
//       margin-top: 0;
//     }
//     .wall-quiz {
//       margin: 0 -20px 10px;
//       padding: 0 20px 30px;
//       border-bottom: solid 1px #f4f4f4;
//       button {
//         width: 108px;
//         height: 40px;
//         font-size: 16px;
//         margin: 0 auto;
//         display: block;
//         color: #000000;
//         cursor: pointer;
//         border-radius: 20px;
//         border: solid 1px #239f87;
//         &:hover {
//           background-color: #239f87;
//           color: #ffffff;
//         }
//       }
//     }
//     .wrap {
//       display: flex;
//       align-items: center;
//       justify-content: flex-start;
//       .action {
//         display: flex;
//         cursor: pointer;
//         align-items: center;
//         &.active {
//           svg,
//           strong {
//             color: #239f87;
//           }
//         }
//         & + .action {
//           margin-left: 47px;
//           position: relative;
//           &::before {
//             content: "•";
//             font-size: 18px;
//             line-height: 28px;
//             position: absolute;
//             left: -25px;
//             top: 50%;
//             transform: translateY(-50%);
//             color: #6f6f6f;
//           }
//         }
//         svg {
//           font-size: 16px;
//           color: #6f6f6f;
//           margin-right: 6px;
//         }
//         span {
//           font-size: 18px;
//           line-height: 28px;
//           color: #6f6f6f;
//         }
//         strong {
//           font-weight: bold;
//           font-size: 18px;
//           line-height: 28px;
//           margin: 0 9px 0 8px;
//           color: #6f6f6f;
//           @media screen and (max-width: 767px) {
//             display: none;
//           }
//         }
//       }
//     }
//   }

//   &--body {
//     // background-color: #000000;
//     .rounded-video-player {
//       width: 100% !important;
//     }
//     video {
//       display: block;
//       max-height: 500px;
//       width: auto !important;
//       max-width: 100%;
//       margin: 0 auto;
//       object-fit: contain;
//     }
//     img {
//       display: block;
//       max-height: 500px;
//       width: auto !important;
//       max-width: 100%;
//       margin: 0 auto;
//       object-fit: contain;
//     }
//   }
// }


.wall-grid {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  .content {
    flex-grow: 1;

    @media screen and (max-width: 767px) {
      order: 2;
      width: 100%;
    }
  }

  .filter {
    width: 280px;
    padding-left: 40px;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
      order: 1;
      padding-left: 0;
      padding-bottom: 20px;
      width: 100%;
    }
    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #1EA087;
    }
  }
}

// .post-card {
//   background: #FFFFFF;
//   border: 1px solid #E5E5E5;
//   border-radius: 16px;
//   margin-bottom: 20px;
//   &--header {
//     display: flex;
//     align-items: center;
//     padding: 16px 20px;
//     justify-content: space-between;
//     @media screen and (max-width: 767px) {
//       padding: 15px;
//     }
//     .wall-dropdown {
//       position: relative;
//       user-select: none;
//       svg {
//         font-size: 24px;
//         color: #6f6f6f;
//         cursor: pointer;
//       }
//       .menu {
//         // display: none;
//         position: absolute;
//         border-radius: 10px;
//         border: solid 1px #efefef;
//         background-color: #ffffff;
//         right: 5px;
//         overflow: hidden;
//         top: 30px;
//         &:not(.active) {
//           display: none;
//         }
//         span {
//           font-size: 12px;
//           display: block;
//           white-space: nowrap;
//           padding: 10px 15px;
//           font-weight: normal;
//           line-height: normal;
//           color: #000000;
//           transition: all ease-in-out 0.3s;
//           &:hover {
//             cursor: pointer;
//             background-color: #239f87;
//             transition: all ease-in-out 0.3s;
//             color: #ffffff;
//           }
//           & + span {
//             border-top: solid 1px #efefef;
//           }
//         }
//       }
//     }
//     .wrap {
//       display: flex;
//       align-items: center;
//       img {
//         width: 48px;
//         height: 48px;
//         border-radius: 50%;
//         @media screen and (max-width: 767px) {
//           width: 30px;
//           height: 30px;
//         }
//       }
//       .user-info {
//         margin-left: 12px;
//         @media screen and (max-width: 767px) {
//           margin-left: 10px;
//         }
//         p {
//           margin: 0;
//           font-size: 18px;
//           line-height: 28px;
//           color: #111111;
//           @media screen and (max-width: 767px) {
//             line-height: normal;
//             font-size: 16px;
//           }
//         }
//         span {
//           display: block;
//           font-size: 14px;
//           line-height: 23px;
//           color: #6f6f6f;
//           & + span {
//             margin-top: 5px;
//           }
//         }
//       }
//     }
//   }

//   &--footer {
//     padding: 18px 24px;
//     border-top: solid 1px #f4f4f4;

//     @media screen and (max-width: 767px) {
//       padding: 15px;
//     }

//     .added-comment {
//       position: relative;
//       & + .add-comment {
//         margin-top: 20px;
//       }
//       .wrap {
//         display: flex;
//         align-items: center;
//         padding: 10px 0;
//         &:first-child {
//           padding-top: 0;
//         }
//         &:last-child {
//           padding-bottom: 0;
//         }
//         & + .wrap {
//           border-top: 1px solid #eee;
//         }
//         img,
//         .comment-remove {
//           flex-shrink: 0;
//         }

//         .comment-remove {
//           svg {
//             cursor: pointer;
//           }
//         }

//         .comment {
//           flex-grow: 1;
//           padding: 0 10px;
//           span {
//             display: block;
//             font-size: 14px;
//             line-height: normal;
//             color: #000000;
//           }

//           p {
//             display: block;
//             font-size: 12px;
//             margin: 0;
//             line-height: normal;
//             color: #a7a7a7;
//           }
//         }
//       }
//     }
//     .add-comment {
//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;
//       position: relative;
//       .comment-action {
//         position: absolute;
//         right: 20px;
//         display: flex;
//         align-items: center;
//         top: 50%;
//         transform: translateY(-50%);
//       }
//       span {
//         & + span {
//           margin-left: 10px;
//           svg {
//             color: #239f87;
//           }
//         }
//         svg {
//           font-size: 24px;
//           cursor: pointer;
//         }
//       }
//       & > img {
//         width: 48px;
//         height: 48px;
//         margin-right: 12px;
//         border-radius: 50%;
//       }
//       input {
//         flex-grow: 1;
//         border: none;
//         font-size: 14px;
//         display: block;
//         line-height: normal;
//         color: #000;
//         border-radius: 24px;
//         height: 48px;
//         padding: 0 100px 0 16px;

//         background: #F9F9F9;
//         @media screen and (max-width: 767px) {
//           max-width: 100%;
//         }
//         &::placeholder {
//           color: #aaaaaa;
//         }
//       }
//     }
//   }

//   &--content {
//     p {
//       font-size: 18px;
//       line-height: 28px;
//       color: #6f6f6f;
//       margin: 0;
//       padding: 0 20px 16px;
//       @media screen and (max-width: 767px) {
//         padding: 15px;
//         font-size: 16px;
//         line-height: normal;
//       }
//     }
//   }

//   &--action {
//     margin-top: 0;
//     padding: 10px 24px;
//     border-top: 1px solid #f0f0f0;
//     border-bottom: 1px solid #f0f0f0;
//     @media screen and (max-width: 767px) {
//       margin-top: 0;
//     }
//     .wall-quiz {
//       margin: 0 -20px 10px;
//       padding: 0 20px 30px;
//       border-bottom: solid 1px #f4f4f4;
//       button {
//         width: 108px;
//         height: 40px;
//         font-size: 16px;
//         margin: 0 auto;
//         display: block;
//         color: #000000;
//         cursor: pointer;
//         border-radius: 20px;
//         border: solid 1px #239f87;
//         &:hover {
//           background-color: #239f87;
//           color: #ffffff;
//         }
//       }
//     }
//     .wrap {
//       display: flex;
//       align-items: center;
//       justify-content: flex-start;
//       .action {
//         display: flex;
//         cursor: pointer;
//         align-items: center;
//         &.active {
//           svg,
//           strong {
//             color: #239f87;
//           }
//         }
//         & + .action {
//           margin-left: 47px;
//           position: relative;
//           &::before {
//             content: "•";
//             font-size: 18px;
//             line-height: 28px;
//             position: absolute;
//             left: -25px;
//             top: 50%;
//             transform: translateY(-50%);
//             color: #6f6f6f;
//           }
//         }
//         svg {
//           font-size: 16px;
//           color: #6f6f6f;
//           margin-right: 6px;
//         }
//         span {
//           font-size: 18px;
//           line-height: 28px;
//           color: #6f6f6f;
//         }
//         strong {
//           font-weight: bold;
//           font-size: 18px;
//           line-height: 28px;
//           margin: 0 9px 0 8px;
//           color: #6f6f6f;
//           @media screen and (max-width: 767px) {
//             display: none;
//           }
//         }
//       }
//     }
//   }

//   &--body {
//     // background-color: #000000;
//     padding: 0 20px 20px;
//     video {
//       display: block;
//       max-height: 500px;
//       width: auto !important;
//       max-width: 100%;
//       border-radius: 16px;
//       margin: 0 auto;
//       object-fit: contain;
//     }
//     audio {
//       display: block;
//       width: 100%;
//       border-radius: 50px;
//     }
//     img {
//       display: block;
//       max-height: 500px;
//       width: auto !important;
//       border-radius: 16px;
//       max-width: 100%;
//       margin: 0 auto;
//       object-fit: contain;
//     }
//   }
// }

.simple-box.mt-5 {
  margin-top: 0 !important;
}