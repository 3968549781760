.material-icons {
  vertical-align: middle;
}

// Sizing
.icon {
  &-16pt {
    font-size: 1rem !important;
  }
  &-24pt {
    font-size: 1.5rem !important;
  }
  &-32pt {
    font-size: 2rem !important;
  }
  &-40pt {
    font-size: 2.5rem !important;
  }
  &-48pt {
    font-size: 3rem !important;
  }
  &-64pt {
    font-size: 4rem !important;
  }
}