// Utils
@import "./utils/buttons";
@import "./utils/fields";
@import "./utils/reusable";
@import "./utils/icons";

// Components
@import "./component/user-structure";
@import "./component/batch";
@import "./component/test-box";
@import "./component/live-box";
@import "./component/doubt";
@import "./component/setting";

// Shared
@import "./shared/header";
@import "./shared/leftside";
@import "./shared/common";
@import "./shared/primary-card";
@import "./shared/secondary-card";
@import "./shared/progress";
@import "./shared/selectable-list";
@import "./shared/list-with-tag";
@import "./shared/courses";
@import "./shared/action-box";
@import "./shared/success-box";
@import "./shared/primary-thumbnail";
@import "./shared/modal";

@import url("https://cdn.syncfusion.com/ej2/material.css");
.forgetform {
  height: 100vh;
}
.forget-sec .wrap .button-group {
  margin-top: 0px;
}
.forget-sec .wrap .button-group .btn {
  width: 100%;
}
.leftnavcard {
  position: relative;
  display: flex;
}

.leftnavmid {
  padding-bottom: 0px;
  padding-left: 1rem;
}
.addv {
  width: 20%;
  position: absolute;
  top: 0;
  right: 20px;
}
.para {
  font-size: 1rem;
  font-weight: bold;
}
.trans {
  width: 50rem;
}
.batchp {
  font-size: 1rem;
  font-weight: bold;
}
.tab {
  width: 70%;
}
.textchange {
  color: #239f86;
  font-size: 1rem;
}
.progressimg {
  height: 3rem;
  border: 2px solid #239f86;
  border-radius: 1rem;
  margin-right: 2rem;
}
.batch-accordion .accordion .card-header {
  padding: 1rem 1rem;
}
.progress-according .accordion .card-body .lesson-wrap .wrapper {
  width: calc(100% - 4px);
}
.prodetail {
  margin-top: -7px;
}
.prodetail a {
  margin-left: 3rem;
}
.progress {
  border: 1px solid #239f86;
}
.progress-bar {
  background-color: #239f86;
}
.livestreamtab {
  width: 100%;
}
.checkprice h4 {
  font-size: 15px;
  padding-left: 30px;
}
.checkprice p {
  text-align: left;
  font-size: 14px;
  padding-left: 20px;
}
.checkdown .subscribed img {
  width: 100%;
}
.calendar-sec .e-day.e-title {
  font-size: 20px;
}
.calendar-sec .e-date-icon-prev.e-icons {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-90deg);
  background-color: #f3f3f3;
  border-radius: 5px;
}
.calendar-sec .e-date-icon-next.e-icons {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-90deg);
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-left: 4px;
}
.calendar-sec .e-today .e-day {
  background-color: #00a689 !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.calender-event-sec {
  background-color: #fff;
  padding: 14px 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px #ccc;
}
.calendar-sec .e-control.e-calendar {
  border: none;
  max-width: 100%;
}
.calendar-sec .e-header.e-month {
  border-bottom: 2px solid #ccc;
  padding-bottom: 7px;
}
.calender-btn .pdf-down {
  background-color: #f3f3f3;
  padding: 11px 11px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 48px;
}

.calender-btn .calendar-timeline-btn {
  display: inline-block;
  list-style: none;
  padding-left: 0px;
  background-color: #f3f3f3;
  margin-right: 12px;
  border-radius: 6px;
  margin-bottom: 6px;
}
.calendar-timeline-btn .active-bg {
  background-color: #000;
  color: #fff;

  border-radius: 5px;
}
.calendar-timeline-btn li {
  display: inline-block;
  padding: 10px 16px;
}
.calender-btn {
  text-align: right;
  margin-right: 20px;
  width: 100%;
  border-bottom: 2px solid #ccc;
  padding-bottom: 7px;
}
.calender-event-sec tr th {
  padding: 18px 0px;
}
.calender-event-sec tr td {
  padding: 11px 0px !important;
}
.calender-event-sec .pd-r {
  padding-right: 0px;
  max-width: 100%;
}
.calender-event-sec .pd-r {
  padding-right: 0px;
}
.calender-event-sec .pd-le {
  padding-left: 0px !important;
}
.calender-event-sec .e-footer-container {
  display: none;
}
.calendar-event-box {
  height: 90%;
  padding: 15px 15px;
  border-left: 2px solid #ccc;
}
.event-sec .cur-date {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
.calendar-event-box .event-box {
  background-color: #00a6892e;
  margin-bottom: 11px;
  border: 2px solid #00a689;
  border-radius: 5px;
  padding: 5px 10px;
}
.calendar-event-box .event-box ul {
  padding-left: 0px;
  list-style: none;
}
.calendar-event-box .event-box h5 {
  color: #00a689;
  font-size: 19px;
  margin-bottom: 0px;
}
.calendar-event-box .event-box .event-title {
  color: #000;
  font-size: 21px;
  font-weight: 600;
}
.calendar-event-box .event-box .event-time {
  color: rgb(158, 158, 158);
  font-size: 21px;
}
.calendar-color-list ul {
  list-style: none;
  padding-left: 0px;
  background-color: #efefef;
  padding: 12px 40px;
  margin: 0px 17px;
  border-radius: 10px;
}
.calendar-color-list li {
  display: inline-block;
  margin-right: 29px;
  height: initial;
  position: relative;
  padding-left: 4px;
}
.color-yellow::before {
  content: ".";
  position: absolute;
  font-size: 51px;
  top: -40px;
  color: #ffe200;
  left: -10px;
}

.color-green::before {
  content: ".";
  position: absolute;
  font-size: 51px;
  top: -40px;
  color: #00c50a;
  left: -10px;
}
.color-black::before {
  content: ".";
  position: absolute;
  font-size: 51px;
  top: -40px;
  color: #000000;
  left: -10px;
}

.calendar-color-list li:last-child {
  margin-right: 0px;
}
.calendar-event-box .event-box .event-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.assignment-tabelbg {
  background: #e3e3e3;
  border-radius: 8px 8px 0px 0px;
}
.assign_row ul li {
  list-style: none;
}
.assign_row h4 {
  color: #00a68d;
}
.assign_row {
  background-color: #ffffff;
}
.assign_table img {
  height: 18rem;
  width: 100%;
}
.row.assign_row h6 {
  line-height: 1.5rem;
  margin-bottom: 0rem;
}
.assign_row {
  padding: 1rem;
}
.assign_row p {
  color: rgb(114, 113, 113);
}
.assign_row .col-md-7 {
  border-right: 2px solid lightgray;
}
.stats_head {
  font-size: 1rem;
}
.box_notes {
  height: 100vh;
  width: 100%;
  background-color: white;
}
.assignment-tabelbg h6 {
  margin: 0px 0px 0px 0px;
}
@media only screen and (max-width: 1190px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0.5rem;
  }
  .livemid {
    width: calc(100% - 0px);
  }
  .livestreammid {
    width: 100%;
  }
  .statistics img {
    width: 60%;
  }
  .calender-btn .pdf-down {
    margin-right: 6px;
  }
  .calendar-timeline-btn li {
    padding: 11px 3px;
  }
  .calender-btn .pdf-down {
    font-size: 14px;
    padding: 15px 3px;
  }
}
@media only screen and (max-width: 991px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 50%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
  }
  .card {
    width: 100%;
  }
  .progressm {
    width: calc(100% - 0px);
  }
  .progress21 {
    width: calc(100% - 0px);
  }
  .progresscard {
    width: 100%;
  }
  .mentor .three-col li {
    width: calc(100% / 2 - 30px) !important;
  }
  .goalmid {
    width: calc(100% - 0px);
  }
  .assignmentmid {
    width: calc(100% - 0px);
  }
  .assignmentmid-edu {
    width: calc(100% / 2 - 0px) !important;
  }
  .advertisement-block {
    padding: 25px 40px;
    flex-grow: 1;
  }
  .course-schedule img {
    width: 100%;
  }
  .calender-btn .pdf-down {
    margin-right: 20px;
  }
  .calendar-timeline-btn li {
    padding: 11px 11px;
  }
  .calender-btn .pdf-down {
    font-size: 14px;
    padding: 15px 11px;
  }
  .calender_main {
    width: calc(100% - 0px) !important;
  }
}
@media only screen and (max-width: 830px) {
  .primary-table-ui .table-responsive-md table tbody tr td {
    height: 80px;
    padding: 0px 10px;
  }
  .assignment-tabelbg.row .col-md-3 h6 {
    font-size: 14px;
    font-weight: bold;
  }
  .assignment-tabelbg h6 {
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 60%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .settingw {
    width: 100%;
  }
  .leftnavrow {
    width: 100%;
  }
  .leftnavmid {
    width: 100%;
  }
  .livestreammid {
    text-align: center;
  }
  .custom-nav-tabs ul li {
    height: 49px;
    display: flex;
    width: 50%;
    text-align: center;
    margin: auto;
    align-items: center;
    padding: 0 26px;
    font-size: 16px;
    margin: auto;
    line-height: 23px;
    display: table;
    padding-top: 15px;
    color: #111;
    position: relative;
    cursor: pointer;
  }
  .leftnavmid .col-md-4 {
    width: 50%;
  }
  .leftnavmain {
    width: calc(100% - 0px);
  }
  .checkprice h4 {
    padding-left: 60px;
  }
  .main-mid.rewardmid {
    width: calc(100% - 0px);
  }
  .mentor .three-col li {
    width: calc(100% / 1 - 30px) !important;
  }
  .mentor {
    width: calc(100% - 0px);
    padding: 25px 40px;
  }
  .referral-code img {
    width: 100%;
  }
  .link-paytm .wrap {
    width: 100%;
    margin: 0 auto;
  }
  .reward-banner {
    display: block;
    padding-right: 0px;
    padding: 20px 0px;
  }
  .reward-banner .image {
    width: 100%;
  }
  .reward-banner .wrap button {
    margin: auto;
    display: block;
  }
  .tabs-select {
    width: 100%;
  }
  .offset-sm-1 {
    margin-left: 0;
  }
  .statistics {
    margin-top: 12px;
  }
  .assign-head {
    width: 100%;
  }
  .custom-nav-tabs {
    width: 100%;
    padding: 0px 5px;
  }
  .custom-nav-tabs ul {
    display: initial;
  }
  .custom-nav-tabs ul {
    width: 100%;
  }
  .custom-nav-tabs ul li {
    padding: 12px 3px;
  }
  .assignmentmid {
    width: calc(100% - 0px);
  }
  .assignmentmid-edu {
    width: calc(100% / 2 - 0px) !important;
  }
  .advertisement-block {
    padding: 25px 40px;
    flex-grow: 1;
  }
  .course-schedule img {
    width: 100%;
  }
  .row_cal {
    margin-right: 0px;
  }
  .calender-btn {
    margin-top: 12px;
  }
}

/* @media only screen and (max-width: 660px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 40%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .settingw {
    width: 100%;
  }
  .leftnavrow {
    width: 100%;
  }
  .leftnavmid {
    width: 100%;
  }
} */
@media only screen and (max-width: 600px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 70%;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .settingw {
    width: 100%;
  }
  .leftnavrow {
    width: 100%;
  }
  .leftnavmain {
    width: calc(100% - 0px);
  }
  .leftnavmain h3 {
    font-size: 19px;
  }
  .reward-banner .wrap h4 {
    font-size: 3rem;
  }
  .assignment--row ul li {
    width: calc(100% - 0px);
    margin-bottom: 10px;
    background: #f5f9ff;
    border: 0.5px solid #e0e6ee;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding-left: 9px;
    padding-right: 16px;
    cursor: pointer;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .assignment--row ul {
    display: inherit;
  }
}
/*
@media only screen and (max-width: 560px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 40%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavmid {
    width: 100%;
  }
  /* .leftnavrow p{
      font-size: 1rem;
    } */
/* } */
@media only screen and (max-width: 520px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 70%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .leftnavmid .col-md-4 {
    width: 100%;
  }
  .leftnavmain .subscribed img {
    width: 100%;
  }
  .checkprice h4 {
    padding-left: 0px;
  }
  .headingcheck h1 {
    font-size: 25px;
  }
  td .d-flex {
    flex-direction: column;
  }
}
@media only screen and (max-width: 450px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 90%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .progressimg {
    margin-right: 0.5rem;
  }
  .reward-banner {
    display: block;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 420px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkdown {
    width: 90%;
  }
  .checkoutp {
    margin-left: 1rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .progressimg {
    height: 2rem;
    margin-right: 0.5rem;
  }
}
@media only screen and (max-width: 388px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .leftnavrowp {
    margin-bottom: 0.5rem;
  }
  .calender-btn .calendar-timeline-btn {
    margin-right: 0px;
  }
  .calender-btn .pdf-down {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 360px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
  }
  .main-mid.rewardmid {
    width: calc(100% - 0px);
  }
  .calender-btn .pdf-down {
    margin-right: 6px;
  }
  .calendar-timeline-btn li {
    padding: 11px 3px;
  }
  .calender-btn .pdf-down {
    font-size: 14px;
    padding: 15px 3px;
  }
}
@media only screen and (max-width: 320px) {
  .prodetail a {
    margin-left: 0rem;
  }
  .checkoutp {
    margin-left: 0rem;
  }
  .leftnavrow {
    width: 100%;
  }
}
/*# sourceMappingURL=scss.css.map */
.checkout-page {
  width: calc(100% - 30px);
  max-width: 517px;
  margin: 15px auto;
  background: #ffffff;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
  padding: 20px;
  @media screen and (min-width: 768px) {
    padding: 57px 125px 34px;
    width: 100%;
    margin: 50px auto;
  }
  button {
    margin: 20px auto 0;
    width: 160px;
    display: block;
    @media screen and (min-width: 768px) {
      margin: 50px auto 0;
    }
  }
  .details {
    h3 {
      font-weight: 600;
      text-align: center;
      font-size: 16px;
      margin: 0 0 15px;
      line-height: 16px;
      color: #000000;
      @media screen and (min-width: 768px) {
        font-size: 18px;
        margin: 0 0 18px;
        line-height: 18px;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        font-size: 15px;
        line-height: 15px;
        color: #000000;
        display: flex;
        align-items: center;
        @media screen and (min-width: 768px) {
          font-size: 18px;
          line-height: 18px;
        }
        & + li {
          margin-top: 10px;
          @media screen and (min-width: 768px) {
            margin-top: 17px;
          }
        }
        span {
          strong {
            font-weight: 600;
            em {
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              display: block;
              font-style: normal;
            }
          }
          &:first-child {
            flex-grow: 1;
          }
          &:last-child {
            min-width: 100px;
            flex-shrink: 0;
          }
        }
      }
    }
    &--footer {
      margin-top: 20px;
      @media screen and (min-width: 768px) {
        margin-top: 55px;
      }
      ul {
        li {
          align-items: flex-start;
        }
      }
    }
  }
  .custom-thumbnail {
    margin: 0 auto 20px;
    @media screen and (min-width: 768px) {
      width: calc(100% - 50px);
      margin: 0 auto 40px;
    }
  }
  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #000000;
    margin: 0 0 20px;
    text-align: center;
    @media screen and (min-width: 768px) {
      font-size: 30px;
      margin: 0 0 49px;
    }
  }
}

.box_notes {
  padding: 15px;
  height: auto;
  embed {
    display: block;
    margin: 0 !important;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .custom-nav-tabs ul {
    padding: 0 10px;
  }
  .custom-nav-tabs ul li {
    padding: 0;
    justify-content: center;
    font-size: 13px;
  }
  .advertisement-block {
    width: 100%;
  }
  .box_notes {
    height: auto;
    embed {
      max-width: 100%;
      min-height: 1px;
    }
  }
}

.student-navbar {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
  height: 70px;
  background-color: #fff;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
  .left {
    display: flex;
    align-items: center;

    .logo {
      margin-right: 105px;
      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 1023px) {
      .nav {
        display: none;
      }
    }

    svg {
      margin-right: 10px;
      font-size: 30px;
      display: none;
      @media screen and (max-width: 1199px) {
        display: block;
      }
      g {
        fill: #000;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    // .button-dropdown {
    //   margin-right: 20px;
    //   @media screen and (max-width: 767px) {
    //     margin-right: 0;
    //   }
    // }
    // .user-dropdown {
    //   margin-left: 20px;
    //   @media screen and (max-width: 767px) {
    //     margin-left: 0;
    //   }
    // }
    @media screen and (max-width: 767px) {
      .user-dropdown .dropdown-toggle {
        min-width: 1px;
      }
      .user-dropdown .dropdown-toggle .user-name {
        font-size: 0;
      }
      .user-dropdown .dropdown-toggle .icon-down-arrow {
        display: none;
      }
      .notification-wrapper .notification-toggle::before {
        display: none;
      }

      .notification-wrapper .notification-toggle::after {
        display: none;
      }
      .notification-wrapper .notification-toggle .noti-icon {
        margin: 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .student-navbar .right .button-dropdown {
    position: fixed;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    left: 0;
    bottom: 0;
    padding: 10px;
    display: none;
  }

  .student-navbar .right .button-dropdown .btn {
    width: 100%;
  }

  .button-dropdown .dropdown-menu {
    width: calc(100% - 20px) !important;
  }

  .student-navbar .left .logo img {
    width: 120px;
  }
}

@media screen and (max-width: 1199px) {
  .navbar {
    min-height: 70px;
  }

  body .mdk-drawer-layout .mdk-drawer[data-persistent].user-left-panel,
  body .mdk-drawer-layout .mdk-drawer[data-persistent].admin-left-panel6 {
    width: 100% !important;
  }

  body .mdk-drawer-layout .mdk-drawer[data-persistent] {
    width: 100% !important;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    overflow: auto;
    height: calc(100vh - 70px);
    top: 70px;
    left: -100%;
    transition: all ease-in-out 0.3s;
  }

  body .mdk-drawer-layout .mdk-drawer[data-persistent].show {
    left: 0;
    transition: all ease-in-out 0.3s;
  }

  body
    .mdk-drawer-layout
    .mdk-drawer[data-persistent]
    .left-panel {
    position: static;
    overflow: auto;
  }
}


.button-dropdown {
  .dropdown-toggle {
    min-width: 212px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #239f87 !important;
    border-color: #239f87 !important;
    border-radius: 8px;
    font-size: 18px !important;
    font-weight: bold;
    padding: 0 16px;
    &:after {
      display: none;
    }
    svg {
      margin-left: 10px;
    }
    &:focus {
      background-color: #239f87;
      border-color: #239f87;
      box-shadow: none !important;
    }
    &:active {
      background-color: #239f87;
      border-color: #239f87;
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    left: auto;
    right: 0;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #E5E5E5;
    overflow: auto;
    max-height: 200px;
    min-width: 1px !important;
    width: 100%;
    padding: 12px 8px;
    margin-top: 0 !important;
    &:after {
      display: none;
    }
    .custom-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
      border: 1px solid #C4CDD5;
      box-sizing: border-box;
      box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
      border-radius: 4px;
      margin-bottom: 8px;
      cursor: pointer;
      padding: 6px 8px;
      position: relative;
      .custom-radio {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        input:not(:checked) + label::before {
          background-color: transparent;
        }
        .form-check {
          position: static;
          padding: 0;

          label {
             &::before {
              width: 100%;
              height: 100%;
              border-radius: 0;
            }
            &:after {
              display: none;
            }
          }
        }
      }

    }
    .custom-item-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
      position: relative;
      z-index: 9;
      text-transform: none;
    }
  }
}

.student-navbar {
  .nav-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: #666666;
    &.active {
      color: #239f87 !important;
    }
  }
}

.courser-selection {
  &.three-col {
    ul li {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: calc((100% / 3) - 24px);
      }
    }
  }
  ul {
    list-style: none;
    margin: 0 -12px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      @media screen and (max-width: 767px) {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: normal;
      }
      margin: 0 12px 24px;
      height: auto;
      display: flex;
      align-items: center;
      font-family: "Source Sans Pro", sans-serif !important;
      font-style: normal;
      padding: 10px;
      position: relative;
      color: #aaaaaa;

      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #00A689;
      background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
      border: 1px solid #C4CDD5;
      box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
      border-radius: 6px;
      &.selected {
        color: #fff;
        padding-left: 10px;
        background: #00A689;
        border: 1px solid #00A689;
        span {
          border-color: transparent;
          background-color: transparent;
          margin-right: 10px;
          @media screen and (max-width: 767px) {
            margin-right: 10px;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          color: #fff;
          position: static;
          right: 0;
          margin-right: 0;
          top: 0;

          @media screen and (max-width: 767px) {
            width: 18px;
            height: 18px;
          }
        }
        &::before {
          content: "";
          // background-image: url("../../dist/images/batch/course-selection.svg");
          width: 40px;
          height: 40px;
          position: absolute;
          right: -1px;
          top: -1px;
          display: none;
        }
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
      }
      span {
        width: 48px;
        height: 48px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        transform: none;
        left: 0;
        position: static;
        font-size: 0;

        background: rgba(0, 166, 137, 0.1);
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 35px;
          height: 35px;
          margin-right: 15px;
        }
      }
    }
  }
}

.assignment-wrap {
  & + .assignment-wrap {
    margin-top: 30px;
    @media screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }
  h3 {
    color: #00A689 !important;
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .assignment-table {
    &--head {
      margin-bottom: 15px;
      @media screen and (max-width: 767px) {
        display: none;
      }
      .assignment-table--col {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #111111;
      }
    }
    &--body {
      .assignment-table--row {
        & + .assignment-table--row {
          margin-top: 20px;
          @media screen and (max-width: 767px) {
            margin-top: 0;
            border-top: 1px solid #eee;

          }
        }
      }
    }
    &--row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      @media screen and (max-width: 767px) {
        justify-content: space-between;
        padding: 15px 0;
      }
    }
    &--col {
      &:first-child {
        width: calc(100% - 500px);
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      &:nth-child(2) {
        width: 250px;
        @media screen and (max-width: 767px) {
          width: auto;
        }
      }
      &:last-child {
        width: 250px;
        @media screen and (max-width: 767px) {
          width: auto;
        }
      }
      padding: 0 15px;
      span {
        font-size: 14px;
        line-height: 18px;
        display: flex;
        color: #666666;
        a {
          color: #00A689;
        }
      }
      p {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
        line-height: 18px;
        display: block;
        color: #666666;
        & + span {
          margin-top: 4px;
        }
      }
    }
  }
}

.assignment-type-bullets {
  display: block;
  width: 10px;
  font-size: 0;
  height: 10px;
  border-radius: 50%;
  &.test {
    background-color: rgb(190, 171, 0);
  }
  &.group {
    background-color: rgb(0, 184, 190);
  }
  &.batch {
    background-color: rgb(0, 190, 76);
  }
}

.simple-table {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 10px 0;
  &.batch-reports {
    li {
      &:first-child {
        width: calc(87px + 58px);
      }
      width: calc(100% - (87px + 58px));
    }
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child{
    padding-bottom: 0;
  }
  & + .simple-table {
    border-top: 1px solid #E9E9E9;
  }
  li {
    font-size: 14px;
    line-height: 18px;
    color: #35363B;
    strong {
    font-weight: 600;
    }
  }
}

.educator-info-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    display: flex;
    cursor: pointer;
    margin-right: 50px;
    margin-top: 5px;
    color: #35363B;
    span {
      width: 85px;
    }
  }
}

.profile-box {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 50px 50px;
  &--header {
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    .image {
      margin-right: 50px;
      label {
        display: block;
        input {
          display: none;
        }
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        line-height: 22px;
        /* identical to box height, or 157% */
        margin: 4px 0 0;
        text-align: center;
        letter-spacing: -0.3px;

        color: #111111;
      }
      img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #f5f9ff;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      li {
        & + li {
          margin-left: 16px;
        }
        background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
        border: 1px solid #C4CDD5;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 24px;
        height: 52px;
        box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
        border-radius: 6px;
        letter-spacing: -0.3px;
        font-weight: 600;
        font-size: 12px;
        color: #666666;
        span {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}

.tabbed-radio {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      & + li {
        margin-left: 11px;
      }
      input {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &:checked {
          & + span {
            background: #00A689;
            border-color: #00A689;
            color: #fff;
          }
        }
      }
      span {
        height: 40px;
        border-radius: 6px;
        padding: 0 28px;
        display: flex;
        align-items: center;
        background: linear-gradient(180deg,#fff 0,#f9fafb 100%);
        border: 1px solid #c4cdd5;
        box-shadow: 0 1px 0 rgba(22,29,37,.05);
        font-weight: 500;
        font-size: 13px;
        line-height: 1;
        letter-spacing: -0.3px;
        color: #111;
      }
    }
  }
  p.radio-heading {
    display: block;
    font-family: "Source Sans Pro",sans-serif;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    color: #393e41;
  }
}

.helper-link {
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
  cursor: pointer;
  color: #00A689;
  svg {
    margin-left: 0;
    font-size: 16px;
  }
}

.otp-box {
  display: flex;
  .form-group {
    margin: 0;
    &:not(:last-child) {
      margin-right: 16px;
    }
    .primary-form-control {
      width: 40px;
      text-align: center;
    }
  }
}

.resend-btn {
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  color: #EB4335;
}