/* add-question-container */
.add-question-container {
  margin-bottom: 120px;
}
.add-question-container .form-control {
  height: 38px;
}
.add-question-container .form-control:hover {
  border-color: #239f87;
}
.add-question-container .form-row {
  margin-right: -10px;
  margin-left: -10px;
}
.add-question-container .form-row > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.add-question-container .btn-form-group-row {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}
.add-question-container .btn-form-group-row .btn {
  height: 38px;
}
.add-question-container .right-side .form-group {
  width: 200px;
}
.add-question-container .custom-file .custom-file-label {
  height: 38px;
  cursor: pointer;
  line-height: 1.7;
}
.add-question-container .custom-file .custom-file-label:after {
  height: 36px;
}
.add-question-container .add-bottom-form-box {
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
}
.add-question-container .add-bottom-form-box .form-group label {
  margin-bottom: 15px;
  line-height: 1;
  text-transform: uppercase;
}
.add-question-container .label-delete-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.add-question-container .label-delete-row .right-side {
  display: flex;
  align-items: center;
}
.add-question-container .label-delete-row .right-side .true-false-text {
  margin-right: 10px;
}
.add-question-container .label-delete-row label {
  color: #7f8381;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
}
.add-question-container .label-delete-row .btn {
  min-width: 50px;
  height: 35px;
}
.add-question-container .add-bottom-form-box .for-custom-radio {
  display: flex;
  margin-bottom: 20px;
  margin-top: 38px;
}
.add-question-container .add-bottom-form-box .for-custom-radio label {
  text-transform: capitalize;
  margin: 0 0 0 8px !important;
  line-height: 1;
}
.add-question-container .add-bottom-form-box .for-custom-radio div {
  display: flex;
  position: relative;
}
.add-question-container .add-bottom-form-box .for-custom-radio div:first-child {
  margin-right: 25px;
}
.add-question-container .add-bottom-form-box .for-custom-radio input:after {
  cursor: pointer;
  top: -2px;
}
.add-question-container .add-bottom-form-box .for-custom-radio input:before {
  cursor: pointer;
}
.add-question-container
  .add-bottom-form-box
  .for-custom-radio
  input:checked:before {
  width: 7px;
  height: 7px;
  border-radius: 15px;
  top: 4px;
  left: 3px;
  position: absolute;
  background-color: white;
  content: "";
  z-index: 9;
}
.add-question-container .add-bottom-form-box .custom-checkbox label {
  margin-bottom: 0;
  cursor: pointer;
}
.add-question-container .add-bottom-form-box .add-option-btn {
  height: 38px;
  background-color: #ffe200;
  font-size: 14px;
  border: 1px solid #e1e3e7;
  margin-bottom: 20px;
  margin-left: 5px;
}
.add-question-container .add-bottom-form-box .editor-2 {
  margin: 20px 0;
  position: relative;
}
.add-question-container .add-bottom-form-box .editor-2 .required-text {
  font-size: 14px;
  color: #239f87;
  margin-top: 3px;
  margin-bottom: 5px;
}
.add-question-container .add-bottom-form-box .editor-2 .editor-2-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  top: 36px;
}
.add-question-container
  .add-bottom-form-box
  .editor-2
  .editor-2-bottom
  .editor-2-checkbox
  label {
  margin-bottom: 0;
  margin-left: 5px;
  cursor: pointer;
}
.add-question-container
  .add-bottom-form-box
  .editor-2
  .editor-2-bottom
  .delete-icon {
  margin-left: 10px;
}
.add-question-container
  .add-bottom-form-box
  .editor-2
  .editor-2-bottom
  .delete-icon
  label {
  display: none !important;
}
.add-question-container
  .add-bottom-form-box
  .editor-2
  .editor-2-bottom
  .delete-icon
  .btn {
  min-width: 50px;
  height: 35px;
}
.add-question-container .add-bottom-form-box .form-group .tox-tinymce {
  margin-bottom: 28px;
  width: 100% !important;
}
.ques-count-text {
  text-align: right;
  margin-bottom: 18px;
  color: #272c33;
  font-size: 15px;
}

/* for-height */
.for-height .form-control {
  height: 38px;
  padding-left: 12px;
}
.for-height .form-control:hover {
  border-color: #239f87;
}

/* ques-row */
.ques-row {
  padding: 15px 0;
}
.ques-row .ques-left {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
}
.ques-row .ques-left::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  right: -8px;
  bottom: 0;
  margin: auto;
  background-color: #acb0b5;
}
.ques-row .ques-left .ques-count {
  background-color: #47b3bb;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-bottom: 25px;
}
.btn {
  min-width: 50px;
  height: 35px;
}
.ques-row .form-group {
  margin-bottom: 8px;
}
.ques-row .form-control {
  height: 38px;
  background-color: white;
}
.ques-row .form-control:hover {
  border-color: #47b3bb;
}
.ques-row label {
  font-size: 15px;
  margin-bottom: 5px;
}
/* add-question-wrapper */
.add-question-wrapper {
  text-align: center;
  margin: 50px 0;
}
.add-question-wrapper .icon-wrapper {
  height: 80px;
  width: 80px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.add-question-wrapper img {
  height: 45px;
}
.add-question-wrapper .text {
  font-size: 15px;
  margin: 20px 0;
}
/* Custom-upload */
.custom-upload input {
  display: none;
}
.custom-upload label {
  background-color: #1ea7fd;
  padding: 8px 20px;
  color: white;
  cursor: pointer;
  border-radius: 6px;
  font-size: 15px;
}
/* question-list-container */
.question-list-container .card {
  border-radius: 0;
}
.question-list-container .table-responsive-md thead tr {
  height: 60px;
}
.question-list-container .table-responsive-md thead tr th {
  padding: 0 15px;
  font-size: 14px;
}
.question-list-container .table-responsive-md tbody tr {
  min-height: 50px;
}
.question-list-container .table-responsive-md tbody tr td {
  padding: 5px 15px;
}
.question-list-container .table-responsive-md tbody tr td:nth-child(2) {
  max-width: 350px;
  white-space: inherit;
}
.question-list-container .table-responsive-md tbody tr td p {
  margin-bottom: 0;
}
.tox-statusbar {
  display: none !important;
}

/* bulk-ques-box */
.bulk-ques-box {
  background-color: white;
  padding: 15px;
  display: flex;
  border: none;
  margin-bottom: 30px;
}
.bulk-ques-box.alert-danger {
  color: #844042;
  background-color: #f7dddc;
  border-color: #f4cfce;
}
.bulk-ques-box .left-side {
  width: 50px;
  // text-align: center;
}
.bulk-ques-box .left-side .count {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #239f87;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto 30px;
}
.bulk-ques-box .right-side {
  width: calc(100% - 50px);
}
.bulk-ques-box .right-side .row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 -8px;
}
.bulk-ques-box .right-side .form-control {
  box-shadow: none;
}
.bulk-ques-box .right-side .form-control:focus {
  border: 1px solid #edf0f2;
}
.bulk-ques-box .right-side .row .bulk-input-group {
  width: 20%;
  padding: 0 8px;
}
// .bulk-ques-box .right-side .row .bulk-input-group .form-group {
//   margin-bottom: 0;
// }
.bulk-ques-bottom-box .form-group-wrapper {
  width: 85%;
}
.bulk-ques-bottom-box .form-group-wrapper span {
  font-size: 15px;
  margin-bottom: 5px;
  display: block;
}
// .bulk-ques-bottom-box .form-group {
//   margin-bottom: 0;
// }
.bulk-ques-bottom-box .form-group-wrapper textarea.form-control {
  margin-bottom: 0;
}
.bulk-ques-bottom-box .form-group .form-control {
  height: 38px;
  background-color: white;
  border: 1px solid #edf0f2;
  font-size: 15px;
  color: #434544;
}
.bulk-ques-bottom-box .form-group span {
  font-size: 15px;
  color: #48506a;
  margin-bottom: 4px;
  display: block;
}
.bulk-ques-bottom-box .form-group textarea.form-control {
  height: 100px;
  padding-left: 15px;
  padding-top: 5px;
  resize: none;
}
.bulk-ques-bottom-box .input-with-side-text {
  display: flex;
  margin-bottom: 0;
}
.bulk-ques-bottom-box .input-with-side-text span {
  font-size: 15px;
  padding-top: 8px;
}
.bulk-ques-bottom-box .input-with-side-text .option-heading {
  font-size: 15px;
  min-width: 95px;
}
.bulk-ques-bottom-box .input-with-side-text .form-group {
  margin-bottom: 0;
  margin-right: 15px;
}
.bulk-ques-bottom-box .input-with-side-text .text {
  margin-top: 15px;
  margin-top: 1px;
  margin-right: 0;
}
.bulk-ques-bottom-box .input-with-side-text .form-group {
  width: 76%;
}
.bulk-ques-bottom-box .input-with-option-check {
  display: flex;
  margin-bottom: 16px;
}
.bulk-ques-bottom-box .input-with-option-check .form-group {
  margin-bottom: 0;
  width: 76%;
  margin-right: 15px;
}
.bulk-ques-bottom-box .input-with-option-check .option-heading {
  font-size: 15px;
  min-width: 95px;
}
.bulk-ques-bottom-box .input-with-option-check p {
  width: 77.5%;
  margin-bottom: 0;
}
.bulk-ques-bottom-box .input-with-option-check .invalid-feedback {
  display: none !important;
}
.ques-bank-box {
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
}
.ques-bank-box .ques-bank-box-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 10px;
}
.ques-bank-box .ques-bank-box-top .ques-no {
  color: #095846;
  margin-right: 10px;
}
.ques-bank-box .ques-bank-box-top .left-side {
  display: flex;
}
.ques-bank-box .ques-bank-box-top .left-side ul {
  list-style-type: none;
  display: flex;
  margin-left: -30px;
  margin-bottom: 0;
}
.ques-bank-box .ques-bank-box-top .left-side ul li {
  padding-right: 5px;
  margin-right: 5px;
  position: relative;
  color: #acacac;
}
.ques-bank-box .ques-bank-box-top .left-side ul li:last-child:after {
  display: none;
}
.ques-bank-box .ques-bank-box-top .left-side ul li:after {
  content: "";
  position: absolute;
  right: 0;
  top: 3px;
  background-color: #b5b5b5;
  height: 12px;
  width: 1px;
}
.ques-bank-box .ques-bank-box-top .right-side .used-text {
  text-decoration: underline;
  color: #acacac;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.ques-bank-box .ques-bank-box-top .right-side .used-text img {
  height: 12px;
  margin-left: 10px;
}
.ques-bank-box .question {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ques-bank-box .question span {
  font-size: 16px;
  margin-right: 13px;
}
.ques-bank-box .question p {
  margin-bottom: 0;
  font-size: 13px;
}
.ques-bank-box .ques-edit-delete-row {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  color: #b5b5b5;
}
.ques-bank-box .ques-edit-delete-row .ques-edit {
  margin: 0 20px;
  cursor: pointer;
}
.ques-bank-box .ques-edit-delete-row .ques-delete {
  cursor: pointer;
}
.ques-bank-box .ques-edit-delete-row .ques-delete img {
  height: 16px;
}
.question-list-container .card {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}
.question-list-container .card thead {
  display: none;
}
.question-list-container .card tbody tr {
  min-height: auto;
}
.question-list-container .card tbody tr td {
  padding: 0;
  white-space: inherit;
  border: none;
}
.question-list-container .card-footer {
  border-radius: 0;
  border: none;
}
.question-list-container .form-row .form-control {
  background-color: white;
  font-size: 16px;
}
.question-list-container .question-date-picker-wrapper {
  display: flex;
}
.question-list-container
  .question-date-picker-wrapper
  .react-datepicker-wrapper {
  margin: 0 5px;
}
.question-list-container
  .question-date-picker-wrapper
  .react-datepicker-wrapper
  input {
  height: 38px;
  font-size: 16px;
  padding-left: 12px;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  color: #596d8c;
  border-radius: 4px;
}
