.sidebar-menu {
  margin-bottom: $sm-spacing-y;
  padding: 0 $sm-spacing-x;
  list-style: none;
}
.sidebar-menu-item {
  position: relative;
  overflow: hidden;
}
.sidebar-menu-button {
  display: flex;
  align-items: center;
  font-weight: $sm-button-font-weight;
  line-height: $sm-button-height;
  padding: 0 $sm-button-spacing-x;
  font-size: $sm-button-font-size;
  &, &:hover, &:focus {
    text-decoration: none;
  }
}
.active > .sidebar-menu-button {
  font-weight: $sm-active-button-font-weight;
}
.sidebar-menu-toggle-icon {
  position: relative;
  font-size: $sm-toggle-font-size;
}
.sidebar-menu-toggle-icon:before {
  font-family: $sm-toggle-family;
  content: $sm-toggle-icon;
  display: flex;
  align-items: center;
  font-size: inherit;
  color: inherit;
}
.open > .sidebar-menu-button .sidebar-menu-toggle-icon:before {
  content: $sm-open-toggle-icon;
}
.sidebar-menu-icon {
  vertical-align: middle;
  font-size: $sm-icon-font-size;
  display: inline-block;
  line-height: normal;
  position: relative;
  &--left {
    margin-right: $sm-icon-spacing-x;
  }
  &--right {
    margin-left: $sm-icon-spacing-x;
  }
}
.sidebar-menu-badge {
  padding: $sm-badge-spacing-y $sm-badge-spacing-x;
  font-size: $sm-badge-font-size;
}
.sidebar-menu-badge,
.sidebar-menu-toggle-icon {
  margin-left: $sm-inner-spacing-x;
}

// submenus
.sidebar-submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  .open > & {
    display: block;
  }
  .sidebar-menu-button {
    line-height: $ssm-button-height;
    font-size: $ssm-button-font-size;
  }
  .sidebar-menu-icon {
    font-size: $ssm-icon-font-size;
  }
}