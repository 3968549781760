.question-banks {
  &--footer {
    display: flex;
    padding-bottom: 14px;
    padding-top: 14px;
    align-items: center;
    justify-content: flex-end;
    .last-edit {
      font-size: 13px;
      line-height: 1;
      margin-right: 20px;
      color: #acacac;
    }
    .edit {
      font-size: 13px;
      margin-right: 20px;
      cursor: pointer;
      color: #303956;
    }
    svg {
      display: block;
    }
  }
  &--body {
    margin-top: 10px;
    .wrap {
      position: relative;
      padding-left: 20px;
      ol, ul {
        padding-left: 12px;
      }
      * {
        font-family: "Source Sans Pro", sans-serif !important;
        color: #303956 !important;
        line-height: normal !important;
      }
      & > span {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 13px;
        line-height: 1.15;
        color: #303956;
      }
      p {
        font-size: 14px;
        line-height: 1.15;
        color: #303956;
        white-space: normal;
      }
    }
  }
  &--header {
    display: flex;
    padding-top: 0;
    padding-left: 20px;
    align-items: center;
    justify-content: space-between;
    .wrap {
      display: flex;
      align-items: center;
      span {
        font-size: 13px;
        color: #acacac;
        margin-right: 10px;
        line-height: 1;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        li {
          font-size: 13px;
          color: #acacac;
          line-height: 1;
          padding: 0 5px;
          & + li {
            border-left: 1px solid #acacac;
          }
        }
      }
    }
  }
}

.add-question {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .form-group {
    margin: 0;
    .custom-ReactSelect {
      width: 164px;
      & > div {
        & > span + div:nth-child(2) {
          background-color: #fff72e;
          border: solid 1px #e6e6e6;
          & > div {
            &:first-child {
              & > div:first-child {
                font-size: 13px;
                color: #48506a;
                text-transform: uppercase;
              }
            }
          }
        }
        & > div {
          &:nth-child(1) {
            background-color: #fff72e;
            border: solid 1px #e6e6e6;
            & > div {
              &:first-child {
                & > div:first-child {
                  font-size: 13px;
                  color: #48506a;
                  text-transform: uppercase;
                }
              }
            }
          }
          & + div {
            background-color: #fff;
          }
          & > div {
            &:last-child {
              svg {
                color: #48506a;
              }
            }
          }
        }
      }
    }
  }
}

.add-question-row {
  margin: 20px 0;
  background-color: #fff;
  padding: 15px;
  .wrap {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
      font-size: 16px;
      color: #000000;
    }
    div {
      cursor: pointer;
    }
  }
}

body .react-datepicker {
  font-family: "Source Sans Pro", sans-serif !important;
  border-color: #eee;
}

body .react-datepicker .react-datepicker__header {
  border: 0;
  background-color: #fff;
}

body
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  border-bottom-color: #fff;
}

body
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #eee;
}

body .react-datepicker__day--keyboard-selected,
body .react-datepicker__month-text--keyboard-selected,
body .react-datepicker__quarter-text--keyboard-selected {
  background-color: #239f87;
}

body .react-datepicker__day-name {
  font-weight: 600;
}

.ques-count-text .form-group {
  margin: 0;
  margin-left: 10px;
  text-align: left;
  width: 85px;
}

.primary-delete-btn {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.permission-box {
  margin: 40px 0;

  p {
    font-weight: 600;
    // margin-bottom: 0;
  }

  .wrap {
    background-color: #fff;

    .wrap-row {
      display: flex;
      padding: 15px;
      justify-content: space-between;
      flex-wrap: wrap;
      p {
        margin: 0;
        color: #7f8381;
        width: 200px;
      }
      ul {
        margin: 0;
        padding: 0;
        width: calc(100% - 200px);
        list-style: none;
        display: flex;
        align-items: center;

        li {
          width: calc(100% / 3);
        }
      }
    }
  }
}
