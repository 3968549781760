.primary-modal {
	// @media screen and (max-width: 1023px) {
	//   width: 100% !important;
	//   padding: 0 !important;
	// }
	.batch-accordion .accordion {
		border: 1px solid #eee;
	}
	&.height-default {
		height: auto;
		max-height: calc(100% - 50px);
		// min-height: 480px;
		min-height: 1px;
		@media screen and (max-width: 767px) {
			min-height: 1px;
		}
	}
	&.p-0 {
		padding: 0 !important;
	}
	&.lg {
		width: 100% !important;

		@media screen and (min-width: 768px) {
			width: 80% !important;
		}

		.resume-preview {
			width: 100% !important;
		}
	}
	position: absolute;
	// inset: 50% auto auto 50%;
	border: 0px none;
	background: rgb(255, 255, 255) none repeat scroll 0% 0%;
	overflow: auto;
	border-radius: 4px;
	outline: currentcolor none medium;
	padding: 20px;
	min-width: 420px;
	height: 80%;
	// height: 80%;
	// margin-right: -50%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	box-shadow: gray 0px 0px 5px;

	.scrollable-list {
		overflow: auto;
		height: calc(100% - (40px + 24px));
		margin: 0 -20px;
		padding: 0;
		list-style: none;
		li {
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			& + li {
				margin-top: 20px;
			}
			p {
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				margin: 0;
				color: #35363b;
			}
		}
	}

	header {
		p {
			font-size: 18px;
			line-height: 26px;
			text-align: center;
			margin-bottom: 20px;
			color: #000000;
		}
		h3 {
			font-weight: 600;
			font-size: 18px;
			line-height: 23px;
			text-align: center;
			color: #000000;
			margin-bottom: 30px;
		}
	}
}
.ReactModal__Overlay {
	background-color: rgba(0, 0, 0, 0.8) !important;
	z-index: 99;
}

.test-list-data {
	display: flex;
	align-items: center;
	list-style: none;
	justify-content: space-between;
	margin: 0;
	padding: 10px 0;

	&:first-child {
		padding-top: 0;
	}
	&:last-child {
		padding-bottom: 0;
	}

	& + .test-list-data {
		border-top: 1px solid #d9d9d9;
	}

	li {
		font-size: 13px;
		line-height: 19px;
		color: #666666;
		display: flex;
		align-items: center;

		span {
			&:first-child {
				width: 300px;
			}
			&:nth-child(2) {
				width: 150px;
			}
		}

		strong {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #454746;
		}

		a {
			text-decoration: underline;
		}
	}
}

body {
	.editor-data {
		* {
			font-family: "Source Sans Pro", sans-serif !important;
			background-color: inherit !important;
			margin: 0 0 1rem !important;
			text-indent: inherit !important;
			color: #000 !important;
			text-align: left !important;
			padding: 0 !important;
			font-size: 20px !important;
			line-height: 160% !important;
		}

		h1 {
			* {
				font-family: "Source Sans Pro", sans-serif !important;
				background-color: inherit !important;
				font-size: 2.827rem !important;
				line-height: 1.5 !important;
				margin: 0 !important;
			}
		}

		h2 {
			* {
				font-family: "Source Sans Pro", sans-serif !important;
				background-color: inherit !important;
				line-height: 1.5 !important;
				font-size: 1.999rem !important;
				margin: 0 !important;
			}
		}

		h3,
		h4 {
			* {
				font-family: "Source Sans Pro", sans-serif !important;
				background-color: inherit !important;
				font-size: 1.414rem !important;
				line-height: 1.414 !important;
				margin: 0 !important;
			}
		}

		h5,
		h6 {
			* {
				font-family: "Source Sans Pro", sans-serif !important;
				background-color: inherit !important;
				line-height: 1.5 !important;
				text-transform: none !important;
				font-size: 1rem !important;
				margin: 0 !important;
			}
		}

		p {
			font-size: 20px !important;
			line-height: 160% !important;
			color: #000 !important;
			margin: 0 0 1rem !important;

			* {
				margin: 0 !important;
			}
		}

		strong {
			color: #000 !important;
			font-weight: 700 !important;
			* {
				margin: 0 !important;
			}
		}

		table {
			max-width: 100% !important;
			width: 100% !important;
		}

		ul,
		ol {
			display: block !important;
			li {
				white-space: normal !important;
				font-size: 20px !important;
				line-height: 160% !important;
				position: relative !important;
				color: #000 !important;
				text-align: left !important;
				margin-bottom: 1rem !important;
				* {
					margin: 0 !important;
				}
			}
		}

		img {
			display: block !important;
			margin-bottom: 1rem !important;
			max-width: 100% !important;
			height: auto !important;
			object-fit: contain !important;
		}

		ul {
			list-style: none !important;
			li {
				padding-left: 24px !important;
				&::before {
					content: "";
					background-image: url("https://educrack.com/static/asset/svg/new/star.svg") !important;
					width: 13px !important;
					height: 14px !important;
					position: absolute !important;
					left: 0 !important;
					top: 9px !important;
					background-color: transparent !important;
					opacity: 1 !important;
					transform: none !important;
				}
			}
		}

		ol {
			list-style: decimal !important;
			padding-left: 1rem !important;
		}
	}
}
