.chart {
  position: relative;
  height: 300px;
}

.chart-legend {
  display: flex;
  margin-top: 2.5rem;
  white-space: nowrap;
  justify-content: center;

  &-item {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: $small-font-size;
    color: rgba($body-color, .5);
    padding: .25rem .5rem;
    border: 1px solid $border-color;
    border-radius: $border-radius-sm;
  }

  &-indicator {
    display: block;
    width: .5rem;
    height: .5rem;
    margin-right: .25rem;
    border-radius: 50%;
  }

  &:not(&--vertical) {
    .chart-legend-item + .chart-legend-item {
      margin-left: .5rem;
    }
  }

  &--vertical {
    flex-direction: column;

    .chart-legend-item + .chart-legend-item {
      margin-top: .5rem;
    }
  }
}

#chart-tooltip {
  z-index: 0;
  pointer-events: none;

  .popover-header {
    border-bottom: 0;
  }
  .popover-body {
    text-transform: uppercase;
    font-size: 0.707rem;
    color: rgba($body-color, .7);
  }
  .popover-body + .popover-body {
    padding-top: 0;
  }
  .popover-body-label,
  .popover-body-value {
    margin-left: .25rem;
  }
  .popover-body-indicator {
    margin-right: .25rem;
  }
  .arrow {
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateX(-.5rem);
  }
  .popover-body-indicator {
    display: inline-block;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
  }
}
