@import '../utils/variables';
.primary-card {
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 20px 27px;
  p {
    font-family: $secondaryFont;
    font-weight: $bold;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 23px;
    color: #2a2a2a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    strong {
      font-family: $secondaryFont;
      font-weight: $semiBold;
      font-size: 16px;
      line-height: 16px;
      color: #239f87;
    }
  }

  .info {
    margin: 6px 0 20px;
    span {
      display: block;
      font-size: 16px;
      line-height: 18px;
      font-family: $secondaryFont;
      color: #6f6f6f;
    }
    &-path {
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        & + span {
          margin-left: 20px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 4px;
            height: 7px;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            // background-image: url(../svg/angle-right-shade.svg);
          }
        }
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      & + li {
        margin-left: 75px;
      }
      span {
        display: block;
        font-size: 16px;
        line-height: 18px;
        font-family: $secondaryFont;
        color: #6f6f6f;
        & + span {
          margin-top: 10px;
        }
      }
      strong {
        display: block;
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        font-family: $secondaryFont;
        color: #2a2a2a;
      }
    }
  }
}
