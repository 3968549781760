.brand {
  font-size: 1.625rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 1.231;
  &-icon {
    margin-right: .5rem;
    display: inline-block;
    vertical-align: sub;
  }
}