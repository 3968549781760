.primary-avtar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #fff;
  flex-shrink: 0;
  border: 1px solid #e6e6e6;
  background-repeat: no-repeat;
  background-size: cover;
}

.avtar-with-text {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .primary-avtar {
    margin-right: 20px;
  }
  .wrap {
    p {
      margin: 0;
      &.avtar-email {

        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        text-align: left;
        word-break: break-word;
        color: #acb0b5;
      }
      &.avtar-mobile {
        margin-top: 4px;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        text-align: left;
        word-break: break-word;
        color: #434544;
      }
      &.avtar-name {
        word-break: break-word;
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 15px;
        text-align: left;
        color: #434544;
      }
    }
  }
}
