.list-with-tag {
  & + .list-with-tag {
    margin-top: 10px;
  }
  .tag-header {
    position: relative;
    margin-bottom: 20px;
    &::before {
      background-color: #e6e6e6;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
    }
    ul {
      background: #e6e6e6;
      border-radius: 4px;
      list-style: none;
      position: relative;
      z-index: 1;
      margin: 0;
      padding: 10px;
      display: inline-flex;
      align-items: center;
      li {
        font-family: $secondaryFont;
        font-size: 14px;
        line-height: 18px;
        color: #2a2a2a;
        & + li {
          margin-left: 10px;
        }
      }
    }
  }
}
