.small-modal {
  .modal-dialog {
    max-width: 365px;
  }
  .modal-body {
    padding: 30px 30px 15px 30px;
    .heading {
      font-size: 18px;
      color: #2A2A2A;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .modal-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    strong {
      font-size: 14px;
      color: #2A2A2A;
      font-weight: bold;
    }
    span {
      font-size: 14px;
      color: #6F6F6F;
    }
  }
  .round-btn {
    margin-top: 10px;
  }
  .modal-content {
    border-radius: 10px;
  }
}
