// @import './partial/modal.scss';
// @import './partial/form.scss';
// @import './partial/wall.scss';

.wall-banner {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  padding: 30px 20px 30px 28px;
  display: none;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px 20px 0;
  }
  .wrap {
    flex-grow: 1;
    // @media screen and (max-width: 767px) {
    //   text-align: center;
    // }
    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 18px;
      margin: 0 0 40px;
      color: #239f87;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: normal;
        margin-bottom: 20px;
      }
    }
    p {
      font-size: 22px;
      line-height: 32px;
      color: #000000;
      margin-bottom: 24px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: normal;
      }
    }
    span {
      display: block;
      padding-top: 23px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 18px;
      line-height: 18px;
      color: #2a2a2a;
      @media screen and (max-width: 767px) {
        line-height: normal;
        font-size: 16px;
        padding-top: 20px;
      }
      strong {
        font-weight: 700;
      }
    }
  }
}

.rewards-banner {
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 38px;
  justify-content: space-between;
  margin-bottom: 40px;

  .wrap {
    display: flex;
    align-items: center;
    .points {
      margin-right: 30px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 0;
      letter-spacing: -0.24px;
      color: #2a2a2a;
    }
    span {
      width: 124px;
      height: 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      background: rgba(35, 159, 135, 0.1);
      font-size: 20px;
      line-height: 1;
      letter-spacing: -0.24px;
      margin-bottom: 5px;
      color: #2a2a2a;
      strong {
        font-weight: bold;
        font-size: 50px;
        line-height: 1;
        margin-bottom: -5px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #2a2a2a;
      }
    }
  }
}

.transaction-list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style: none;
  & + .primary-page-header {
    margin-top: 30px;
  }
  li {
    background: #ffffff;
    border: 1px solid rgba(216, 216, 216, 0.55);
    display: flex;
    padding: 14px 32px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    & + li {
      margin-top: 10px;
    }
    em {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #2a2a2a;
      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin-left: 1px;
        color: #2a2a2a;
      }
    }
    strong {
      font-size: 26px;
      line-height: 32px;
      /* identical to box height, or 123% */

      text-align: center;
      letter-spacing: -0.24px;

      display: flex;
      align-items: center;

      color: #239f87;
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 16px;
      color: #2a2a2a;
      span {
        display: block;
        font-size: 12px;
        line-height: 16px;
        color: #6f6f6f;
        margin-top: 2px;
      }
    }
  }
}

.referral-code-box {
  margin-top: 90px;
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.55);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  text-align: center;
  padding: 30px 20px;

  p {
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    color: #666666;
    em {
      cursor: pointer;
      font-style: normal;
      svg {
        font-style: 16px;
        margin-right: 4px;
      }
    }
  }

  span {
    font-weight: bold;
    font-size: 16px;
    padding: 0 20px;
    min-width: 220px;
    line-height: 24px;
    display: inline-flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px dashed #00a689;
    color: #00a689;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    li {
      a {
        display: block;
        font-size: 24px;
      }
      & + li {
        margin-left: 25px;
      }
    }
  }

  h4 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #111111;
  }
}

.redeem-modal {
  max-width: 380px;
  padding: 10px;
  h4 {
    font-family: Rokkitt, serif;
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #2a2a2a;
    margin-bottom: 30px;
  }
  .resend-otp {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 15px;
    color: #6f6f6f;
    a {
      font-weight: 700;
      color: rgb(35, 159, 135);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0 0 40px;
    justify-content: center;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      & + li {
        margin-left: 20px;
      }
      .primary-form-control {
        width: 40px;
        padding: 0;
        text-align: center;
      }
    }
  }
  h3 {
    font-weight: bold;
    font-size: 34px;
    font-family: Rokkitt, serif;
    text-align: center;
    line-height: 44px;
    color: #2a2a2a;
    margin-bottom: 25px;
  }
  img {
    text-align: center;
    display: block;
    margin: 0 auto 8px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6f6f6f;
    margin-bottom: 25px;
  }
}

.applicable-on-courses {
  & > p {
    font-weight: 600;
    color: #393e41;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}

.applicable-for {
  .wrap {
    &_foot {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      border-radius: 0px 0px 4px 4px;
      cursor: pointer;

      &.detach {
        background: #666666;
      }

      &.attach {
        background: #00b8be;
      }
    }
    &_body {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #e9e9e9;
      border-radius: 4px 4px 0px 0px;
      height: 470px;
      overflow: auto;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 20px;
        }
      }
    }
  }
  & > p {
    font-weight: 600;
    color: #393e41;
  }
}
