@import '../utils/variables';
.live-box {
  & + .live-box {
    margin-top: 20px;
  }
  h3 {
    font-family: $secondaryFont;
    font-weight: $bold;
    margin: 0 0 15px;
    color: #000;
    font-size: 20px;
    line-height: 36px;
  }
}
