.avatar-group {
  flex-direction: row;
}

.avatar-title {
  font-size: $small-font-size;
  font-weight: 500;
}

.avatar-32pt {
  width: 2rem;
  height: 2rem;
}