@import '../utils/variables';
.secondary-card {
  background: $white;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .action-group {
    .btn {
      font-size: 14px;
      font-weight: $bold;
      & + .btn {
        margin-left: 10px;
      }
    }
  }
  .wrap {
    flex-grow: 1;
  }
  p {
    font-family: $secondaryFont;
    font-weight: $bold;
    font-size: 18px;
    line-height: 32px;
    color: #2a2a2a;
    margin: 0;
  }
  span {
    font-family: $secondaryFont;
    font-size: 14px;
    line-height: 32px;
    display: block;
    color: #6f6f6f;
  }
}
