/* Student Header */
.student-header {
  position: static !important;
  // top: 0;
  // left: 0;
  // right: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  z-index: 999;
  .navbar {
    height: 70px;
    background-color: white;
    padding: 0 22px;
    display: flex;
    align-items: center;
  }
  .navbar-brand {
    margin-right: 105px;
  }
  .navbar-nav {
    margin: 0 -20px !important;
    .nav-link {
      font-size: 15px;
      color: #6f6f6f;
      height: auto;
      margin: 0 20px !important;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        color: #239f87;
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
  }
  .button-dropdown {
    margin-right: 20px;
  }
  .user-dropdown {
    margin-left: 20px;
  }
}
