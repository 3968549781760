.action-box {
  h3 {
    font-family: $primaryFont;
    font-weight: $bold;
    font-size: 34px;
    line-height: 44px;
    color: #2a2a2a;
  }
  p {
    font-family: $secondaryFont;
    font-size: 20px;
    line-height: 32px;
    max-width: 565px;
    color: #6f6f6f;
    opacity: 0.85;
  }
  textarea {
    height: 367px;
    padding: 20px;
    margin-bottom: 25px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      background: #f3f3f3;
      border-radius: 4px;
      height: 120px;
      font-family: $primaryFont;
      font-weight: $bold;
      width: calc((100% - 40px) / 3);
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #2a2a2a;
      &:hover {
        background-color: $primaryColor;
        color: $white;
      }
    }
  }
}
